import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

import * as moment from "moment";
import { Subject } from "rxjs";

import { User, UserAccount, WorkingTimePeriod } from "../models";
import { MetaParameters } from "../models/meta-paramters";
import { WarnMessageService } from "./warn-messages.service";

interface WorkingTimeDays {
  [day: string]: WorkingTimePeriod[];
}

@Injectable({
  providedIn: "root"
})
export class WorkingTimeService {
  private firstAccess = true;
  private timePeriodsOfDay: { start: moment.Moment; end: moment.Moment }[];
  public finishTime = new Subject<void>();
  private timeoutIds: any[];
  private intervalIds: any[];
  constructor(private warnMessageService: WarnMessageService, private router: Router) {
    this.timePeriodsOfDay = [];
    this.timeoutIds = [];
    this.intervalIds = [];
  }

  public checkAccess(user: User, refreshMonitoring: boolean = false): boolean {
    const wtDaysString = localStorage.getItem("wtDays");
    let wtDays = JSON.parse(wtDaysString) as WorkingTimeDays;
    const haveWorkingTime = user && user.meta_parameters && user.meta_parameters.working_time && user.meta_parameters.working_time.length > 0;
    const haveWtDays = wtDays && Object.keys(wtDays).length > 0;
    if (!haveWtDays && !haveWorkingTime) return true;
    if (user.role !== "SALESMAN") return true;

    if (!wtDays) wtDays = this.metaParamsToDays(user.meta_parameters);

    const today = moment();
    today.locale("en");
    const dayOfWeek = today.format("ddd").toLocaleLowerCase();

    this.timePeriodsOfDay = [];
    for (const day of Object.keys(wtDays)) {
      if (day === dayOfWeek) {
        for (const period of wtDays[day]) {
          this.timePeriodsOfDay.push({
            start: moment(`${day} ${period.start}`, "ddd HH:mm"),
            end: moment(`${day} ${period.end}`, "ddd HH:mm")
          });
        }

        break;
      }
    }

    let haveAccess = false;

    for (const periodOfDay of this.timePeriodsOfDay) {
      if (today.isSameOrAfter(periodOfDay.start) && today.isSameOrBefore(periodOfDay.end)) {
        haveAccess = true;
        if (this.firstAccess || refreshMonitoring) this.monitoringAccess(periodOfDay, refreshMonitoring);
      }
    }

    if (this.firstAccess) this.firstAccess = false;

    return haveAccess;
  }

  private monitoringAccess(periodOfDay: { start: moment.Moment; end: moment.Moment }, refresh: boolean): void {
    if (refresh) {
      for (const id of this.timeoutIds) clearTimeout(id);
      for (const id of this.intervalIds) clearInterval(id);
      this.timeoutIds = [];
      this.intervalIds = [];
      this.warnMessageService.delete("workingtime");
    }
    const now = moment();
    const remain = periodOfDay.end.diff(now);
    const finishTimeoutId = setTimeout(() => {
      this.finishTime.next();
    }, remain);
    this.timeoutIds.push(finishTimeoutId);

    const previous = moment(periodOfDay.end).subtract(5, "minutes");
    const previousRemain = previous.diff(moment());
    const warnMsgTimeoutId = setTimeout(() => {
      const timeRemain = moment.utc(moment(periodOfDay.end).diff(moment()));
      const msg = (time: string) => `Restam ${time} para encerrar o horário de trabalho`;
      this.warnMessageService.add("workingtime", msg(timeRemain.format("mm:ss")));

      const intervalId = setInterval(() => {
        timeRemain.subtract(1, "seconds");
        this.warnMessageService.update("workingtime", msg(timeRemain.format("mm:ss")));

        if (timeRemain.get("minutes") === 0 && timeRemain.get("seconds") === 0) {
          this.warnMessageService.delete("workingtime");
          clearInterval(intervalId);
        }
      }, 1000);
      this.intervalIds.push(intervalId);
    }, previousRemain);
    this.timeoutIds.push(warnMsgTimeoutId);
  }

  public mergeWorkingTime(account: UserAccount): void {
    const user: User = JSON.parse(localStorage.getItem("user"));
    let daysMap: WorkingTimeDays = {};
    if (account) {
      daysMap = this.metaParamsToDays(account.meta_parameters);
    }

    if (user) {
      daysMap = {
        ...daysMap,
        ...this.metaParamsToDays(user.meta_parameters)
      };
    }

    localStorage.setItem("wtDays", JSON.stringify(daysMap));
    if (!this.checkAccess(user, true)) this.router.navigateByUrl("/access-denied");
    else if (this.router.url.includes("/access-denied")) this.router.navigateByUrl("/");
  }

  private metaParamsToDays(source: MetaParameters): WorkingTimeDays {
    const daysMap: WorkingTimeDays = {};
    const hasWorkingTime = source && source.working_time && source.working_time.length > 0;
    if (hasWorkingTime) {
      const { working_time } = source;
      for (const wt of working_time) {
        for (const day of wt.days) {
          daysMap[day] = wt.period_time;
        }
      }
    }

    return daysMap;
  }

  public removeWorkDays(): void {
    localStorage.removeItem("wtDays");
  }
}
