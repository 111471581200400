import { WarnMessageService } from "./warn-messages.service";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { EventEmitterService } from ".";

@Injectable({
  providedIn: "root"
})
export class OnlineService {
  private static USER_ONLINE_KEY = "userOnline";
  private _onlineNetwork: boolean;
  private _wantWorkOnline: boolean;
  public onlineSnapshot: boolean;

  private online: BehaviorSubject<boolean>;

  get onlineNetwork(): boolean {
    return this._onlineNetwork;
  }

  get wantWorkOnline(): boolean {
    return this._wantWorkOnline;
  }

  constructor(private warnMessagesService: WarnMessageService) {
    const userOnline = localStorage.getItem(OnlineService.USER_ONLINE_KEY);
    this._onlineNetwork = navigator.onLine;
    this._wantWorkOnline = userOnline ? userOnline === "true" : true;

    this.onlineSnapshot = this._onlineNetwork && this._wantWorkOnline;
    this.online = new BehaviorSubject<boolean>(this.onlineSnapshot);

    window.addEventListener("online", () => this.setNetworkOnline(true));
    window.addEventListener("offline", () => this.setNetworkOnline(false));

    EventEmitterService.get(EventEmitterService.CHANGE_WORK_MODE_EVENT).subscribe(isOnline => {
      this._wantWorkOnline = isOnline;
      this.setMode();
    });

    this.setOfflineMsg();
  }

  private setMode(): void {
    const isOnline = this._wantWorkOnline && this._onlineNetwork;
    this.onlineSnapshot = isOnline;
    this.online.next(isOnline);
    localStorage.setItem(OnlineService.USER_ONLINE_KEY, this._wantWorkOnline.toString());

    this.setOfflineMsg();
  }

  public getOnline(): Observable<boolean> {
    return this.online.asObservable();
  }

  private setNetworkOnline(isOnline: boolean): void {
    this._onlineNetwork = isOnline;
    this.setMode();
  }

  private setOfflineMsg(): void {
    if (!this.onlineSnapshot) {
      const msg = !this._wantWorkOnline ? "Você está trabalhando OffLine" : "Sem conexão com a internet";
      if (!this.warnMessagesService.haveId("offline")) this.warnMessagesService.add("offline", msg);
      else this.warnMessagesService.update("offline", msg);
    } else {
      if (this.warnMessagesService.haveId("offline")) this.warnMessagesService.delete("offline");
    }
  }
}
