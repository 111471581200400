import { Injectable } from "@angular/core";
@Injectable({
    providedIn: "root"
})
export class UserAgenteService {

    public isMobile = false;

    public UserAgente(): void {
        this.isMobile = this.detectMob();
    }

    public detectMob(): boolean {
        const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

        return toMatch.some(toMatchItem => {
            return navigator.userAgent.match(toMatchItem);
        });
    }

}