import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { Observable } from "rxjs";
import { timeout, switchMap } from "rxjs/operators";
import { throwError, from, TimeoutError, empty, EMPTY, NEVER, of } from "rxjs";
import { SyncService } from "./sync.service";
import { SyncData, HttpResponseStatusText } from "../models";
import { environment } from "../../../../environments/environment";
import { EventEmitterService } from "app/main/shared/services/event.service";
import { AuthService } from "./auth.service";
import { OnlineService } from ".";
import * as moment from "moment";

interface RequestToSync {
  id: number;
  type: string;
  pattern: string;
  bodyId: string;
}

@Injectable()
export class SyncInterceptor implements HttpInterceptor {
  constructor(
    private syncService: SyncService,
    private authService: AuthService,

    private _onlineService: OnlineService
  ) {}

  private keepRequestData(request: HttpRequest<any>, requestToSync: RequestToSync): Promise<void> {
    return this.syncService
      .getByPatternId(requestToSync.id)
      .then(items => {
        if (items) {
          items.forEach(item => {
            if (item.url === request.url) {
              const { body } = request;
              const { payload } = item;

              if (!requestToSync.bodyId || (requestToSync.bodyId && payload[requestToSync.bodyId] === body[requestToSync.bodyId])) {
                return this.syncService.removeById(item.id);
              }
            }
          });
        }
      })
      .then(() => this.authService.getUser().toPromise())
      .then(user => {
        const syncItem: SyncData = {
          patternId: requestToSync.id,
          url: request.url,
          httpMethod: request.method,
          payload: request.body,
          accountId: user.account_id,
          userId: user.id,
          date: moment().toDate()
        };
        syncItem.date.setHours(0, 0, 0, 0);

        return this.syncService.addItem(syncItem);
      })
      .then(() => EventEmitterService.get(EventEmitterService.SYNC_ALL_EVENT).emit());
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestsToSync: RequestToSync[] = <RequestToSync[]>environment.requestsToSync;

    for (const req of requestsToSync) {
      const needToCache = request.method === req.type && new RegExp(req.pattern).test(request.url);

      if (needToCache) {
        const isExternalSalesman = this.authService.currentUser && this.authService.currentUser.salesman_type === "EXTERNAL";
        const isInternalSalesman = this.authService.currentUser && this.authService.currentUser.salesman_type === "INTERNAL";

        if (isExternalSalesman || isInternalSalesman) {
          const isOnline = this._onlineService.onlineSnapshot;
          if (!isOnline) {
            return from(this.keepRequestData(request, req)).pipe(
              switchMap(() => {
                const statusText = this.syncService.canSync() ? HttpResponseStatusText.NetworkUnavailable : HttpResponseStatusText.SyncUnavailable;
                return throwError(new HttpErrorResponse({ status: 0, statusText }));
              })
            );
          }
        }
      }
    }
    return next.handle(request);
  }
}
