export interface ICountClients {
  amount: number;
  client_with_photo: string[];
  client_without_photo: string[];
  clients_non_routable: string[];
  clients_non_schedulable: string[];
  clients_excluded: string[];
  clients_blocked: string[];
  clients_non_schedulable_non_routable: string[];
}
export class CountPhotos implements ICountClients {
  public amount = 0;
  public seller_id = "";
  public client_with_photo: string[] = [];
  public client_without_photo: string[] = [];
  public clients_non_routable: string[] = [];
  public clients_non_schedulable: string[] = [];
  public clients_excluded: string[] = [];
  public clients_blocked: string[] = [];
  public clients_non_schedulable_non_routable: string[] = [];

  public constructor(count?: CountPhotos) {
    if (count) {
      this.amount = count.amount;
      this.seller_id = count.seller_id;
      this.client_with_photo = count.client_with_photo;
      this.client_without_photo = count.client_without_photo;
      this.clients_non_routable = count.clients_non_routable;
      this.clients_non_schedulable = count.clients_non_schedulable;
      this.clients_excluded = count.clients_excluded;
      this.clients_blocked = count.clients_blocked;
      this.clients_non_schedulable_non_routable = count.clients_non_schedulable_non_routable;
    }
  }
}
