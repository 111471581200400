import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";

import { environment } from "environments/environment";
import { User, UserAccount, IUser } from "../models";
import { ReplaySubject, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { SentryErrorHandler } from "./sentry-error-handler.service";

@Injectable()
export class AuthService {
  private BASE_URL = environment.BASE_URL;

  public currentUser: User;
  public currentAccount: UserAccount;

  public user$: ReplaySubject<User>;
  public account$: ReplaySubject<UserAccount>;
  private isLoadingUsers: boolean;
  private isLoadingAccount: boolean;

  constructor(private http: HttpClient, private sentryService: SentryErrorHandler) {
    this.user$ = new ReplaySubject<User>(1);
    this.account$ = new ReplaySubject<UserAccount>(1);
    this.isLoadingUsers = false;
    this.isLoadingAccount = false;
  }

  public getToken(): string {
    return localStorage.getItem("token");
  }

  public logIn(email: string, password: string): Observable<any> {
    this.currentUser = null;

    const url = `${this.BASE_URL}/authentication/login`;
    return this.http
      .post<User>(url, { username: email, password: password })
      .pipe(
        catchError(err => {
          this.sentryService.handleError(err);
          return throwError(err);
        })
      );
  }

  public getStatus(): Observable<User> {
    const url = `${this.BASE_URL}/status`;
    return this.http.get<User>(url);
  }

  public getUser(force: boolean = false): Observable<User> {
    if (this.currentUser && !force) {
      return this.user$;
    }

    if (!this.isLoadingUsers) {
      const url = `${this.BASE_URL}/users`;
      this.isLoadingUsers = true;
      this.http.get<IUser>(url).subscribe(
        user => {
          this.currentUser = new User(user);
          this.user$.next(this.currentUser);
          this.user$.complete();
        },
        error => {
          this.sentryService.handleError(error);
          this.user$.error(error);
        },
        () => {
          this.isLoadingUsers = false;
        }
      );
    }

    return this.user$;
  }

  public getAccount(force: boolean = false): Observable<UserAccount> {
    if (this.currentAccount && !force) {
      return this.account$;
    }

    if (!this.isLoadingAccount) {
      const url = `${this.BASE_URL}/account?token=${localStorage.getItem("token")}`;
      this.isLoadingAccount = true;
      this.http.get<UserAccount>(url).subscribe(
        account => {
          this.currentAccount = account;
          this.account$.next(this.currentAccount);
          this.account$.complete();
        },
        error => {
          this.sentryService.handleError(error);
          this.account$.error(error);
        },
        () => {
          this.isLoadingAccount = false;
        }
      );
    }

    return this.account$;
  }
}
