import { SentryErrorHandler } from "./../../../services/sentry-error-handler.service";
import { ToastrService } from "ngx-toastr";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { User, Client } from "app/main/shared/models";
import { AuthService, ProductService, TrackingService } from "app/main/shared/services";
import { ComplementOption } from "../model/complement-options";
import { Recommendation } from "../model/recommentation";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "product-item",
  templateUrl: "./product-item.component.html",
  styleUrls: ["./product-item.component.scss"]
})
export class ProductItemComponent implements OnInit {
  @Input()
  public recommendation: Recommendation;

  @Input()
  public client: Client;

  @Input()
  public listName: string;

  @Output()
  public actionClick = new EventEmitter<Recommendation>();

  public options: ComplementOption[];

  private currentUser: User;

  public spinnerName = "";

  constructor(
    private trackingService: TrackingService,
    private auth: AuthService,
    private productService: ProductService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private sentryService: SentryErrorHandler
  ) {
    this.currentUser = this.auth.currentUser;
  }

  public ngOnInit(): void {
    if (this.recommendation.feedback.feedback_id != null) {
      const mapping = this.productService.feedbackMappings.find(x => x.feedback_id === this.recommendation.feedback.feedback_id);
      if (mapping) {
        this.recommendation.feedback.isPositive = mapping.positive;
        this.options = this.buildFeedbackOptions(mapping.positive);
      }
    }

    this.spinnerName = `product-item-${this.recommendation.product_id}`;
  }

  private showSpinner(): void {
    this.spinner.show(this.spinnerName);
  }

  private hideSpinner(): void {
    this.spinner.hide(this.spinnerName);
  }

  public selectThumbsUp(): void {
    if (this.recommendation.thumbsUpSelected) return;

    this.recommendation.isPositiveFeedback = true;
    this.options = this.buildFeedbackOptions(true);
    this.recommendation.feedback.feedback_id = null;

    const feedback = this.recommendation.thumbsUpSelected ? "thumbsUp" : "";
    this.trackFeedback("feedback do produto", feedback);
  }

  public selectThumbsDown(): void {
    if (this.recommendation.thumbsDownSelected) return;

    this.recommendation.isPositiveFeedback = false;
    this.options = this.buildFeedbackOptions(false);
    this.recommendation.feedback.feedback_id = null;

    const feedback = this.recommendation.thumbsDownSelected ? "thumbsDown" : "";
    this.trackFeedback("feedback do produto", feedback);
  }

  private trackFeedback(trackName: string, feedback: string): void {
    const data = {
      client_id: this.client.client_id,
      client: this.client.client,
      salesman_id: this.currentUser.salesman_id,
      salesman: this.currentUser.name,
      product_id: this.recommendation.product_id,
      feedback,
      list_name: this.listName
    };

    const mapping = this.productService.feedbackMappings.find(x => x.feedback_id === this.recommendation.feedback.feedback_id);
    if (mapping) data["feedbackComplement"] = mapping.label;

    this.trackingService.track(trackName, data);
  }

  public selectComplementOption(item: ComplementOption): void {
    const { feedback } = this.recommendation;
    const mapping = this.productService.feedbackMappings.find(x => x.feedback_id === item.value);

    if (feedback.feedback_id === mapping.feedback_id) return;

    feedback.feedback_id = item.value;
    feedback.client_id = this.recommendation.client_id;
    feedback.product_id = this.recommendation.product_id;
    feedback.list_type = this.recommendation.list_type;
    feedback.feedback_date = new Date();

    this.showSpinner();
    this.productService.saveFeedback(feedback).subscribe(
      () => {
        this.hideSpinner();
        this.toastr.success(`Feedback para o produto ${feedback.product_id} foi salvo com sucesso!`);
      },
      error => {
        this.sentryService.handleError(error);
        this.hideSpinner();
        this.toastr.error(`Houve um erro ao salvar o feedback para o produto ${feedback.product_id}. Tente novamente mais tarde!`);
      }
    );

    const feedbackForTrack = this.recommendation.thumbsUpSelected ? "thumbsUp" : this.recommendation.thumbsDownSelected ? "thumbsDown" : "";
    this.trackFeedback("complemento do feedback do produto", feedbackForTrack);
  }

  public isOptionSelected(item: ComplementOption): boolean {
    return this.recommendation.feedback?.feedback_id === item.value;
  }

  public canShowComplementOptions(): boolean {
    return this.recommendation.thumbsUpSelected || this.recommendation.thumbsDownSelected;
  }

  public hasTags(item: Recommendation): boolean {
    return item && item.tags && item.tags.length > 0;
  }

  public actionClicked(): void {
    this.actionClick.emit(this.recommendation);
  }

  private buildFeedbackOptions(isPositive: boolean): ComplementOption[] {
    return this.productService.feedbackMappings.filter(x => x.positive === isPositive).map(x => ({ label: x.label, value: x.feedback_id }));
  }
}
