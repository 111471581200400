import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import * as moment from "moment";
import { environment } from "environments/environment";
import { CalendarSummaryFilter, DaySumaryOfCalendarPlanning } from "../../sales-planning/models/calendar";
import { ClientStatusSumary } from "../models/client-status-summary";
import { ClientLog, ClientLogsParams } from "../models";
import { map } from "rxjs/operators";

@Injectable()
export class VisibilityService {
  private BASE_URL = environment.BASE_URL;

  constructor(public http: HttpClient) { }

  public getCalendarSummary(salesman_ids: String[]): Observable<DaySumaryOfCalendarPlanning[]> {
    const calendarSummaryFilter = new CalendarSummaryFilter();

    calendarSummaryFilter.date = moment()
      .startOf("month")
      .format("YYYY-MM-DD");
    calendarSummaryFilter.salesman_ids = salesman_ids;

    const url = `${this.BASE_URL}/visibility/summary`;

    return this.http.post(url, calendarSummaryFilter).pipe(map((response: DaySumaryOfCalendarPlanning[]) => {
      return response;
    }));
  }

  public getClientsSumaryStatus(salesman_ids: String[]): Observable<ClientStatusSumary> {
    const dataFilter = {
      salesman_ids: salesman_ids
    };

    const url = `${this.BASE_URL}/visibility/clients-status/summary`;

    return this.http.post<ClientStatusSumary>(url, dataFilter);
  }

  public getCountClientinWalletOfSellerList(salesman_ids: String[]): Observable<any> {
    const dataFilter = {
      sellersIds: salesman_ids
    };

    const url = `${this.BASE_URL}/clients`;

    return this.http.post(url, dataFilter, { observe: 'response' });
  }

  public getClientsLogsList(params: ClientLogsParams, page: number, limit: number): Observable<any> {
    const url = `${this.BASE_URL}/visibility/clients/logs?page=${page}&perPage=${limit}`;

    return this.http.post<ClientLog[]>(url, params, { observe: 'response' });
  }
}
