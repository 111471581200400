import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Observable, BehaviorSubject, throwError, of, from } from "rxjs";
import { tap, map, catchError } from "rxjs/operators";
import { Client, IClient, GovendasResponse, PhotoStorageData, GetClientsParams, ImageInfos } from "../models";
import { environment } from "environments/environment";
import * as moment from "moment";
import { CustomNetworkInformation } from "../models/customNetworkInformation";
import { ItemClient } from "../models/itemClient";
import { CountPhotos } from "../models/countPhotos";
import { ClientRepository } from "../repositories";

import { NetworkspeedService } from "./networkspeed.service";
import { StorageImageService } from "./storageImage.service";
import { ToastrService } from "ngx-toastr";
import { CacheService } from "./cache.service";
import { LocationDataPhoto } from "../models";
import { SentryErrorHandler } from "./sentry-error-handler.service";
import { OnlineService } from "./online.service";

@Injectable()
export class ClientService {
  private BASE_URL = environment.BASE_URL;
  public items: Observable<any[]>;
  public sinal: CustomNetworkInformation;

  public isLoading: BehaviorSubject<boolean>;

  constructor(
    public http: HttpClient,
    private _networkservice: NetworkspeedService,
    private _storageImageService: StorageImageService,
    private toastr: ToastrService,
    private cacheService: CacheService<Client[]>,
    private clientRepository: ClientRepository,
    private sentryService: SentryErrorHandler,
    private onlineService: OnlineService
  ) {
    this.sinal = new CustomNetworkInformation();
    this._networkservice.netWorkStatus.subscribe(resp => {
      this.sinal = resp;
    });

    this.isLoading = new BehaviorSubject<boolean>(false);
  }

  get clients(): Client[] {
    return this.clientRepository.clients;
  }

  private getClientFromCache(): Promise<Client[]> {
    const url = `${this.BASE_URL}/clients/?perPage=3000&trustedCoords=true`;
    return this.cacheService
      .getByUrl(url)
      .then(itemCacheService => {
        if (itemCacheService) {
          this.clientRepository.saveAll(itemCacheService.response);
          return itemCacheService.response;
        } else {
          return [];
        }
      })
      .catch(error => {
        this.sentryService.handleError(error);
        return error;
      });
  }

  public getAll(forceRequest: boolean): Observable<GovendasResponse<Client[]>> {
    let startTime = moment();

    const url = `${this.BASE_URL}/clients/?perPage=3000&trustedCoords=true`;
    if (forceRequest || this.clients.length === 0) {
      this.isLoading.next(true);
      return this.http.post<IClient[]>(url, {}, { observe: "response" }).pipe(
        map(response => {
          const backendTime = parseInt(response.headers.get("Request-Time-Duration"), 10);
          let responseTime = moment.duration(moment().diff(startTime)).asMilliseconds();
          if (backendTime) responseTime -= backendTime;
          startTime = moment();
          this.isLoading.next(false);
          this.clientRepository.saveAll(response.body);
          const processingTime = moment.duration(moment().diff(startTime)).asMilliseconds();
          const govendasResponse: GovendasResponse<Client[]> = {
            data: this.clients,
            sendTime: startTime.valueOf(),
            responseTime,
            processingTime,
            backendTime,
            requestId: response.headers.get(environment.requestIdHeader)
          };

          return govendasResponse;
        }),
        catchError(err => {
          if (err instanceof HttpErrorResponse) {
            if (!err.status) {
              const responseTime = moment.duration(moment().diff(startTime)).asMilliseconds();
              return from(
                this.getClientFromCache()
                  .then(clients => {
                    if (clients.length > 0) {
                      const govendasResponse: GovendasResponse<Client[]> = {
                        data: this.clients,
                        sendTime: startTime.valueOf(),
                        responseTime,
                        requestId: ""
                      };
                      return govendasResponse;
                    } else {
                      return err;
                    }
                  })
                  .catch(error => {
                    this.sentryService.handleError(err);
                    return error;
                  })
                  .finally(() => {
                    this.isLoading.next(false);
                  })
              );
            } else {
              this.isLoading.next(false);
              this.sentryService.handleError(err);
              return throwError(err);
            }
          } else {
            this.isLoading.next(false);
            this.sentryService.handleError(err);
            throwError(err);
          }
        })
      );
    } else {
      return of({
        data: this.clients,
        sendTime: 0,
        responseTime: 0,
        requestId: ""
      });
    }
  }

  public getSalesmanClients(sellersIDs: string[]): Promise<Client[]> {
    const url = `${this.BASE_URL}/clients/?perPage=3000&trustedCoords=true`;
    if (sellersIDs != null && sellersIDs.length > 0) {
      const data = {
        sellersIds: sellersIDs,
        selectFields: [
          "client_id",
          "client",
          "fantasy_name",
          "neighborhood",
          "thoroughfare",
          "house_number",
          "city",
          "state",
          "image_infos",
          "exclusion",
          "blocked"
        ]
      };
      return new Promise((resolve, reject) => {
        this.http
          .post<Client[]>(url, data)
          .toPromise()
          .then(list => {
            this.clientRepository.saveAll(list);
            resolve(this.clients);
          })
          .catch(err => {
            this.sentryService.handleError(err);
            reject(err);
          });
      });
    }
  }

  public getByClient(client: string, bySalesman: boolean = true, page: number = 1, limit: number = 10): Observable<Client[]> {
    const url = `${this.BASE_URL}/clients/?page=${page}&perPage=${limit}&bySalesman=${bySalesman}`;
    const params = {
      client
    };
    return this.http.post<Client[]>(url, params).pipe(
      map(response => response.map(x => new Client(x))),
      catchError(err => {
        this.sentryService.handleError(err);
        return throwError(err);
      })
    );
  }

  public getByFields(clientParams: GetClientsParams): Observable<Client[]> {
    const { client, page, limit, bySalesman, columns } = clientParams;
    const url = `${this.BASE_URL}/clients/?page=${page}&perPage=${limit}&bySalesman=${bySalesman}&trustedCoords=true`;
    const params = {
      match: client,
      matchFields: columns,
      exclusion: false
    };
    if (columns.includes("client_id") && columns.length === 1) {
      params["sortField"] = "client_id";
      params["order"] = "ASC";
    }
    return this.http.post<Client[]>(url, params).pipe(
      tap(resp => {
        this.clientRepository.save(resp);
      }),
      map(response => response.map(x => new Client(x))),
      catchError(err => {
        this.sentryService.handleError(err);
        return throwError(err);
      })
    );
  }

  public getByClientWithFantasyName(client: string, bySalesman: boolean = true, page: number = 1, limit: number = 10): Observable<Client[]> {
    const url = `${this.BASE_URL}/clients/?page=${page}&perPage=${limit}&bySalesman=${bySalesman}`;
    const params = {
      match: client,
      matchFields: ["fantasy_name"]
    };
    return this.http.post<Client[]>(url, params).pipe(
      map(response => response.map(x => new Client(x))),
      catchError(err => {
        this.sentryService.handleError(err);
        return throwError(err);
      })
    );
  }

  public getByClientWithCompanyName(client: string, bySalesman: boolean = true, page: number = 1, limit: number = 10): Observable<Client[]> {
    const url = `${this.BASE_URL}/clients/?page=${page}&perPage=${limit}&bySalesman=${bySalesman}`;
    const params = {
      match: client,
      matchFields: ["client"]
    };
    return this.http.post<Client[]>(url, params).pipe(
      map(response => response.map(x => new Client(x))),
      catchError(err => {
        this.sentryService.handleError(err);
        return throwError(err);
      })
    );
  }

  public getByClientWithClientCode(client: string, bySalesman: boolean = true, page: number = 1, limit: number = 10): Observable<Client[]> {
    const url = `${this.BASE_URL}/clients/?page=${page}&perPage=${limit}&bySalesman=${bySalesman}`;
    const params = {
      match: client,
      matchFields: ["client_id"]
    };
    return this.http.post<Client[]>(url, params).pipe(
      map(response => response.map(x => new Client(x))),
      catchError(err => {
        this.sentryService.handleError(err);
        return throwError(err);
      })
    );
  }

  public getByClientIds(ids: string[], limit: number = 10, insertClients = false, trustedCoords: boolean = false): Observable<Client[]> {
    const url = `${this.BASE_URL}/clients/?bySalesman=false&perPage=${limit}&trustedCoords=${trustedCoords}`;
    const params = {
      ids
    };
    return this.http.post<Client[]>(url, params).pipe(
      tap(list => {
        if (insertClients) this.clientRepository.save(list);
      }),
      map(response => response.map(x => new Client(x))),
      catchError(err => {
        this.sentryService.handleError(err);
        return throwError(err);
      })
    );
  }

  public getClientsByIds(
    ids: string[],
    salesman: string,
    limit: number = 10,
    trustedCoords: boolean = true,
    insertClients = false
  ): Observable<Client[]> {
    const url = `${this.BASE_URL}/clients?trustedCoords=${trustedCoords}&bySalesman=false&perPage=${limit}`;
    const params = {
      ids,
      salesman: salesman,
      selectFields: ["client_id", "image_infos"]
    };

    return this.http.post<Client[]>(url, params).pipe(
      tap(list => {
        if (insertClients) this.clientRepository.save(list);
      }),
      map(response => response.map(x => new Client(x))),
      catchError(err => {
        this.sentryService.handleError(err);
        return throwError(err);
      })
    );
  }

  public getClient(id: string, trustedCoords: boolean = false): Observable<Client> {
    const url = `${this.BASE_URL}/clients/${id}?trustedCoords=${trustedCoords}`;
    return this.http.get<Client>(url).pipe(
      map(resp => new Client(resp)),
      catchError(err => {
        this.sentryService.handleError(err);
        return throwError(err);
      })
    );
  }

  public putImageLocal(item: ItemClient): Promise<LocationDataPhoto> {
    const date = moment(item.timestamp[0]).utc().format("YYYY-MM-DD-HH-mm-ss");
    let itenCopy: any;
    itenCopy = { ...item };

    itenCopy.dataInLocalStorage = true;
    return this._storageImageService.add({
      date: date,
      item: itenCopy,
      accountId: itenCopy.account_id,
      userId: itenCopy.user_id,
      clientId: itenCopy.client_id
    });
  }

  public dataURItoBlob(dataURI): Blob {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    return new Blob([int8Array], { type: "image/jpeg" });
  }

  public async loadItensToSync(account_id: number, user_id: number): Promise<ItemClient[]> {
    const syncItensList = [];
    if (!account_id || !user_id) return Promise.reject(Error("Invalid params"));
    return await this._storageImageService.getList(account_id, user_id).then(resp => {
      if (resp && resp.length > 0) {
        resp.forEach(element => {
          syncItensList.push(new ItemClient(element.item));
        });
      }
      return syncItensList;
    });
  }

  public postMethodGogeo(item: ItemClient): Observable<ImageInfos> {
    const uri = item.imageBase64.slice(23, item.imageBase64.length);
    item.imageBase64 = "";
    const formData = new FormData();

    let index = 0;

    item.accuracy.map((obj, i) => {
      if (obj < item.accuracy[index]) {
        index = i;
      }
    });

    const metadataNovo = {
      clientId: item.client_id,
      date: moment(item.timestamp[index]).toISOString(),
      coordinate: "" + item.latitude[index].toString() + "," + item.longitude[index].toString()
    };

    // Coleta de informações detalhadas da localização
    if (item.accuracy && item.accuracy[index]) metadataNovo["accuracy"] = item.accuracy[index].toString();

    if (item.altitude && item.altitude[index]) metadataNovo["altitude"] = item.altitude[index].toString();

    if (item.altitudeAccuracy && item.altitudeAccuracy[index]) metadataNovo["altitudeAccuracy"] = item.altitudeAccuracy[index].toString();

    if (item.heading && item.heading[index]) metadataNovo["heading"] = item.heading[index].toString();

    if (item.speed && item.speed[index]) metadataNovo["speed"] = item.accuracy[index].toString();

    formData.append(
      "imageInfo",
      new Blob([JSON.stringify(metadataNovo)], {
        type: "application/json"
      })
    );
    formData.append(
      "file",
      new Blob([this.dataURItoBlob(uri)], {
        type: "multipart/form-data"
      })
    );

    const headers = new HttpHeaders().set("timeout-govendas", `${1000 * 60 * 5}`);

    return this.http
      .post<ImageInfos>(this.BASE_URL + "/image", formData, {
        params: {
          multipart: "true"
        },
        headers
      })
      .pipe(
        tap(resp => {
          this.clientRepository.addImageInfos(item.client_id, resp);
        }),
        catchError(err => {
          this.sentryService.handleError(err);
          return throwError(err);
        })
      );
  }

  public showMessagePhotoStatus(clientRes: any): void {
    if (clientRes) {
      switch (clientRes.status) {
        case "success":
          this.toastr.success("Imagem e localização registrada com sucesso");
          break;

        case "fail":
          this.toastr.error("Houve um erro ao atualizar imagem e a localização deste cliente");
          break;
        case "cancel":
          this.toastr.warning("Foto cancelada");
          break;
        default:
          this.toastr.error("Houve um erro ao atualizar imagem e a localização deste cliente");
          break;
      }
    }
  }

  public async checkPhotoLocalStorage(account_id: number, user_id: number, client_id: string): Promise<PhotoStorageData> {
    let ret: PhotoStorageData = {
      exists: false,
      data: []
    };

    const slep = await this.loadItensToSync(account_id, user_id).then(syncitems => {
      syncitems = syncitems
        .filter(x => x.client_id === client_id)
        .sort(x => x.timestamp[0])
        .reverse();

      const key = client_id;
      syncitems.forEach((element, index) => {
        if (element.client_id === key) {
          ret = {
            exists: true,
            data: []
          };
          ret.data.push(new ItemClient(element));
        }
      });
    });

    return ret;
  }

  public checkClientContainsPhoto(client: Partial<Client>): string {
    if (!this.clients) return "";

    const clientFiltreds = this.clients.filter(c => c.client_id === client.client_id);

    if (clientFiltreds.length === 0) {
      return "";
    } else {
      if (clientFiltreds[0].image_infos && clientFiltreds[0].image_infos.length > 0) {
        return clientFiltreds[0].image_infos[0].token;
      } else {
        return "";
      }
    }
  }

  public countClientPhotos(seller_id?: string[]): Observable<CountPhotos[]> {
    const data = seller_id ? { ids: seller_id } : {};
    const headers = new HttpHeaders().set("timeout-govendas", `${1000 * 60 * 5}`);
    return this.http
      .post<CountPhotos[]>(`${this.BASE_URL}/image/count`, data, { headers })
      .pipe(
        map(response => response.map(x => new CountPhotos(x))),
        catchError(err => {
          this.sentryService.handleError(err);
          return throwError(err);
        })
      );
  }

  public getClientPhotosReport(clientIds?: string[], salesmanId?: string): Promise<string> {
    const data = clientIds;
    const url = `${this.BASE_URL}/clients/photo/report/${salesmanId ? salesmanId : ""}`;

    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders().set("Content-Type", "text/plain; charset=utf-8");

      this.http
        .post<any>(url, data, { headers, responseType: "blob" as "json" })
        .toPromise()
        .then(resp => {
          const blob = new Blob([resp], { type: "application/vnd.ms-excel" });
          const url2 = window.URL.createObjectURL(blob);

          const filename = "relatorio_de_fotos_" + salesmanId + ".xlsx";
          const anchor = document.createElement("a");
          anchor.download = filename;
          anchor.href = url2;
          anchor.click();
          resolve(filename);
        })
        .catch(err => {
          const requestId = err instanceof HttpErrorResponse ? err.headers.get(environment.requestIdHeader) : null;

          if (requestId) {
            const extra = {};
            extra[environment.requestIdHeader] = err.headers.get(environment.requestIdHeader);
            this.sentryService.handleError(err, extra);
          } else {
            this.sentryService.handleError(err);
          }

          reject(err);
        });
    });
  }
}
