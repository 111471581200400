import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { OnlineService, EventEmitterService, SyncService, ClientService, AuthService, TrackingService } from "../../services";

@Component({
  selector: "app-modal-confirm-offline-toogle",
  templateUrl: "./modal-confirm-offline-toogle.component.html",
  styleUrls: ["./modal-confirm-offline-toogle.component.scss"]
})
export class ModalConfirmOfflineToogleComponent implements OnInit {
  public online: boolean;
  public numberItensToSync = 0;
  public isSyncAutomatic: boolean;

  constructor(
    public dialogRef: MatDialogRef<ModalConfirmOfflineToogleComponent>,
    public onlineService: OnlineService,
    private syncService: SyncService,
    private auth: AuthService,
    private clientService: ClientService,
    private trackingService: TrackingService
  ) {
    this.isSyncAutomatic = false;
    this.onlineService.getOnline().subscribe(() => {
      this.online = this.onlineService.wantWorkOnline;
    });

    this.syncService.countItems().then(count => {
      if (count != null) this.numberItensToSync = this.numberItensToSync + count;
    });

    this.auth.getUser().subscribe(user => {
      this.clientService.loadItensToSync(user.account_id, user.id).then(resp => {
        this.numberItensToSync = this.numberItensToSync + resp.length;
      });
    });
  }

  public ngOnInit(): void {}

  public close(isOnline?: boolean): void {
    this.dialogRef.close(isOnline);
  }

  public toggleOnlineOffline(): void {
    const onlineValue = !this.online;
    const trackMsg = onlineValue ? "troca para online" : "troca para offline";
    this.trackingService.track(trackMsg, null, true);
    EventEmitterService.get(EventEmitterService.CHANGE_WORK_MODE_EVENT).emit(!this.online);

    if (this.isSyncAutomatic === true) {
      EventEmitterService.get(EventEmitterService.SYNC_ITEMS_AFTER_CHANGE_WORK_MODE_EVENT).emit();
    }

    this.close(!this.online);
  }
}
