import { IClient, Client, ImageInfos } from "./../models/client";
import { Injectable } from "@angular/core";

@Injectable()
export class ClientRepository {
  private _clients: Client[];

  constructor() {
    this._clients = [];
  }

  get clients(): Client[] {
    return this._clients;
  }

  public saveAll(data: IClient[]): void {
    if (this.clients.length > 0) this.clients.splice(0, this.clients.length);
    const clients = data.map(x => new Client(x));
    this._clients.push(...clients);
  }

  public save(data: IClient[]): void {
    if (!this._clients && data && data.length > 0) this._clients = [];
    const clients = data.map(x => new Client(x));

    const repoClientIds = this._clients ? this._clients.map(x => x.client_id) : [];
    clients.forEach((x, i) => {
      if (!repoClientIds.includes(x.client_id)) {
        this._clients.push(x);
      } else {
        this._clients[i] = x;
      }
    });
  }

  public addImageInfos(clientId: string, data: ImageInfos): void {
    if (!this._clients && data) this._clients = [];
    const client = this._clients.find(x => x.client_id === clientId);

    if (client) {
      if (client.image_infos && client.image_infos.length > 0) client.image_infos.unshift(data);
      else client.image_infos = [data];
    }
  }
}
