import { Injectable } from "@angular/core";
import { IndexQueryRange, GovendasError, GovendasErrorCode } from "../models";

@Injectable()
export class DatabaseManager {
  public static DATABASE_NAME = "govendas-app";
  public static NOTIFICATION_STORE_NAME = "notifications";
  private static VERSION = 10;
  public static PHOTO_STORE_NAME = "photo";
  public static SYNC_STORE_NAME = "sync";
  public static CACHE_STORE_NAME = "cache";
  public static STORE_NAMES = new Map(
    Object.entries({
      notifications: {
        id: "id",
        date: "date",
        accountId: "accountId",
        userId: "userId",
        notificationQuery: ["accountId", "userId", "date"]
      },
      photo: {
        clientId: "clientId",
        accountId_userId: ["accountId", "userId"],
        accountId_userId_clientId: ["accountId", "userId", "clientId"]
      },
      sync: {
        patternId: "patternId",
        patternByUser: ["accountId", "userId", "patternId", "date"],
        accountId_userId: ["accountId", "userId", "date"]
      },
      cache: {
        url: "url"
      }
    })
  );

  public readonly indexedDBExists = !!window.indexedDB;

  constructor() {}

  private createIndex(objectStore: IDBObjectStore, indexes: any): void {
    for (const item of Object.entries<string[]>(indexes)) {
      const [key, value] = item;

      if (objectStore.indexNames.contains(key)) {
        const currentIndex = objectStore.index(key);
        const isSameIndex = JSON.stringify(value) === JSON.stringify(currentIndex.keyPath);
        if (!isSameIndex) {
          objectStore.deleteIndex(key);
          objectStore.createIndex(key, value, { unique: false });
        }
      } else objectStore.createIndex(key, value, { unique: false });
    }
  }

  private buildKeyRange(range: IndexQueryRange): IDBKeyRange {
    const hasMin = range.min != null;
    const hasMax = range.max != null;
    const hasOnly = range.only != null;

    if (hasMin && hasMax) {
      return IDBKeyRange.bound(range.min, range.max, range.isMinOpen, range.isMaxOpen);
    } else if (hasMin) {
      return IDBKeyRange.lowerBound(range.min, range.isMinOpen);
    } else if (hasMax) {
      return IDBKeyRange.upperBound(range.max, range.isMaxOpen);
    } else if (hasOnly) {
      return IDBKeyRange.only(range.only);
    } else return null;
  }

  private openDatabase(): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
      if (this.indexedDBExists) {
        const openRequest = indexedDB.open(DatabaseManager.DATABASE_NAME, DatabaseManager.VERSION);
        let db: IDBDatabase;
        openRequest.onupgradeneeded = (event: IDBVersionChangeEvent) => {
          db = event.target["result"] as IDBDatabase;
          for (const item of DatabaseManager.STORE_NAMES.entries()) {
            const [storeName, indexes] = item;
            if (!db.objectStoreNames.contains(storeName)) {
              const objectStore = db.createObjectStore(storeName, { keyPath: "id", autoIncrement: true });
              this.createIndex(objectStore, indexes);
            } else {
              const transaction = event.target["transaction"];
              const objectStore = transaction.objectStore(storeName);
              this.createIndex(objectStore, indexes);
            }
          }
        };

        openRequest.onsuccess = event => {
          db = event.target["result"] as IDBDatabase;
          resolve(db);
        };

        openRequest.onerror = event => {
          const target = event.target as IDBOpenDBRequest;
          reject(target.error as DOMException);
        };
      } else reject(new GovendasError(GovendasErrorCode.ResourceUnavailable, "IndexedDB unavaliable"));
    });
  }

  private getObjectStore(storeName: string, mode: IDBTransactionMode): Promise<IDBObjectStore> {
    return this.openDatabase().then(db => {
      if (db) {
        const objStore = db.transaction([storeName], mode).objectStore(storeName);
        db.close();
        return objStore;
      } else return Promise.reject(new GovendasError(GovendasErrorCode.Unknown, `${storeName} database: Error during open operation`));
    });
  }

  public getAll(storeName: string, range: IndexQueryRange, indexName?: string): Promise<any> {
    return this.getObjectStore(storeName, "readonly").then(objStore => {
      if (objStore) {
        const queryRange = this.buildKeyRange(range);

        const req = indexName ? objStore.index(indexName).getAll(queryRange) : objStore.getAll(queryRange);

        return new Promise((resolve, reject) => {
          req.onsuccess = event => {
            resolve(event.target["result"]);
          };
          req.onerror = event => {
            reject(event["error"]);
          };
        });
      } else return Promise.reject(new GovendasError(GovendasErrorCode.Unknown, `${storeName} database: Error during getObjectStore operation`));
    });
  }

  public addItem(storeName: string, value: any): Promise<any> {
    return this.getObjectStore(storeName, "readwrite").then(objStore => {
      if (objStore) {
        const req = objStore.add(value);

        return new Promise((resolve, reject) => {
          req.onsuccess = event => {
            resolve(event.target["result"]);
          };
          req.onerror = event => {
            reject(event["error"]);
          };
        });
      } else return Promise.reject(new GovendasError(GovendasErrorCode.Unknown, `${storeName} database: Error during getObjectStore operation`));
    });
  }

  public getByKey(storeName: string, key: number): Promise<any> {
    return this.getObjectStore(storeName, "readonly").then(objStore => {
      if (objStore) {
        const req = objStore.get(key);

        return new Promise((resolve, reject) => {
          req.onsuccess = event => {
            resolve(event.target["result"]);
          };
          req.onerror = event => {
            reject(event["error"]);
          };
        });
      } else return Promise.reject(new GovendasError(GovendasErrorCode.Unknown, `${storeName} database: Error during getObjectStore operation`));
    });
  }

  public updateItem(storeName: string, value: any): Promise<any> {
    return this.getObjectStore(storeName, "readwrite").then(objStore => {
      if (objStore) {
        const req = objStore.put(value);

        return new Promise((resolve, reject) => {
          req.onsuccess = event => {
            resolve(event.target["result"]);
          };
          req.onerror = event => {
            reject(event["error"]);
          };
        });
      } else return Promise.reject(new GovendasError(GovendasErrorCode.Unknown, `${storeName} database: Error during getObjectStore operation`));
    });
  }

  public removeItem(storeName: string, range: IndexQueryRange, indexName?: string): Promise<any[]> {
    const removed = [];
    return this.getAll(storeName, range, indexName).then(async result => {
      if (result) {
        for (const item of result) {
          removed.push(item);
          await this.removeById(storeName, item.id);
        }

        return removed;
      } else return [];
    });
  }

  public removeById(storeName: string, id: number): Promise<any> {
    return this.getObjectStore(storeName, "readwrite").then(objStore => {
      if (objStore) {
        const req = objStore.delete(id);

        return new Promise((resolve, reject) => {
          req.onsuccess = event => {
            resolve(event.target["result"]);
          };
          req.onerror = event => {
            reject(event["error"]);
          };
        });
      } else return Promise.reject(new GovendasError(GovendasErrorCode.Unknown, `${storeName} database: Error during getObjectStore operation`));
    });
  }

  public openCursorByRange(storeName: string, callback: (evt: Event) => void, range: IndexQueryRange, index: string): Promise<any> {
    return this.getObjectStore(storeName, "readwrite").then(objectStore => {
      if (objectStore) {
        const storeByIndex = objectStore.index(index);
        const keyRange = this.buildKeyRange(range);
        const cursorRequest = storeByIndex.openCursor(keyRange);
        return new Promise<void>((resolve, reject) => {
          cursorRequest.onsuccess = async (evt: Event) => {
            await callback(evt);
            resolve();
          };
          cursorRequest.onerror = (evt: Event) => {
            reject(new Error("Failure on indexeddb during openCursor execution"));
          };
        });
      } else return Promise.reject(new GovendasError(GovendasErrorCode.Unknown, `${storeName} database: Error during getObjectStore operation`));
    });
  }

  public openCursor(storeName: string, callback: (evt: Event) => void): Promise<any> {
    return this.getObjectStore(storeName, "readwrite").then(objectStore => {
      if (objectStore) {
        const cursorRequest = objectStore.openCursor();
        return new Promise<void>((resolve, reject) => {
          cursorRequest.onsuccess = (evt: Event) => {
            callback(evt);
            resolve();
          };
          cursorRequest.onerror = (evt: Event) => {
            reject(new Error("Failure on indexeddb during openCursor execution"));
          };
        });
      }
      return Promise.reject(new GovendasError(GovendasErrorCode.Unknown, `${storeName} database: Error during getObjectStore operation`));
    });
  }

  public countAll(storeName: string): Promise<any> {
    return this.getObjectStore(storeName, "readwrite").then(objectStore => {
      if (objectStore) {
        const countRequest = objectStore.count();
        return new Promise((resolve, reject) => {
          countRequest.onsuccess = () => {
            resolve(countRequest.result);
          };
          countRequest.onerror = () => {
            reject(new Error("Failure on indexeddb during count execution"));
          };
        });
      } else return Promise.reject(new GovendasError(GovendasErrorCode.Unknown, `${storeName} database: Error during getObjectStore operation`));
    });
  }

  public countByRange(storeName: string, range: IndexQueryRange, index: string): Promise<any> {
    return this.getObjectStore(storeName, "readwrite").then(objectStore => {
      if (objectStore) {
        const storeIndex = objectStore.index(index);
        const idbRange = this.buildKeyRange(range);
        const countRequest = storeIndex.count(idbRange);
        return new Promise((resolve, reject) => {
          countRequest.onsuccess = () => {
            resolve(countRequest.result);
          };
          countRequest.onerror = () => {
            reject(new Error("Failure on indexeddb during count execution"));
          };
        });
      } else return Promise.reject(new GovendasError(GovendasErrorCode.Unknown, `${storeName} database: Error during getObjectStore operation`));
    });
  }

  public clearStorage(): IDBOpenDBRequest {
    if (this.indexedDBExists) return indexedDB.deleteDatabase(DatabaseManager.DATABASE_NAME);
    else throw new GovendasError(GovendasErrorCode.ResourceUnavailable, "IndexedDB unavaliable");
  }
}
