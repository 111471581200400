import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";

import { AuthService } from "./auth.service";

import { access } from "../../../navigation/navigation";
import { WorkingTimeService } from "./working-time.service";

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(public auth: AuthService, public router: Router, private workingTimeService: WorkingTimeService) {}
  public canActivate(routeSnapshot: ActivatedRouteSnapshot, stateSnapshot: RouterStateSnapshot): boolean {
    const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    if (isIEOrEdge) {
      this.router.navigateByUrl("/browser-not-supported");
      return false;
    }

    const user = JSON.parse(localStorage.getItem("user"));

    if (user && user.role) {
      const haveAccess = this.workingTimeService.checkAccess(user);
      if (!haveAccess) {
        this.router.navigateByUrl("/access-denied");
        return false;
      } else if (stateSnapshot.url.includes("/access-denied")) this.router.navigateByUrl("/");

      if (!this.auth.getToken()) {
        this.router.navigateByUrl("/auth/login");
        return false;
      } else {
        if (this.validateAccess(stateSnapshot.url, user.role)) {
          return true;
        } else {
          this.router.navigateByUrl(access[user.role][0]);
        }
      }
    } else {
      this.router.navigateByUrl("/auth/login");
      return false;
    }
  }

  private validateAccess(route: string, role: string): boolean {
    const isValid = (path: string) => {
      const pathFound = access[role].find(accessPath => {
        let regex = accessPath;
        let routeParts = regex.split("/");
        routeParts = routeParts.map(part => {
          if (part.includes(":")) {
            return "(?:\\d|\\w)+";
          } else return part;
        });
        regex = routeParts.join("/");
        regex = regex.replace("/", "\\/");

        return new RegExp(regex).test(path);
      });

      return !!pathFound;
    };

    const routSpliting = route.split("/");
    if (routSpliting.length === 4) {
      const x = routSpliting;
      x.pop();
      const subRoute = x.join("/");
      return isValid(subRoute);
    } else {
      return isValid(route);
    }
  }
}
