import { IFollowupBase } from "app/main/shared/models";

export class Indicators {
  public planned: number;
  public not_planned: number;
  public made_planned: number;
  public made_not_planned: number;
  public attended_planned: number;
  public attended_not_planned: number;
  public contacted_planned: number;
  public contacted_not_planned: number;
  public not_contacted: number;
  public positivized_planned: number;
  public positivized_not_planned: number;
  public presential_by_distance: number;
  public without_attendance_coordinate: number;
  public without_trusted_coordinate: number;

  constructor(indicatorsData?: IFollowupBase) {
    if (indicatorsData) {
      this.planned = indicatorsData.planned;
      this.not_planned = indicatorsData.not_planned;
      this.made_planned = indicatorsData.made_planned;
      this.made_not_planned = indicatorsData.made_not_planned;
      this.attended_planned = indicatorsData.attended_planned;
      this.attended_not_planned = indicatorsData.attended_not_planned;
      this.contacted_planned = indicatorsData.contacted_planned;
      this.contacted_not_planned = indicatorsData.contacted_not_planned;
      this.not_contacted = indicatorsData.not_contacted;
      this.positivized_planned = indicatorsData.positivized_planned;
      this.positivized_not_planned = indicatorsData.positivized_not_planned;
      this.presential_by_distance = indicatorsData.presential_by_distance;
      this.without_attendance_coordinate = indicatorsData.without_attendance_coordinate;
      this.without_trusted_coordinate = indicatorsData.without_trusted_coordinate;
    }
  }

  // Planned

  public getPlanned(): number {
    return this.planned != null ? this.planned : 0;
  }

  public getNotPlanned(): number {
    return this.not_planned != null ? this.not_planned : 0;
  }

  // Made

  public getMadePlanned(): number {
    return this.made_planned != null ? this.made_planned : 0;
  }

  public getMadeNotPlanned(): number {
    return this.made_not_planned != null ? this.made_not_planned : 0;
  }

  // Attended

  public getAttendedPlanned(): number {
    return this.attended_planned != null ? this.attended_planned : 0;
  }

  public getAttendedNotPlanned(): number {
    return this.attended_not_planned != null ? this.attended_not_planned : 0;
  }

  // Contacted

  public getContactedPlanned(): number {
    return this.contacted_planned != null ? this.contacted_planned : 0;
  }

  public getContactedNotPlanned(): number {
    return this.contacted_not_planned != null ? this.contacted_not_planned : 0;
  }

  public getNotContacted(): number {
    return this.not_contacted != null ? this.not_contacted : 0;
  }

  // Positivized

  public getPositivizedPlanned(): number {
    return this.positivized_planned != null ? this.positivized_planned : 0;
  }

  public getPositivizedNotPlanned(): number {
    return this.positivized_not_planned != null ? this.positivized_not_planned : 0;
  }

  // Utils

  public getPresencial(): number {
    return this.presential_by_distance != null ? this.presential_by_distance : 0;
  }

  public getWithoutCoordinate(): number {
    return this.without_attendance_coordinate != null ? this.without_attendance_coordinate : 0;
  }

  public getRemote(): number {
    return this.getMadeTotal() - this.getPresencial() - this.getWithoutCoordinate();
  }

  public getTotal(): number {
    let total = 0;
    if (this.planned != null) total += this.planned;
    if (this.not_planned != null) total += this.not_planned;
    return total;
  }

  public getMadeTotal(): number {
    let total = 0;
    if (this.made_planned != null) total += this.made_planned;
    if (this.made_not_planned != null) total += this.made_not_planned;
    return total;
  }

  public getFinalized(): number {
    let total = 0;
    if (this.made_planned != null) total += this.made_planned;
    if (this.made_not_planned != null) total += this.made_not_planned;
    return total;
  }

  public getInProgress(): number {
    let total = 0;
    if (this.contacted_planned != null) total += this.contacted_planned;
    if (this.contacted_not_planned != null) total += this.contacted_not_planned;
    return total;
  }

  public getPositivizedPercent(): number {
    let percent = 0;

    if (this.positivized_planned != null) percent += this.positivized_planned;
    if (this.positivized_not_planned != null) percent += this.positivized_not_planned;
    this.getMadeTotal() !== 0 ? (percent /= this.getMadeTotal()) : (percent = 0);
    return percent * 100;
  }

  public getPositivizedTotal(): number {
    let total = 0;

    if (this.positivized_planned != null) total += this.positivized_planned;
    if (this.positivized_not_planned != null) total += this.positivized_not_planned;
    return total;
  }

  public getAtendPercent(): number {
    let realizad = 0;
    if (this.attended_planned != null) realizad += this.attended_planned;
    if (this.attended_not_planned != null) realizad += this.attended_not_planned;
    if (this.not_planned != null) realizad += this.not_planned;

    const ret = parseFloat((realizad / this.getTotal()).toFixed(2));
    return ret;
  }
}
