import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import * as _ from "lodash";
import { Observable, throwError } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { AccountParams, ClientParams, ClientWithParams, IClientParams, IClientWithParams, IUser } from "../models";
import { ParametersRepository } from "../repositories";
import { UpdatedParameters } from "./../models/parameters";
import { AgendaRepository } from "./../repositories/agenda.repository";
import { AuthService } from "./auth.service";
import { OnlineService } from "./online.service";
import { SentryErrorHandler } from "./sentry-error-handler.service";

@Injectable()
export class ParameterService {
  private BASE_URL = environment.BASE_URL;

  constructor(
    public http: HttpClient,
    public paramsRepository: ParametersRepository,
    private agendaRepository: AgendaRepository,
    private auth: AuthService,
    public onlineService: OnlineService,
    private sentryService: SentryErrorHandler
  ) {}

  get parameters(): ClientWithParams[] {
    return this.paramsRepository.params;
  }

  public getByID(userSellerID: number): Observable<HttpResponse<ClientWithParams[]>> {
    const serviceUrl = !this.onlineService.onlineSnapshot ? '/clients/params' : '/params';
    const url = `${this.BASE_URL}${serviceUrl}/${userSellerID}?token=${localStorage.getItem("token")}`;

    const headers = new HttpHeaders().set("timeout-govendas", `${1000 * 60 * 5}`);
    return this.http
      .get<IClientWithParams[]>(url, { observe: "response" , headers })
      .pipe(
        map(response => {
          this.paramsRepository.saveAll(response.body);
          return new HttpResponse({
            ...response,
            body: response.body.map(x => new ClientWithParams(x))
          });
        }),
        catchError(err => {
          this.sentryService.handleError(err);
          return throwError(err);
        })
      );
  }

  public getByClientId(userSellerID: number, clientId: string): Observable<ClientWithParams[]> {
    const serviceUrl = !this.onlineService.onlineSnapshot ? '/clients/params' : '/params';
    const url = `${this.BASE_URL}${serviceUrl}/${userSellerID}?clientId=${clientId}`;
    return this.http.get<IClientWithParams[]>(url).pipe(
      tap(resp => {
        this.paramsRepository.save(resp);
      }),
      map(resp => resp.map(x => new ClientWithParams(x))),
      catchError(err => {
        this.sentryService.handleError(err);
        return throwError(err);
      })
    );
  }

  public updateClientParameters(userID: number, params: IClientParams[]): Observable<UpdatedParameters[]> {
    const serviceParams = params.map(param => _.omitBy(param, v => v == null || v === ""));
    const serviceUrl = !this.onlineService.onlineSnapshot ? '/clients/params' : '/params';
    const url = `${this.BASE_URL}${serviceUrl}/${userID}?token=${localStorage.getItem("token")}`;
    return this.http.put<UpdatedParameters[]>(url, serviceParams).pipe(
      tap(resp => {
        const paramsToUpdate = resp.filter(x => x.status === 200).map(x => x.params);
        this.paramsRepository.updateParams(paramsToUpdate, this.auth.currentUser);
        if (paramsToUpdate.length === 1 && this.agendaRepository.agenda.length > 0) this.agendaRepository.updateFromClientParams(paramsToUpdate[0]);
      }),
      catchError(err => {
        this.sentryService.handleError(err);
        return throwError(err);
      })
    );
  }

  public updateSellerParameters(userID: number, params: IUser): Observable<IUser> {
    const serviceParams = _.omitBy(params, v => v == null || v === "");
    const url = `${this.BASE_URL}/users/update/${userID}?token=${localStorage.getItem("token")}`;
    return this.http.put<IUser>(url, serviceParams).pipe(
      catchError(err => {
        this.sentryService.handleError(err);
        return throwError(err);
      })
    );
  }

  public updateAccountParameters(params: AccountParams): Observable<AccountParams> {
    const serviceParams = {
      inactive_days: params.inactive_days,
      meta_parameters: params.toMetaParameters()
    };
    const url = `${this.BASE_URL}/accounts?token=${localStorage.getItem("token")}`;
    return this.http.put(url, serviceParams).pipe(
      map((response: AccountParams) => response),
      catchError(err => {
        this.sentryService.handleError(err);
        return throwError(err);
      })
    );
  }

  public getClientesAgregateDay(userSellerID: number): Observable<any> {
    const serviceUrl = !this.onlineService.onlineSnapshot ? '/clients/params' : '/params';
    const url = `${this.BASE_URL}${serviceUrl}/aggby/${userSellerID}?token=${localStorage.getItem("token")}`;
    return this.http.get(url).pipe(
      map((response: any[]) => response),
      catchError(err => {
        this.sentryService.handleError(err);
        return throwError(err);
      })
    );
  }

  public getHistory(clientId: string, sallesman_id: string): Observable<ClientParams[]> {
    const serviceUrl = !this.onlineService.onlineSnapshot ? '/clients/params' : '/params';
    const url = `${this.BASE_URL}${serviceUrl}/history/${clientId}?salesman_id=${sallesman_id}`;
    return this.http.get<ClientParams[]>(url).pipe(
      map(response => response.map(x => new ClientParams(x))),
      catchError(err => {
        this.sentryService.handleError(err);
        return throwError(err);
      })
    );
  }
}
