<div class="dialog-content-wrapper" style="overflow: hidden">
  <div mat-dialog-content style=" text-align: right;">
    <button mat-icon-button class="mr-24" (click)="dialogRef.close(true)" style="position: relative; ">
      <mat-icon>close</mat-icon>
    </button>
    <img *ngIf="!coordsWithoutPhoto" [src]="imageURL" style="display: block; max-width: 100%; height: auto;">
    <div *ngIf="coordsWithoutPhoto" style="width: 300px; height: 300px">
      <mat-icon class="without-coord-icon">check</mat-icon>
    </div>
  </div>
</div>
