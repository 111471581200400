export class OneSginalData {
  public app_id: string;
  public tags: { account_id: number; user_id: number };
  public language: string;
  public external_user_id: string;
  public notification_types?: 1 | -2;
}

export interface OnseSignalUpdateUserResponse {
  success: boolean;
}
