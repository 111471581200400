<ngx-spinner name="product-history" bdColor="rgba(51, 51, 51, 0.8)" size="small" color="#fff" type="ball-clip-rotate"
  size="default">
</ngx-spinner>

<div class="dialog-custom" fxLayout="column" fxLayoutAlign="stretch" style="overflow: hidden">
  <mat-toolbar class="mat-accent m-0 mat-elevation-z1">
    <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
      <span ngClass="title dialog-title" ngClass.xs="h4 font-weight-400 width-80-p">
        <button mat-icon-button class="ml-12" (click)="backToView()">
          <mat-icon>arrow_back</mat-icon>
        </button> Histórico
      </span>
      <div fxLayout="row" fxLayoutAlign="center start">
        <button mat-icon-button (click)="onCancel()" aria-label="Fechar janela">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>

  <div class="p-16" fxLayout="row" fxLayoutAlign="start center">
    <div>
      <span class="h4" matLine>{{data.client?.client_id}} - {{data.client?.client}} </span>
      <p class="m-0 h4">{{data.client?.neighborhood}}
        <span *ngIf="!!data.client?.thoroughfare">, {{data.client?.thoroughfare}}</span>
        <span *ngIf="!!data.client?.house_number">, {{data.client?.house_number}}</span>
        <span *ngIf="!!data.client?.city">, {{data.client?.city}}</span>
        <span *ngIf="!!data.client?.phone">, Fone: {{data.client?.phone}}</span>
      </p>
    </div>
  </div>
  <mat-divider></mat-divider>

  <div fxLayout="column" fxLayoutAlign="stretch" class="modal-content">
    <div style="margin-left: 16px;">
      <p>
        {{data.productId}} - {{data.productName}}
      </p>
    </div>

    <mat-divider></mat-divider>

    <div fxLayout="row" fxLayout.lt-md="column" class="p-12">
      <div class="p-16" *ngIf="!(history.length > 0)">
        <div class="timeline-item">
          <div class="feedback feedback-anotations-status">Sem dados para exibir</div>
        </div>
      </div>
      
      <div *ngIf="history.length > 0">
        <div [ngClass]="getColorByFeedbackID(' ', itemHistory)" class="timeline-item"
          *ngFor="let itemHistory of history; let i = index">
          <div class="feedback-visitdate bdg">
            <span>{{itemHistory.feedback_date | amLocale:'pt-br' | amDateFormat:'DD/MM/YYYY HH:mm'}}</span>
          </div>
          <span class="feedback-name_seller" *ngIf="!!itemHistory.salesman_id">
            {{itemHistory.salesman_id}} - {{itemHistory.salesman}}
          </span>
          <span [ngClass]="getColorByFeedbackID('color-', itemHistory)"
            class="line-height-18 font-size-16 mt-5 mb-5 feedback feedback-anotations-status">
            {{this.feedbackMapping[itemHistory.feedback_id].label}}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
