import { WeeksPossibleComponent } from "./components/weeks-possible/weeks-possible.component";
import { PushNotificationService } from "./services/push-notification.service";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TimelineComponent } from "./components/timeline/timeline.component";
import { IndicadoresComponent } from "./components/indicadores/indicadores.component";

import { MomentModule } from "ngx-moment";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatIconModule } from "@angular/material/icon";

import { FuseSharedModule } from "@fuse/shared.module";
import { MatBadgeModule } from "@angular/material/badge";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTableModule } from "@angular/material/table";

import { NgxDatatableModule } from "@swimlane/ngx-datatable";

import { InputBuscaComponent } from "./components/input-busca/input-busca.component";
import { NgxSpinnerModule } from "ngx-spinner";
import { CameraComponent } from "./components/camera/camera.component";
import { AcessCameraAndLocationComponent } from "./components/acess-camera-and-location/acess-camera-and-location.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatToolbarModule } from "@angular/material/toolbar";
import { AlertNotificationsComponent } from "./components/alert-notifications/alert-notifications.component";
import { MatMenuModule } from "@angular/material/menu";
import { NotificationSevice } from "./services/notification.service";
import { VisibilityService } from "./services/sales-planning.service";
import { StatusAgendaPipe } from "../shared/pipes/status-agenda";
import { NewNotificationComponent } from "./components/alert-notifications/components/new-notification/new-notification.component";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { ClientParametersFormComponent } from "./components/client-parameters-form/client-parameters-form.component";
import { DayPreferenceComponent } from "./components/client-parameters-form/components/day-preference/day-preference.component";
import { NetworkStatusComponentComponent } from "./components/network-status-component/network-status-component.component";
import { StorageImageService } from "./services/storageImage.service";
import { SanitizeStylePipe } from "./pipes/sanitizeStylePipe.pipe";
import { SentryErrorHandler, SyncService, CacheService, MetricsService, AuthService } from "./services";
import { ContainerRefDirective } from "./directives/container-ref.directive";
import { ModalClientHistoryComponent } from "./components/modal-client-history/modal-client-history.component";
import { ExcelService } from "./services/excel.service";
import { PhotoClientComponent } from "./components/photo-client/photo-client.component";
import { PhotoViewModalComponent } from "./components/photo-view-modal/photo-view-modal.component";
import { CameraClientesFormComponent } from "./components/camera-clientes-form/camera-clientes-form.component";
import { DaysPossibleComponent } from "./components/days-possible/days-possible.component";
import { EllipsisPipe } from "./pipes/ellipsisPipe";
import { AgendaRepository, ClientRepository, ParametersRepository, NotificationsRepository } from "./repositories";
import { ModalConfirmOfflineToogleComponent } from "./components/modal-confirm-offline-toogle/modal-confirm-offline-toogle.component";
import { StoragesManagementService } from "./services/storages-management.service";
import { ModalCnaeDescriptionComponent } from "./components/modal-cnae-description/modal-cnae-description.component";
import { ModalHistoryParamtersComponent } from "./components/modal-history-paramters/modal-history-paramters.component";
import { FirstWord } from "./pipes/firstWord.pipe";
import { TimeLineParamtersComponent } from "./components/time-line-paramters/time-line-paramters.component";
import { ModalSituationDescriptionComponent } from "./components/modal-situation-description/modal-situation-description.component";
import { ModalRuptureDescriptionComponent } from "./components/modal-rupture-description/modal-rupture-description.component";
import { CategoryJustifySchedulePipe } from "./pipes/category-justify-schedule-pipe.pipe";
import { ShortReasonJustifySchedulePipe } from "./pipes/reason-justify-schedule-pipe.pipe";
import { LongReasonJustifySchedulePipe } from "./pipes/reason-justify-schedule-pipe.pipe";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { ModalTextDescriptionComponent } from "./components/modal-description-text/modal-description-text.component";
import { PhoneLabelPipe } from "./pipes/phone-label-pipe";
import { ModalComponentComponent } from "./components/modal-component/modal-component.component";
import { ProductListComponent } from "app/main/shared/components/product-list/product-list.component";
import { SugestService } from "./services/sugest.service";
import { ProductItemComponent } from "./components/product-list/components/product-item.component";
import { MatInputModule } from "@angular/material/input";
import { MatRadioModule } from "@angular/material/radio";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { ConfirmLocationComponent } from "./components/confirm-location/confirm-location.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatChipsModule } from "@angular/material/chips";
import { MatSelectModule } from "@angular/material/select";
import { TimelineProductComponent } from "./components/timeline-product/timeline-product.component";
import { ProductService } from "./services/product.service";
import { MatCardModule } from "@angular/material/card";
import { NotificationsHistoryContainerComponent } from "./components/notifications-history-container/notifications-history-container.component";
import { MatListModule } from "@angular/material/list";
import { ActionCardsComponent } from "./components/action-cards/action-cards.component";
import { ListaVendedoresComponent } from "./components/lista-vendedores/lista-vendedores.component";
import { MatExpansionModule } from "@angular/material/expansion";
import { MarkdownPipe } from "./pipes/markdown.pipe";
import { RemindersHistoryComponent } from "./components/alert-notifications/components/reminders-history/reminders-history.component";
import { ReminderToastrComponent } from "./components/alert-notifications/components/reminder-toastr/reminder-toastr.component";
import { ReminderDelayComponent } from "./components/alert-notifications/components/reminder-delay/reminder-delay.component";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { RulesService } from "./services/rules.service";
import { PushNotificationsRepository } from "./repositories/push-notifications.repository";
import { ExpandableChipComponent } from "./components/expandable-chip/expandable-chip.component";
import { ModalActivityDescriptionComponent } from "./components/modal-activity-description/modal-activity-description.component";
import { ConfirmRetrieveComponent } from "./components/confirm-retrieve/confirm-retrieve.component";
import { UserRolePipe } from "./pipes/user-role";

@NgModule({
  declarations: [
    TimelineComponent,
    IndicadoresComponent,
    EllipsisPipe,
    PhoneLabelPipe,
    UserRolePipe,
    CategoryJustifySchedulePipe,
    ShortReasonJustifySchedulePipe,
    LongReasonJustifySchedulePipe,
    FirstWord,
    InputBuscaComponent,
    CameraComponent,
    AcessCameraAndLocationComponent,
    AlertNotificationsComponent,
    StatusAgendaPipe,
    NetworkStatusComponentComponent,
    StatusAgendaPipe,
    NewNotificationComponent,
    SanitizeStylePipe,
    ClientParametersFormComponent,
    DayPreferenceComponent,
    ContainerRefDirective,
    ModalClientHistoryComponent,
    PhotoClientComponent,
    PhotoViewModalComponent,
    CameraClientesFormComponent,
    DaysPossibleComponent,
    ModalConfirmOfflineToogleComponent,
    ModalCnaeDescriptionComponent,
    ModalHistoryParamtersComponent,
    TimeLineParamtersComponent,
    ModalSituationDescriptionComponent,
    ModalRuptureDescriptionComponent,
    ModalTextDescriptionComponent,
    LongReasonJustifySchedulePipe,
    ModalComponentComponent,
    ProductListComponent,
    ProductItemComponent,
    ConfirmLocationComponent,
    TimelineProductComponent,
    NotificationsHistoryContainerComponent,
    ActionCardsComponent,
    WeeksPossibleComponent,
    ListaVendedoresComponent,
    MarkdownPipe,
    RemindersHistoryComponent,
    ReminderToastrComponent,
    ReminderDelayComponent,
    ExpandableChipComponent,
    ModalActivityDescriptionComponent,
    ConfirmRetrieveComponent
  ],
  imports: [
    // Material
    MatIconModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatTabsModule,
    MatMenuModule,
    MatBadgeModule,
    MatDatepickerModule,
    MatToolbarModule,
    MatPaginatorModule,
    MatSortModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatChipsModule,
    MatSelectModule,
    MatCardModule,
    MatTableModule,
    MatListModule,
    MatExpansionModule,

    // Fuse modules
    FormsModule,
    ReactiveFormsModule,

    FuseSharedModule,
    CommonModule,
    MomentModule,
    FlexLayoutModule,
    NgxDatatableModule,
    NgxSpinnerModule,
    MatButtonModule,
    MatTabsModule,
    MatMenuModule,
    MatBadgeModule,
    MatTooltipModule,

    NgxMaterialTimepickerModule,
  ],
  exports: [
    TimelineComponent,
    IndicadoresComponent,
    EllipsisPipe,
    UserRolePipe,
    PhoneLabelPipe,
    CategoryJustifySchedulePipe,
    ShortReasonJustifySchedulePipe,
    LongReasonJustifySchedulePipe,
    FirstWord,
    InputBuscaComponent,
    CameraComponent,
    AcessCameraAndLocationComponent,
    AlertNotificationsComponent,
    StatusAgendaPipe,
    NewNotificationComponent,
    NetworkStatusComponentComponent,
    SanitizeStylePipe,
    ContainerRefDirective,
    ModalClientHistoryComponent,
    PhotoViewModalComponent,
    PhotoClientComponent,
    CameraClientesFormComponent,
    DaysPossibleComponent,
    TimeLineParamtersComponent,
    LongReasonJustifySchedulePipe,
    ModalComponentComponent,
    ConfirmLocationComponent,
    ActionCardsComponent,
    WeeksPossibleComponent,
    ListaVendedoresComponent,
    MarkdownPipe,
    RemindersHistoryComponent,
    ExpandableChipComponent
  ],
  providers: [
    CameraComponent,
    NotificationSevice,
    StorageImageService,
    VisibilityService,
    SentryErrorHandler,
    ExcelService,
    PhotoViewModalComponent,
    SyncService,
    CacheService,
    StoragesManagementService,
    AuthService,
    MetricsService,
    AgendaRepository,
    ClientRepository,
    ParametersRepository,
    NotificationsRepository,
    PushNotificationsRepository,
    LongReasonJustifySchedulePipe,
    ProductListComponent,
    ProductItemComponent,
    SugestService,
    ProductService,
    PushNotificationService,
    RulesService
  ]
})
export class SharedModule {}
