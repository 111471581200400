import { Injectable } from "@angular/core";
import { SyncData, TrackingEvents } from "../models";
import { DatabaseManager } from "./database-manager";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "./auth.service";
import * as moment from "moment";
import { TrackingService } from "./tracking.service";

@Injectable()
export class SyncService {
  constructor(
    public httpClient: HttpClient,
    public dbManager: DatabaseManager,
    private authService: AuthService,
    private trackingService: TrackingService
  ) {}

  public getByID(id: number): Promise<SyncData> {
    return this.dbManager.getByKey(DatabaseManager.SYNC_STORE_NAME, id);
  }

  public async getByPatternId(patternId: number): Promise<SyncData[]> {
    const today = new Date();
    const yesterday = moment().subtract(1, "day").startOf("day").toDate();
    today.setHours(0, 0, 0, 0);
    const user = await this.authService.getUser().toPromise();
    const range = {
      min: [user.account_id, user.id, patternId, yesterday],
      max: [user.account_id, user.id, patternId, today]
    };

    return this.dbManager.getAll(DatabaseManager.SYNC_STORE_NAME, range, "patternByUser");
  }

  public async getAll(): Promise<SyncData[]> {
    const today = new Date();
    const yesterday = moment().subtract(1, "day").startOf("day").toDate();
    today.setHours(0, 0, 0, 0);
    const user = await this.authService.getUser().toPromise();
    const range = {
      min: [user.account_id, user.id, yesterday],
      max: [user.account_id, user.id, today]
    };

    return this.dbManager.getAll(DatabaseManager.SYNC_STORE_NAME, range, "accountId_userId");
  }

  public addItem(notification: SyncData): Promise<SyncData> {
    return this.dbManager.addItem(DatabaseManager.SYNC_STORE_NAME, notification);
  }

  public async countItems(): Promise<number> {
    const today = new Date();
    const yesterday = moment().subtract(1, "day").startOf("day").toDate();
    today.setHours(0, 0, 0, 0);
    const user = await this.authService.getUser().toPromise();
    const range = {
      min: [user.account_id, user.id, yesterday],
      max: [user.account_id, user.id, today]
    };
    return this.dbManager.countByRange(DatabaseManager.SYNC_STORE_NAME, range, "accountId_userId");
  }

  public async syncItems(): Promise<any> {
    const today = new Date();
    const yesterday = moment().subtract(1, "day").startOf("day").toDate();
    today.setHours(0, 0, 0, 0);
    const user = await this.authService.getUser().toPromise();
    const range = {
      min: [user.account_id, user.id, yesterday],
      max: [user.account_id, user.id, today]
    };

    return this.dbManager.getAll(DatabaseManager.SYNC_STORE_NAME, range, "accountId_userId").then(resp => {
      if (!resp) return null;
      return new Promise<void>(async (resolve, reject) => {
        const clientIds = [];
        for (const item of resp) {
          const itemClone = JSON.parse(JSON.stringify(item));
          const { id, url, httpMethod, payload } = itemClone;
          try {
            await this.httpClient.request(httpMethod, url, { body: payload }).toPromise();
            await this.dbManager.removeById(DatabaseManager.SYNC_STORE_NAME, id);
            if (payload.client_id) clientIds.push(payload.client_id);
          } catch (error) {
            reject(error);
          }
        }

        const trackData = {
          ...(clientIds.length > 0 && { client_ids: clientIds })
        };
        this.trackingService.track(TrackingEvents.FEEDBACK_SYNCED, trackData);

        resolve();
      });
    });
  }

  public removeById(id: number): Promise<SyncData> {
    return this.dbManager.removeById(DatabaseManager.SYNC_STORE_NAME, id);
  }

  public async getAllbyDay(): Promise<any> {
    const today = new Date();
    const yesterday = moment().subtract(1, "day").startOf("day").toDate();
    today.setHours(0, 0, 0, 0);
    const user = await this.authService.getUser().toPromise();
    const range = {
      min: [user.account_id, user.id, yesterday],
      max: [user.account_id, user.id, today]
    };

    return this.dbManager.getAll(DatabaseManager.SYNC_STORE_NAME, range, "accountId_userId").then((items: any[]) => {
      const ret = [];
      if (items && items.length > 0) {
        items.forEach(element => {
          ret.push(element);
        });
        return ret;
      } else return null;
    });
  }

  public canSync(): boolean {
    return this.dbManager.indexedDBExists;
  }
}
