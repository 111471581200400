export enum TrackingEvents {
  //A
  AGENDA_LOADED = "agenda carregada",
  AGENDA_SERVICE_LOADED = "dados de agenda obtidos",
  AGENDA_FILLED_BY_SYNC_DATA = "agenda enriquecida por dados não sincronizados",

  //C
  CLIENT_INFO_OPENED = "Aberto modal clientInfo",
  CLIENT_FOUND = "client encontrado",

  //D
  DETACHED_FEEDBACK_OPENED = "abrir registro de feedback avulso",

  //F
  FEEDBACK_REGISTERED = "Feedback registrado",
  FEEDBACK_TO_SYNC = "Há feedbacks armazenados",
  FEEDBACK_SYNCED = "Feedbacks sincronizados",

  //N
  NOTIFICATION_VISUALIZED = "Notificação visualizada",

  //P
  PHONE_SHOWED = "exibido telefone",

  //R
  REMINDER_OPENED = "lembrete aberto",
  REMINDER_TRIGGED = "lembrete disparado",
  REMINDER_ADDED = "lembrete adicionado",

  //S
  SD_EVENT_OPENED = "evento do SD aberto",
  SD_EVENT_RECEIVED = "evento do SD recebido",
  SEARCH_CLIENT = "buscar cliente"
}
