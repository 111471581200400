export class CacheData<T> {
  public id?: number;
  public url: string;
  public response: T;

  constructor(url: string, response: T) {
    this.url = url;
    this.response = response;
  }
}
