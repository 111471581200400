import { ViewService } from "./../../services/view.service";
import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Component, OnInit, ChangeDetectorRef, AfterViewInit, AfterViewChecked, Inject } from "@angular/core";
import { User, Agenda } from "../../models";
import { NgxSpinnerService } from "ngx-spinner";
import { IProductFeedbackMapping, ProductHistoryItem } from "./../product-list/model/product-feedback";
import { ProductService } from "app/main/shared/services";
import { Client } from "app/main/shared/models";

@Component({
  selector: "timeline-product",
  templateUrl: "./timeline-product.component.html",
  styleUrls: ["./timeline-product.component.scss"]
})
export class TimelineProductComponent implements OnInit, AfterViewInit, AfterViewChecked {
  public clientId: string;

  public user: User;

  public history: ProductHistoryItem[];

  public informacaoCarregada: boolean;

  public feedbackMapping: { [key: string]: IProductFeedbackMapping };

  constructor(
    private _spinner: NgxSpinnerService,
    private _cdRef: ChangeDetectorRef,
    public dialogRef: MatDialogRef<TimelineProductComponent>,
    public viewService: ViewService,
    private productService: ProductService,
    @Inject(MAT_DIALOG_DATA) public data: { client: Client; productId: string; productName: string; user: User }
  ) {
    this.informacaoCarregada = false;
    this.history = [];
    this.feedbackMapping = {};
  }

  public ngOnInit(): void {
    this.productService.feedbackMappings.forEach(x => (this.feedbackMapping[x.feedback_id] = x));
  }

  public ngAfterViewChecked(): void {
    this._cdRef.detectChanges();
  }

  public ngAfterViewInit(): void {
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 7);
    const endDate = new Date();

    if (this.data) {
      this.clientId = this.data.client.client_id;
      this.user = this.data.user;
    }

    this.showSpinner();
    this.productService.getHistory(this.clientId, this.data.productId, startDate, endDate).subscribe(resp => {
      this.hideSpinner();
      this.history = resp;
      this.history.forEach(item => (item.positive = this.feedbackMapping[item.feedback_id].positive));
    });
  }

  public showSpinner(): void {
    this._spinner.show("product-history");
  }

  public hideSpinner(): void {
    this._spinner.hide("product-history");
  }

  public getColorByFeedbackID(prefix: string, itemHistory: ProductHistoryItem): string {
    const feedbackColor = itemHistory.positive ? "st-positivado" : "st-naoatendido";

    return prefix + feedbackColor;
  }

  public getObservationList(agenda: Partial<Agenda>): any[] {
    const { observations } = agenda;

    if (observations) {
      const observationsList = observations.split("\r\n");
      return observationsList;
    } else return [];
  }

  public getDateAndObservation(observation: string): string[] {
    const obsParts = observation.split(": ");
    if (obsParts.length === 1) return obsParts;
    obsParts[0] = `-- ${obsParts[0]} --`;
    obsParts[1] = observation.substring(observation.indexOf(": ") + 2, observation.length);
    return obsParts;
  }

  public isSellerOtherGroup(itemHistory): boolean {
    let ret = false;

    if (this.user.group != null && itemHistory.group_code != null && itemHistory.group_code !== this.user.group) {
      ret = true;
    }

    return ret;
  }

  public onCancel(): void {
    this.dialogRef.close();
  }

  public backToView(): void {
    this.viewService.removeContent();
  }
}
