import { FuseNavigation } from "@fuse/types";
import { EventEmitterService } from "../../app/main/shared/services/event.service";

const changeOfflineStatus = online => {
  const icon = online ? "signal_wifi_off" : "signal_wifi_on";
  const title = online ? "Trabalhar Offline" : "Trabalhar Online";
  const offlineNavigator = othersNavigation.children[0];
  offlineNavigator.icon = icon;
  offlineNavigator.title = title;
};

EventEmitterService.get(EventEmitterService.CHANGE_WORK_MODE_EVENT).subscribe(online => {
  changeOfflineStatus(online);
});

EventEmitterService.get(EventEmitterService.UPDATE_OFFLINE_ICON).subscribe(online => {
  changeOfflineStatus(online);
});

const othersNavigation: any = {
  id: "others",
  title: "",
  type: "group",
  children: [
    {
      id: "changeWorkMode",
      title: "Trabalhar Offline",
      type: "item",
      icon: "signal_wifi_off",
      function: (online: boolean) => {
        EventEmitterService.get(EventEmitterService.OPEN_MODAL_OFFLINE_TOGGLE_EVENT).emit(online);
      }
    },

    {
      id: "academyGovendas",
      title: "Academia goVendas",
      type: "item",
      icon: "local_library",
      function: () => {
        EventEmitterService.get(EventEmitterService.ACADEMY_GOVENDAS).emit();
      }
    },

    {
      id: "refresh",
      title: "Recarregar",
      type: "item",
      icon: "sync",
      function: () => {
        EventEmitterService.get(EventEmitterService.REFRESH_EVENT).emit();
      }
    },
    {
      id: "sair",
      title: "Sair",
      type: "item",
      icon: "exit_to_app",
      function: () => {
        EventEmitterService.get(EventEmitterService.LOGOUT_EVENT).emit();
      }
    }
  ]
};

if (!(navigator.serviceWorker && caches && indexedDB)) othersNavigation.children.shift();

export const navigation: FuseNavigation[] = [
  {
    id: "applications",
    title: "Principal",
    translate: "NAV.APPLICATIONS",
    type: "group",
    children: [
      // {
      //     id       : 'sample',
      //     title    : 'Sample',
      //     translate: 'NAV.SAMPLE.TITLE',
      //     type     : 'item',
      //     icon     : 'email',
      //     url      : '/sample',
      //     badge    : {
      //         title    : '25',
      //         translate: 'NAV.SAMPLE.BADGE',
      //         bg       : '#F44336',
      //         fg       : '#FFFFFF'
      //     }
      // },
      {
        id: "acompanhamento",
        title: "Acompanhamento",
        type: "item",
        icon: "important_devices",
        url: "/acompanhamento"
      },
      {
        id: "atendimento",
        title: "Atendimento",
        type: "item",
        icon: "date_range",
        url: "/atendimento"
      },
      {
        id: "reports",
        title: "Relatórios",
        type: "item",
        icon: "assessment",
        url: "/relatorios"
      }
    ]
  },
  othersNavigation
];

export const navigationManager: FuseNavigation[] = [
  {
    id: "applications",
    title: "Principal",
    translate: "NAV.APPLICATIONS",
    type: "group",
    children: [
      // {
      //     id       : 'sample',
      //     title    : 'Sample',
      //     translate: 'NAV.SAMPLE.TITLE',
      //     type     : 'item',
      //     icon     : 'email',
      //     url      : '/sample',
      //     badge    : {
      //         title    : '25',
      //         translate: 'NAV.SAMPLE.BADGE',
      //         bg       : '#F44336',
      //         fg       : '#FFFFFF'
      //     }
      // },
      {
        id: "acompanhamento",
        title: "Acompanhamento",
        type: "item",
        icon: "dashboard",
        url: "/acompanhamento"
      },
      {
        id: "gestao-ruptura",
        title: "Gestão de Ruptura",
        type: "item",
        icon: "build",
        url: "/gestao-ruptura"
      },
      {
        id: "usuarios",
        title: "Administração de Usuários",
        type: "item",
        icon: "supervisor_account",
        url: "/usuarios"
      },
      {
        id: "parametrosatendimento",
        title: "Parâmetros de Atendimento",
        type: "item",
        icon: "tune",
        url: "/parametrosatendimento"
      },
      {
        id: "planejamento",
        title: "Planejamento de Venda",
        type: "item",
        icon: "today",
        url: "/planejamento"
      },
      {
        id: "reports",
        title: "Relatórios",
        type: "item",
        icon: "assessment",
        url: "/relatorios"
      }
    ]
  },

  othersNavigation
];

export const navigationSupervisor: FuseNavigation[] = [
  {
    id: "applications",
    title: "Principal",
    translate: "NAV.APPLICATIONS",
    type: "group",
    children: [
      {
        id: "acompanhamento",
        title: "Acompanhamento",
        type: "item",
        icon: "dashboard",
        url: "/acompanhamento"
      },
      {
        id: "gestao-ruptura",
        title: "Gestão de Ruptura",
        type: "item",
        icon: "build",
        url: "/gestao-ruptura"
      },
      {
        id: "parametrosatendimento",
        title: "Parâmetros de Atendimento",
        type: "item",
        icon: "tune",
        url: "/parametrosatendimento"
      },
      {
        id: "usuarios",
        title: "Administração de Usuários",
        type: "item",
        icon: "supervisor_account",
        url: "/usuarios"
      },
      {
        id: "planejamento",
        title: "Planejamento de Venda",
        type: "item",
        icon: "today",
        url: "/planejamento"
      },
      {
        id: "reports",
        title: "Relatórios",
        type: "item",
        icon: "assessment",
        url: "/relatorios"
      }
    ]
  },
  othersNavigation
];

export const navigationSeller: FuseNavigation[] = [
  {
    id: "applications",
    title: "Principal",
    translate: "NAV.APPLICATIONS",
    type: "group",
    children: [
      {
        id: "atendimento",
        title: "Atendimento",
        type: "item",
        icon: "date_range",
        url: "/atendimento"
      },
      {
        id: "planejamento",
        title: "Planejamento de Venda",
        type: "item",
        icon: "today",
        url: "/planejamento"
      },
      {
        id: "parametrosatendimento",
        title: "Parâmetros de Atendimento",
        type: "item",
        icon: "tune",
        url: "/parametrosatendimento"
      },
      {
        id: "reports",
        title: "Relatórios",
        type: "item",
        icon: "assessment",
        url: "/relatorios"
      }
    ]
  },
  othersNavigation
];

export const navigationAdmin: FuseNavigation[] = [
  {
    id: "applications",
    title: "Principal",
    translate: "NAV.APPLICATIONS",
    type: "group",
    children: [
      // {
      //     id       : 'sample',
      //     title    : 'Sample',
      //     translate: 'NAV.SAMPLE.TITLE',
      //     type     : 'item',
      //     icon     : 'email',
      //     url      : '/sample',
      //     badge    : {
      //         title    : '25',
      //         translate: 'NAV.SAMPLE.BADGE',
      //         bg       : '#F44336',
      //         fg       : '#FFFFFF'
      //     }
      // },

      {
        id: "acompanhamento",
        title: "Acompanhamento",
        type: "item",
        icon: "dashboard",
        url: "/acompanhamento"
      },
      {
        id: "gestao-ruptura",
        title: "Gestão de Ruptura",
        type: "item",
        icon: "build",
        url: "/gestao-ruptura"
      },
      {
        id: "usuarios",
        title: "Administração de Usuários",
        type: "item",
        icon: "supervisor_account",
        url: "/usuarios"
      },
      {
        id: "parametrosatendimento",
        title: "Parâmetros de Atendimento",
        type: "item",
        icon: "tune",
        url: "/parametrosatendimento"
      },

      {
        id: "planejamento",
        title: "Planejamento de Venda",
        type: "item",
        icon: "today",
        url: "/planejamento"
      },
      {
        id: "reports",
        title: "Relatórios",
        type: "item",
        icon: "assessment",
        url: "/relatorios"
      }
    ]
  },

  othersNavigation
];

export const navigationMobile: FuseNavigation[] = [
  {
    id: "applications",
    title: "Principal",
    translate: "NAV.APPLICATIONS",
    type: "group",
    children: [
      // {
      //     id       : 'sample',
      //     title    : 'Sample',
      //     translate: 'NAV.SAMPLE.TITLE',
      //     type     : 'item',
      //     icon     : 'email',
      //     url      : '/sample',
      //     badge    : {
      //         title    : '25',
      //         translate: 'NAV.SAMPLE.BADGE',
      //         bg       : '#F44336',
      //         fg       : '#FFFFFF'
      //     }
      // },
      {
        id: "acompanhamento",
        title: "Acompanhamento",
        type: "item",
        icon: "important_devices",
        url: "/acompanhamento"
      },
      {
        id: "atendimento",
        title: "Atendimento",
        type: "item",
        icon: "date_range",
        url: "/atendimento"
      },
      {
        id: "reports",
        title: "Relatórios",
        type: "item",
        icon: "assessment",
        url: "/relatorios"
      }
    ]
  },
  othersNavigation
];

export const navigationManagerMobile: FuseNavigation[] = [
  {
    id: "applications",
    title: "Principal",
    translate: "NAV.APPLICATIONS",
    type: "group",
    children: [
      // {
      //     id       : 'sample',
      //     title    : 'Sample',
      //     translate: 'NAV.SAMPLE.TITLE',
      //     type     : 'item',
      //     icon     : 'email',
      //     url      : '/sample',
      //     badge    : {
      //         title    : '25',
      //         translate: 'NAV.SAMPLE.BADGE',
      //         bg       : '#F44336',
      //         fg       : '#FFFFFF'
      //     }
      // },
      {
        id: "acompanhamento",
        title: "Acompanhamento",
        type: "item",
        icon: "dashboard",
        url: "/acompanhamento"
      },
      {
        id: "usuarios",
        title: "Administração de Usuários",
        type: "item",
        icon: "supervisor_account",
        url: "/usuarios"
      },
      {
        id: "parametrosatendimento",
        title: "Parâmetros de Atendimento",
        type: "item",
        icon: "tune",
        url: "/parametrosatendimento"
      },
      {
        id: "planejamento",
        title: "Planejamento de Venda",
        type: "item",
        icon: "today",
        url: "/planejamento"
      },
      {
        id: "reports",
        title: "Relatórios",
        type: "item",
        icon: "assessment",
        url: "/relatorios"
      }
    ]
  },
  othersNavigation
];

export const navigationSupervisorMobile: FuseNavigation[] = [
  {
    id: "applications",
    title: "Principal",
    translate: "NAV.APPLICATIONS",
    type: "group",
    children: [
      // {
      //     id       : 'sample',
      //     title    : 'Sample',
      //     translate: 'NAV.SAMPLE.TITLE',
      //     type     : 'item',
      //     icon     : 'email',
      //     url      : '/sample',
      //     badge    : {
      //         title    : '25',
      //         translate: 'NAV.SAMPLE.BADGE',
      //         bg       : '#F44336',
      //         fg       : '#FFFFFF'
      //     }
      // },
      {
        id: "acompanhamento",
        title: "Acompanhamento",
        type: "item",
        icon: "dashboard",
        url: "/acompanhamento"
      },
      {
        id: "parametrosatendimento",
        title: "Parâmetros de Atendimento",
        type: "item",
        icon: "tune",
        url: "/parametrosatendimento"
      },
      {
        id: "planejamento",
        title: "Planejamento de Venda",
        type: "item",
        icon: "today",
        url: "/planejamento"
      },
      {
        id: "usuarios",
        title: "Administração de Usuários",
        type: "item",
        icon: "supervisor_account",
        url: "/usuarios"
      },
      {
        id: "reports",
        title: "Relatórios",
        type: "item",
        icon: "assessment",
        url: "/relatorios"
      }
    ]
  },
  othersNavigation
];

export const navigationSellerMobile: FuseNavigation[] = [
  {
    id: "applications",
    title: "Principal",
    translate: "NAV.APPLICATIONS",
    type: "group",
    children: [
      // {
      //     id       : 'sample',
      //     title    : 'Sample',
      //     translate: 'NAV.SAMPLE.TITLE',
      //     type     : 'item',
      //     icon     : 'email',
      //     url      : '/sample',
      //     badge    : {
      //         title    : '25',
      //         translate: 'NAV.SAMPLE.BADGE',
      //         bg       : '#F44336',
      //         fg       : '#FFFFFF'
      //     }
      // },

      {
        id: "atendimento",
        title: "Atendimento",
        type: "item",
        icon: "date_range",
        url: "/atendimento"
      },
      {
        id: "reports",
        title: "Relatórios",
        type: "item",
        icon: "assessment",
        url: "/relatorios"
      }
    ]
  },
  othersNavigation
];

export const navigationAdminMobile: FuseNavigation[] = [
  {
    id: "applications",
    title: "Principal",
    translate: "NAV.APPLICATIONS",
    type: "group",
    children: [
      // {
      //     id       : 'sample',
      //     title    : 'Sample',
      //     translate: 'NAV.SAMPLE.TITLE',
      //     type     : 'item',
      //     icon     : 'email',
      //     url      : '/sample',
      //     badge    : {
      //         title    : '25',
      //         translate: 'NAV.SAMPLE.BADGE',
      //         bg       : '#F44336',
      //         fg       : '#FFFFFF'
      //     }
      // },

      {
        id: "acompanhamento",
        title: "Acompanhamento",
        type: "item",
        icon: "dashboard",
        url: "/acompanhamento"
      },
      {
        id: "gestao-ruptura",
        title: "Gestão de Ruptura",
        type: "item",
        icon: "build",
        url: "/gestao-ruptura"
      },
      {
        id: "usuarios",
        title: "Administração de Usuários",
        type: "item",
        icon: "supervisor_account",
        url: "/usuarios"
      },
      {
        id: "parametrosatendimento",
        title: "Parâmetros de Atendimento",
        type: "item",
        icon: "tune",
        url: "/parametrosatendimento"
      },
      {
        id: "planejamento",
        title: "Planejamento de Venda",
        type: "item",
        icon: "today",
        url: "/planejamento"
      },
      {
        id: "reports",
        title: "Relatórios",
        type: "item",
        icon: "assessment",
        url: "/relatorios"
      }
    ]
  },
  othersNavigation
];

export const access: { [key: string]: string[] } = {
  All: [
    "/acompanhamento",
    "/atendimento",
    "/atendimento/lembrete",
    "/parametrosatendimento",
    "/usuarios",
    "/planejamento",
    "/relatorios",
    "/notificacao/:id",
    "/notificacao"
  ],
  MANAGER: [
    "/acompanhamento",
    "/gestao-ruptura",
    "/parametrosatendimento",
    "/planejamento",
    "/usuarios",
    "/relatorios",
    "/notificacao/:id",
    "/notificacao"
  ],
  SUPERVISOR: [
    "/acompanhamento",
    "/gestao-ruptura",
    "/parametrosatendimento",
    "/planejamento",
    "/relatorios/fotos",
    "/usuarios",
    "/relatorios",
    "/notificacao/:id",
    "/notificacao"
  ],
  SALESMAN: [
    "/atendimento",
    "/atendimento/lembrete",
    "/planejamento",
    "/parametrosatendimento",
    "/relatorios",
    "/notificacao/:id",
    "/notificacao"
  ],
  ADMIN: [
    "/usuarios",
    "/acompanhamento",
    "/gestao-ruptura",
    "/parametrosatendimento",
    "/planejamento",
    "/relatorios",
    "/notificacao/:id",
    "/notificacao"
  ]
};
