import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { OnlineService, ClientService, AuthService } from "../../services";
import { environment } from "environments/environment";
import { Client } from "../../models";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";

@Component({
  selector: "app-photo-view-modal",
  templateUrl: "./photo-view-modal.component.html",
  styleUrls: ["./photo-view-modal.component.css"]
})
export class PhotoViewModalComponent implements OnInit {
  public title: string;
  public imageURL: SafeUrl;
  public coordsWithoutPhoto = false;

  constructor(
    public onlineService: OnlineService,
    public dialogRef: MatDialogRef<PhotoViewModalComponent>,
    public clientService: ClientService,
    private auth: AuthService,
    protected _sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: Partial<Client>
  ) {
    const baseUrl = environment.BASE_URL;
    const that = this;
    const token: string = this.auth.getToken();
    this.onlineService.getOnline().subscribe(async online => {
      if (data.dataInLocalStorage) {
        this.imageURL = this.data.dataCollect.imageBase64;
      } else {
        const imageToken = that.clientService.checkClientContainsPhoto(this.data);
        const { image_infos } = this.data;
        const missing_file = image_infos && image_infos.length > 0 && image_infos[0].imageInfo.missing_file;
        if (imageToken === "" || missing_file) {
          this.imageURL = "assets/images/misc/semfoto.jpg";
        } else {
          if (online) {
            that.imageURL = this._sanitizer.bypassSecurityTrustUrl(`${baseUrl}/image?tag=original&token=${token}&tokenImg=${imageToken}`);
          } else {
            this.imageURL = this._sanitizer.bypassSecurityTrustUrl("assets/images/misc/foto_offline.png");
          }
        }
      }
    });
  }

  public ngOnInit(): void {
    const { image_infos } = this.data;
    if (image_infos && image_infos.length > 0) {
      const info = image_infos[0];
      this.coordsWithoutPhoto = info.imageInfo.missing_file;
    }
  }
}
