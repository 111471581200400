export class MetricsData {
  public requestId: string;
  public loadingName: string;
  public sendTime = 0;
  public processingTime = 0;
  public responseTime = 0;
  public backendTime = 0;
  public totalTime = 0;
  public isSucess: boolean;

  public extractData(): Partial<MetricsData> {
    const result: Partial<MetricsData> = {};

    for (const key of Object.keys(this)) {
      if (this[key] != null) result[key] = this[key];
    }

    return result;
  }
}
