export class Route {
  public visit_date = "";
  public salesman_id = "";
  public polyline = "";
  public distance = 0;
  public weight = 0;
  public duration = 0;

  constructor(source?: Route) {
    if (source) {
      if (source.visit_date != null) this.visit_date = source.visit_date;
      if (source.salesman_id != null) this.salesman_id = source.salesman_id;
      if (source.polyline != null) this.polyline = source.polyline;
      if (source.distance != null) this.distance = source.distance;
      if (source.weight != null) this.weight = source.weight;
      if (source.duration != null) this.duration = source.duration;
    }
  }
}
