import { MatDialogModule } from "@angular/material/dialog";
import { NgModule, ErrorHandler } from "@angular/core";
import { BrowserModule, DomSanitizer } from "@angular/platform-browser";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { DateAdapter } from "@angular/material/core";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatMenuModule } from "@angular/material/menu";
import { MatTableModule } from "@angular/material/table";
import { MatIconRegistry } from "@angular/material/icon";

import { FuseModule } from "@fuse/fuse.module";
import { FuseSharedModule } from "@fuse/shared.module";

import { fuseConfig } from "app/fuse-config";
import { FuseConfigService } from "@fuse/services/config.service";

import { ToastrModule } from "ngx-toastr";
import { MomentModule } from "ngx-moment";

// routing
import { AppRoutingModule } from "./app.routing";
import { LocationStrategy, HashLocationStrategy } from "@angular/common";

// services
import {
  AuthService,
  AuthGuardService,
  SellersService,
  TokenInterceptor,
  ErrorInterceptor,
  MomentUtcDateAdapter,
  AgendaService,
  FeedbackMappingService,
  FeedbackService,
  ClientService,
  RouteService,
  UsersService,
  RoleService,
  LocationService,
  DatabaseManager,
  ParameterService,
  SyncInterceptor,
  CacheInterceptor,
  AccessDeniedGuardService,
  UpdateService
} from "./main/shared/services";

import { AppComponent } from "app/app.component";

import { LogoutComponent } from "./main/logout/logout.component";
import { ServiceWorkerModule } from "@angular/service-worker";
import { SharedModule } from "./main/shared/shared.module";
import { SentryErrorHandler } from "./main/shared/services/sentry-error-handler.service";
import { UserAgenteService } from "./main/shared/services/user-agent.service";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatTooltipModule } from "@angular/material/tooltip";
import { NgxSpinnerModule } from "ngx-spinner";
import { MatButtonModule } from "@angular/material/button";
import { FlexLayoutModule } from "@angular/flex-layout";
import { TimeoutInterceptor } from "./main/shared/services/timeout.interceptor";

import { NgxCsvParserModule } from "ngx-csv-parser";
import { environment } from "environments/environment";

@NgModule({
  declarations: [AppComponent, LogoutComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: "toast-bottom-center",
      preventDuplicates: true
    }),
    HttpClientModule,

    // Material moment date module
    MatMomentDateModule,

    // Material
    MatMenuModule,
    MatTableModule,
    MatExpansionModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatTooltipModule,
    MatButtonModule,
    MatDialogModule,

    MomentModule,
    FuseSharedModule,
    NgxSpinnerModule,
    FlexLayoutModule,

    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseSharedModule,

    ServiceWorkerModule.register(`OneSignalSDKWorker.js?appId=${environment.oneSignalAppId}`, {
      enabled: true,
      registrationStrategy: "registerWhenStable:30000"
    }),

    // App modules
    SharedModule,

    NgxCsvParserModule
  ],
  providers: [
    AuthService,
    AuthGuardService,
    SellersService,
    AgendaService,
    FeedbackMappingService,
    FeedbackService,
    ClientService,
    ParameterService,
    LocationService,
    RouteService,
    UsersService,
    RoleService,
    DatabaseManager,
    UserAgenteService,
    AccessDeniedGuardService,
    UpdateService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: SyncInterceptor,
      multi: true
    },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true
    },

    { provide: DateAdapter, useClass: MomentUtcDateAdapter },
    FuseConfigService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl("./assets/mdi.svg"));
  }
}
