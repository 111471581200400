import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { environment } from "environments/environment";
import * as moment from "moment";
import { IProductListSugestion, ProductListSugestion } from "../components/product-list/model/product-list-sugestion";
import { map } from "rxjs/operators";

@Injectable()
export class SugestService {
  private BASE_URL = environment.BASE_URL;

  constructor(private http: HttpClient) {}

  public getListByClientID(client_id: String): Observable<ProductListSugestion> {
    const dateAsString = moment.utc().format("YYYY-MM-DD");

    const url = `${this.BASE_URL}/products/lists/${dateAsString}/${client_id}`;
    return this.http.get<IProductListSugestion>(url).pipe(map(x => new ProductListSugestion(x)));
  }
}
