<div class="item">
  <ngx-spinner [name]="spinnerName" bdColor="rgba(51, 51, 51, 0.8)" size="small" color="#fff" type="ball-clip-rotate" size="default" [fullScreen]="false">
  </ngx-spinner>
  <div class="item-content">
    <div class="item-content-info">
      <div>
        <div class="proprierty">{{recommendation.product_id}}</div>
        <div class="proprierty">{{recommendation.product_name}}</div>
        <div class="proprierty">Quantidade: {{recommendation.numItems}}</div>
      </div>
      <div *ngIf="hasTags(recommendation)" class="tags-container">
        <span *ngFor="let tag of recommendation.tags" matTooltip="{{tag.description}}">
          <span class="mdi mdi-arrow-up green-500-fg"> </span>
          {{tag.text}}
        </span>
      </div>
      <div class="thumbs-container ml-20">
        <span *ngIf="recommendation.isPositiveFeedback" (click)="selectThumbsUp()">
          <span class="mdi mdi-thumbs-up green-500-fg"> </span>
        </span>
        <span *ngIf="!recommendation.isPositiveFeedback" (click)="selectThumbsUp()">
          <span class="mdi mdi-thumbs-up grey-500-fg"> </span>
        </span>
  
        <span *ngIf="recommendation.isPositiveFeedback === false" class="ml-8" (click)="selectThumbsDown()">
          <span class="mdi mdi-thumbs-down red-400-fg"> </span>
        </span>
        <span *ngIf="recommendation.isPositiveFeedback == null || recommendation.isPositiveFeedback === true" class="ml-8" (click)="selectThumbsDown()">
          <span class="mdi mdi-thumbs-down grey-500-fg"> </span>
        </span>
      </div>
      <div *ngIf="canShowComplementOptions()" class="options-container ml-20">
        <mat-chip-list>
          <mat-chip *ngFor="let option of options" class="complement-option"
            [ngClass]="isOptionSelected(option) ? 'selected' : ''" (click)="selectComplementOption(option)">
            <mat-icon *ngIf="isOptionSelected(option)">check_circle_outline</mat-icon>
            {{option.label}}
          </mat-chip>
        </mat-chip-list>
      </div>
    </div>
    <div class="item-content-action" (click)="actionClicked()">
      <mat-icon>navigate_next</mat-icon>
    </div>
  </div>
</div>
