import { Injectable } from "@angular/core";
import { indexOf } from "lodash";
import * as moment from "moment";
import { NotificationData } from "../models";

@Injectable()
export class NotificationsRepository {
  private _allNotifications: NotificationData[];

  constructor() {
    this._allNotifications = [];
  }

  get allNotificationsList(): NotificationData[] {
    return this._allNotifications;
  }
  
  public save(data: NotificationData[]) : void {
    this._allNotifications = data;
  }
}
