import { Feedback, IFeedback } from "./feedback";
import { GeoJsonGeometry } from "./geo-json-point";
import { Client, IClientParams, ImageInfos } from "./client";

export interface IAgenda extends IFeedback {
  agenda_id: string;
  salesmanId: string;
  client_id: string;
  client: string;
  block: string;
  thoroughfare: string;
  house_number: string;
  neighborhood: string;
  city: string;
  state: string;
  days_without_buying: number;
  days_without_contact: number;
  rescheduled_date: Date;
  visit_date: Date;
  observations: string;
  visit_point: GeoJsonGeometry;
  feedback: string;
  seq_number: number;
  client_point: GeoJsonGeometry;
  fantasy_name: string;
  phone: string;
  non_schedulable: boolean;
  feedback_presential: boolean;
  schedule_type: number;
  generated_by: number;
  withGPS?: boolean;
  realizedIndex?: number;
  freq: number;
  mon_pref: number;
  tue_pref: number;
  wed_pref: number;
  thu_pref: number;
  fri_pref: number;
  sat_pref: number;
  sun_pref: number;
  routable: boolean;
  fixed: boolean;
  image_infos: ImageInfos[];
  situation: string;
  scheduled_metadata: ScheduledMetadata;
  is_presential_by_distance: boolean;
  has_photo: boolean;
  is_trusted: boolean;
  trusted_source: string;
  description: string;
  distance: number;
  salesman: string;
  salesman_id: string;
  divergent_info_desc: string;
  divergent_info_id: number;
}

export class ScheduledMetadata {
  public is_sales_rupture: boolean = null;
  public is_visit_rupture: boolean = null;
  public owners: String[] = [];
}

export class Agenda extends Feedback {
  public agenda_id = "";
  public salesmanId = "";
  public client_id = "";
  public client = "";
  public block = "";
  public thoroughfare = "";
  public house_number = "";
  public neighborhood = "";
  public city = "";
  public state = "";
  public days_without_buying = 0;
  public days_without_contact = 0;
  public rescheduled_date: Date = null;
  public visit_date: Date = null;
  public observations = "";
  public visit_point = new GeoJsonGeometry();
  public feedback = "";
  public seq_number = -1;
  public client_point = new GeoJsonGeometry();
  public fantasy_name = "";
  public phone = "";
  public non_schedulable: boolean = null;
  public feedback_presential: boolean = null;
  public feedback_date: Date = null;
  public generated_by = -1;
  public withGPS?: boolean;
  public realizedIndex?: number;
  public freq: number = null;
  public mon_pref: number = null;
  public tue_pref: number = null;
  public wed_pref: number = null;
  public thu_pref: number = null;
  public fri_pref: number = null;
  public sat_pref: number = null;
  public sun_pref: number = null;
  public routable: boolean;
  public fixed: boolean = null;
  public image_infos: ImageInfos[] = [];
  public situation = "";
  public is_presential_by_distance: boolean = null;
  public schedule_type = -1;
  public scheduled_metadata = new ScheduledMetadata();
  public has_photo: boolean = null;
  public is_trusted: boolean = null;
  public trusted_source = "";
  public description = "";
  public distance: number = null;
  public salesman = "";
  public salesman_id = "";
  public divergent_info_desc = "";
  public divergent_info_id: number = null;

  constructor(source?: IAgenda) {
    super(source);
    this.copy(source);
  }

  public setFeedback(feedback: IFeedback): void {
    this.feedback_id = feedback.feedback_id;
    this.feedback_presential = feedback.feedback_presential;
    this.rescheduled_date = feedback.rescheduled_date as Date;
    this.observations = feedback.observations;

    this.visit_point =
      feedback.visit_point && feedback.visit_point.coordinates && feedback.visit_point.coordinates.length > 0 ? feedback.visit_point : null;
    this.feedback_presential = feedback.feedback_presential;
    this.gps_available = feedback.gps_available;
    this.accuracy = feedback.accuracy;
    this.gps_retries = feedback.gps_retries;
  }

  public setClient(client: Client): void {
    this.client = client.client;
    this.client_id = client.client_id;
    this.fantasy_name = client.fantasy_name;
    this.house_number = client.house_number;
    this.neighborhood = client.neighborhood;
    this.state = client.state;
    this.thoroughfare = client.thoroughfare;
    this.block = client.block;
    this.city = client.city;
    this.image_infos = client.image_infos;
    this.phone = client.phone;
  }

  public setClientParams(params: IClientParams): void {
    if (params.freq != null) this.freq = params.freq;
    if (params.non_schedulable != null) this.non_schedulable = params.non_schedulable;
    if (params.mon_pref != null) this.mon_pref = params.mon_pref;
    if (params.tue_pref != null) this.tue_pref = params.tue_pref;
    if (params.wed_pref != null) this.wed_pref = params.wed_pref;
    if (params.thu_pref != null) this.thu_pref = params.thu_pref;
    if (params.fri_pref != null) this.fri_pref = params.fri_pref;
    if (params.sat_pref != null) this.sat_pref = params.sat_pref;
    if (params.sun_pref != null) this.sun_pref = params.sun_pref;
    if (params.routable != null) this.routable = params.routable;
    if (params.fixed != null) this.fixed = params.fixed;
  }

  public merge(agenda: Partial<IAgenda>): void {
    const merged = { ...this, ...agenda };
    this.copy(merged);
  }

  private copy(agenda: IAgenda): void {
    if (agenda) {
      if (agenda.agenda_id != null) this.agenda_id = agenda.agenda_id;
      if (agenda.salesmanId != null) this.salesmanId = agenda.salesmanId;
      if (agenda.client_id != null) this.client_id = agenda.client_id;
      if (agenda.client != null) this.client = agenda.client;
      if (agenda.block != null) this.block = agenda.block;
      if (agenda.thoroughfare != null) this.thoroughfare = agenda.thoroughfare;
      if (agenda.house_number != null) this.house_number = agenda.house_number;
      if (agenda.neighborhood != null) this.neighborhood = agenda.neighborhood;
      if (agenda.city != null) this.city = agenda.city;
      if (agenda.state != null) this.state = agenda.state;
      if (agenda.days_without_buying != null) this.days_without_buying = agenda.days_without_buying;
      if (agenda.days_without_contact != null) this.days_without_contact = agenda.days_without_contact;
      if (agenda.rescheduled_date != null) this.rescheduled_date = agenda.rescheduled_date;
      if (agenda.visit_date != null) this.visit_date = agenda.visit_date;
      if (agenda.observations != null) this.observations = agenda.observations;
      if (agenda.visit_point != null) this.visit_point = agenda.visit_point;
      if (agenda.feedback_id != null) this.feedback_id = agenda.feedback_id;
      if (agenda.feedback != null) this.feedback = agenda.feedback;
      if (agenda.seq_number != null) this.seq_number = agenda.seq_number;
      if (agenda.client_point != null) this.client_point = agenda.client_point;
      if (agenda.fantasy_name != null) this.fantasy_name = agenda.fantasy_name;
      if (agenda.phone != null) this.phone = agenda.phone;
      if (agenda.non_schedulable != null) this.non_schedulable = agenda.non_schedulable;
      if (agenda.feedback_presential != null) this.feedback_presential = agenda.feedback_presential;
      if (agenda.feedback_date != null) this.feedback_date = agenda.feedback_date;
      if (agenda.schedule_type != null) this.schedule_type = agenda.schedule_type;
      if (agenda.generated_by != null) this.generated_by = agenda.generated_by;
      if (agenda.withGPS != null) this.withGPS = agenda.withGPS;
      if (agenda.realizedIndex != null) this.realizedIndex = agenda.realizedIndex;
      if (agenda.freq != null) this.freq = agenda.freq;
      if (agenda.mon_pref != null) this.mon_pref = agenda.mon_pref;
      if (agenda.tue_pref != null) this.tue_pref = agenda.tue_pref;
      if (agenda.wed_pref != null) this.wed_pref = agenda.wed_pref;
      if (agenda.thu_pref != null) this.thu_pref = agenda.thu_pref;
      if (agenda.fri_pref != null) this.fri_pref = agenda.fri_pref;
      if (agenda.sat_pref != null) this.sat_pref = agenda.sat_pref;
      if (agenda.sun_pref != null) this.sun_pref = agenda.sun_pref;
      if (agenda.routable != null) this.routable = agenda.routable;
      if (agenda.fixed != null) this.fixed = agenda.fixed;
      if (agenda.is_presential_by_distance != null) this.is_presential_by_distance = agenda.is_presential_by_distance;
      if (agenda.scheduled_metadata != null) this.scheduled_metadata = agenda.scheduled_metadata;
      if (agenda.image_infos != null) this.image_infos = agenda.image_infos;
      if (agenda.has_photo != null) this.has_photo = agenda.has_photo;
      if (agenda.is_trusted != null) this.is_trusted = agenda.is_trusted;
      if (agenda.description != null) this.description = agenda.description;
      if (agenda.trusted_source != null) this.trusted_source = agenda.trusted_source;
      if (agenda.distance != null) this.distance = agenda.distance;
      if (agenda.salesman != null) this.salesman = agenda.salesman;
      if (agenda.salesman_id != null) this.salesman_id = agenda.salesman_id;
      if (agenda.divergent_info_desc != null) this.divergent_info_desc = agenda.divergent_info_desc;
      if (agenda.divergent_info_id != null) this.divergent_info_id = agenda.divergent_info_id;

      // TODO: Remover após implementar o serviço que retorna se é cliente ativo, inativo ou ruptura
      // const index = Math.floor(Math.random() * (3 - 0)) + 0;
      // this.situation = ["ativo", "inativo", "ruptura"][index];
    }
  }
}

export class NextVisitDate {
  public next_visit_date: string;
}
