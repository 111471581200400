import { User } from "./../models/user";
import { Injectable } from "@angular/core";
import { ClientWithParams, IClientWithParams, IClientParams } from "../models";

@Injectable()
export class ParametersRepository {
  private _params: ClientWithParams[];
  private paramDict: Map<string, number>;

  get params(): ClientWithParams[] {
    return this._params;
  }

  constructor() {
    this._params = [];
    this.paramDict = new Map<string, number>();
  }

  public saveAll(data: IClientWithParams[]): void {
    this._params = data.map(x => new ClientWithParams(x));
    for (let i = 0; i < this._params.length; i++) {
      const { client_id } = this.params[i].params;
      this.paramDict.set(client_id, i);
    }
  }

  public save(data: IClientWithParams[]): void {
    for (const param of data) {
      const { client_id } = param.params;
      const index = this.paramDict.get(client_id);
      if (index != null) {
        this._params[index] = new ClientWithParams(param);
      } else {
        this._params.push(new ClientWithParams(param));
        this.paramDict.set(client_id, this._params.length - 1);
      }
    }
  }

  public updateParams(data: IClientParams[], user: User): void {
    for (const param of data) {
      const { client_id } = param;
      const index = this.paramDict.get(client_id);

      if (index != null) {
        const { params } = this._params[index];
        params.fromClientParams(param);
        params.user_id = user.id;
        params.username = user.name;
      }
    }
  }
}
