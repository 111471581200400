import { ClientService } from "app/main/shared/services";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of, Subject, throwError } from "rxjs";
import { tap, concatMap, map, catchError } from "rxjs/operators";
import { Agenda, Feedback, NextVisitDate, IAgenda, GovendasResponse, Client, IClientParams, TrackingEvents } from "../models";
import { Utils } from "./utils";
import * as moment from "moment";
import { environment } from "environments/environment";
import { AgendaRepository, ClientRepository } from "../repositories";
import { SyncService } from "./sync.service";
import { OnlineService } from "./online.service";
import { SentryErrorHandler } from "./sentry-error-handler.service";
import { TrackingService } from "./tracking.service";
import { FeedbackMappingService } from "./feedback-mapping.service";
import { ToastrService } from "ngx-toastr";

@Injectable()
export class AgendaService {
  private BASE_URL = environment.BASE_URL;
  private loadedAgenda: Subject<boolean>;
  private _lastAgendaLoadDate: moment.Moment;

  get agenda(): Agenda[] {
    return this.agendaRepository.agenda;
  }

  get lastAgendaLoadDate(): moment.Moment {
    if (!this._lastAgendaLoadDate) {
      const date = localStorage.getItem("hourUploadedAgenda");
      if (date && typeof date === "string") this._lastAgendaLoadDate = moment(date);
    }

    return this._lastAgendaLoadDate;
  }

  set lastAgendaLoadDate(value: moment.Moment) {
    localStorage.setItem("hourUploadedAgenda", JSON.stringify(value));
    this._lastAgendaLoadDate = value;
  }

  constructor(
    public http: HttpClient,
    public agendaRepository: AgendaRepository,
    private syncService: SyncService,
    private clientRepository: ClientRepository,
    private clientService: ClientService,
    private onlineService: OnlineService,
    private sentryService: SentryErrorHandler,
    private feedbackMappingService: FeedbackMappingService,
    private trackingService: TrackingService,
    private toastr: ToastrService
  ) {
    this.loadedAgenda = new Subject<boolean>();
    this.checkAgendaDate();
  }

  public checkAgendaDate(): void {
    setInterval(() => {
      if (this.lastAgendaLoadDate) {
        const now = moment();

        if (now.isAfter(this.lastAgendaLoadDate, "day")) {
          this.loadedAgenda.next(true);
        } else {
          this.loadedAgenda.next(false);
        }
      }
    }, 1000 * 60 * 60);
  }

  public getAgendaNeedToReload(): Observable<boolean> {
    return this.loadedAgenda.asObservable();
  }

  private dateServerFormat(date: Date): string {
    return moment(date).format("YYYY-MM-DD");
  }

  private trackFeedbackchange(feedback: Feedback, isAddClient: boolean, requestId: string, coordsTimestamp: number): void {
    const { feedbackGroups: mappingArrayFeedbacksgroup } = this.feedbackMappingService;
    if (mappingArrayFeedbacksgroup[0].values.length > 0) {
      let data = {};
      dataLoop: for (const mapping of mappingArrayFeedbacksgroup) {
        for (const value of mapping.values) {
          const feedbackId = parseInt(value[0], 10);
          if (feedbackId === feedback.feedback_id) {
            const { rescheduled_date, feedback_presential } = feedback;
            data = {
              feedback_category: mapping.name,
              feedback_id: feedbackId,
              feedback: value[1],
              feedback_date: moment(feedback.feedback_date).format("YYYY-MM-DDTHH:mm:ssZ"),
              feedback_visit_date: moment(feedback.visit_date).format("YYYY-MM-DD"),
              client_id: feedback.client_id || "",
              feedback_detached: isAddClient,
              ...(rescheduled_date ? { rescheduled_date } : {}),
              ...(feedback_presential !== null && feedback_presential !== undefined ? { feedback_presential } : {}),
              ...(coordsTimestamp ? { coords_timestamp: coordsTimestamp } : {}),
              ...(requestId ? { request_id: requestId } : {})
            };
            break dataLoop;
          }
        }
      }
      this.trackingService.track(TrackingEvents.FEEDBACK_REGISTERED, data);
    }
  }

  public listar(date: Date): Observable<GovendasResponse<Agenda[]>> {
    const url = `${this.BASE_URL}/agenda/${Utils.getDateToServer(date)}`;
    let responseTime: number, backendTime: number;
    let startTime = moment();
    let requestId = "";

    return this.http
      .get<IAgenda[]>(url, { observe: "response" })
      .pipe(
        tap(resp => {
          responseTime = moment.duration(moment().diff(startTime)).asMilliseconds();
          backendTime = parseInt(resp.headers.get("Request-Time-Duration"), 10);
          if (backendTime) responseTime -= backendTime;
          startTime = moment();
          requestId = resp.headers.get(environment.requestIdHeader);

          const trackData: any = {
            request_id: requestId,
            agenda_date: moment(date).format("YYYY-MM-DDTHH:mm:ssZ")
          };
          if (resp.body) trackData.agenda_client_ids = resp.body.map(x => x.client_id);
          this.trackingService.track(TrackingEvents.AGENDA_SERVICE_LOADED, trackData);

          this.lastAgendaLoadDate = startTime;
        }),
        concatMap(resp => this.mergeWithClients(resp.body)),
        concatMap(async list => {
          this.agendaRepository.save(list);
          await this.mergeWithSync();
          const listAgenda = this.agendaRepository.agenda;

          const processingTime = moment.duration(moment().diff(startTime)).asMilliseconds();

          const response: GovendasResponse<Agenda[]> = {
            data: listAgenda,
            sendTime: startTime.valueOf(),
            responseTime,
            processingTime,
            backendTime,
            requestId
          };
          return response;
        }),
        catchError(err => {
          if (err instanceof HttpErrorResponse) {
            const extra = {};
            extra[environment.requestIdHeader] = err.headers.get(environment.requestIdHeader);
            this.sentryService.handleError(err, extra);
          } else {
            this.sentryService.handleError(err);
          }

          return throwError(err);
        })
      );
  }

  public listBySalesmanId(salesmanId: number, date: Date): Observable<Agenda[]> {
    const url = `${this.BASE_URL}/agenda/${salesmanId}/${Utils.getDateToServer(date)}`;
    return this.http.get<IAgenda[]>(url).pipe(
      map(resp => {
        this.agendaRepository.save(resp);

        return this.agendaRepository.agenda;
      }),
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          const extra = {};
          extra[environment.requestIdHeader] = err.headers.get(environment.requestIdHeader);
          this.sentryService.handleError(err, extra);
        } else {
          this.sentryService.handleError(err);
        }

        return throwError(err);
      })
    );
  }

  public updateStatus(feedback: Feedback, params: IClientParams, coordsTimestamp: number): Promise<Agenda | HttpErrorResponse> {
    let dateAsString = moment(feedback.visit_date).format("YYYY-MM-DD");
    if (typeof dateAsString !== "string") {
      dateAsString = Utils.getDateToServer(feedback.visit_date);
    }

    const url = `${this.BASE_URL}/agenda/${dateAsString}/${feedback.client_id}/status`;
    if (feedback.rescheduled_date) {
      feedback.rescheduled_date = this.dateServerFormat(<Date>feedback.rescheduled_date);
    }

    feedback.feedback_date = feedback.feedback_date;

    return this.http
      .put<IAgenda>(url, feedback.toFeedback(), { observe: "response" })
      .toPromise()
      .then(resp => {
        const { body } = resp;
        const requestId = resp.headers.get(environment.requestIdHeader);
        this.trackFeedbackchange(feedback, false, requestId, coordsTimestamp);
        this.saveAgendaItem(body);
        return new Agenda(body);
      })
      .catch(err => {
        if (err instanceof HttpErrorResponse) {
          if (!err.status) {
            this.agendaRepository.updateFromFeedback(feedback).then(() => this.agendaRepository.updateFromClientParams(params));
          }

          const extra = {};
          extra[environment.requestIdHeader] = err.headers.get(environment.requestIdHeader);
          this.sentryService.handleError(err, extra);
        } else {
          this.sentryService.handleError(err);
        }

        throw err;
      });
  }

  public saveAgendaItem(data: IAgenda): void {
    const index = this.agenda.map(x => x.client_id).indexOf(data.client_id);
    if (index !== -1) {
      this.agendaRepository.updateItem(index, data);
    } else {
      this.agendaRepository.add(data);
    }
  }

  public addClient(date: Date, client: Agenda, coordsTimestamp: number): Observable<Agenda> {
    const url = this.BASE_URL + "/agenda/" + Utils.getDateToServer(date) + "/client";
    const newFeedback = new Feedback();
    newFeedback.fromAgenda(client);
    const newClient = newFeedback.toFeedback();

    if (newClient.rescheduled_date) {
      newClient.rescheduled_date = this.dateServerFormat(client.rescheduled_date);
    }

    newClient.feedback_date = client.feedback_date;

    return this.http
      .post<IAgenda>(url, newClient, { observe: "response" })
      .pipe(
        tap(resp => {
          const requestId = resp.headers.get(environment.requestIdHeader);
          this.trackFeedbackchange(newFeedback, true, requestId, coordsTimestamp);
          client.merge(resp.body);
          this.saveAgendaItem(client);
        }),
        map(resp => new Agenda(resp.body)),
        catchError(err => {
          if (err instanceof HttpErrorResponse) {
            if (!err.status) this.saveAgendaItem(client);

            const extra = {};
            extra[environment.requestIdHeader] = err.headers.get(environment.requestIdHeader);
            this.sentryService.handleError(err, extra);
          } else {
            this.sentryService.handleError(err);
          }

          throw err;
        })
      );
  }

  public deleteClient(clientId: string, observations: string): Observable<boolean> {
    const url = this.BASE_URL + "/agenda/client/" + clientId;
    return this.http.post(url, { observations }).pipe(map(response => response as boolean));
  }

  public getNextVisitDate(clientId: string, date: string): Observable<NextVisitDate> {
    const url = `${this.BASE_URL}/agenda/client/${clientId}/${date}`;
    return this.http.get(url).pipe(
      map(response => response as NextVisitDate),
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          const extra = {};
          extra[environment.requestIdHeader] = err.headers.get(environment.requestIdHeader);
          this.sentryService.handleError(err, extra);
        } else {
          this.sentryService.handleError(err);
        }

        return throwError(err);
      })
    );
  }

  private mergeWithClients(agenda: IAgenda[]): Observable<IAgenda[]> {
    const ids = agenda.map(x => x.client_id);
    if (ids.length === 0) return of([]);
    const completeAgenda = (clients: Client[]) => {
      agenda.forEach(itemListaAgenda => {
        clients.forEach(itemListaClientes => {
          if (itemListaAgenda.client_id === itemListaClientes.client_id) {
            if (itemListaClientes.thoroughfare && !itemListaAgenda.thoroughfare) itemListaAgenda.thoroughfare = itemListaClientes.thoroughfare;
            if (itemListaClientes.neighborhood && !itemListaAgenda.neighborhood) itemListaAgenda.neighborhood = itemListaClientes.neighborhood;
            if (itemListaClientes.house_number && !itemListaAgenda.house_number) itemListaAgenda.house_number = itemListaClientes.house_number;
            if (itemListaClientes.city && !itemListaAgenda.city) itemListaAgenda.city = itemListaClientes.city;
            if (itemListaClientes.state && !itemListaAgenda.state) itemListaAgenda.state = itemListaClientes.state;
            if (itemListaClientes.block && !itemListaAgenda.block) itemListaAgenda.block = itemListaClientes.block;
            itemListaAgenda.fantasy_name = itemListaClientes.fantasy_name;
            itemListaAgenda.phone = itemListaClientes.phone;
            if (itemListaClientes.image_infos) {
              itemListaAgenda.image_infos = itemListaClientes.image_infos;
            }
          }
        });
      });
    };
    if (this.onlineService.onlineSnapshot) {
      return this.clientService.getByClientIds(ids, ids.length, true, true).pipe(
        map(clients => {
          if (ids.length > 0 && clients.length === 0) {
            this.toastr.warning(
              "Não foi possível carregar todas as informações dos clientes da agenda. Entre em contato com o suporte do goVendas corrigir o problema.",
              null,
              { timeOut: 10000 }
            );
          }
          completeAgenda(clients);

          return agenda;
        })
      );
    } else {
      return this.clientService.getAll(false).pipe(
        map(response => {
          const clients = response.data;
          const filteredClients = clients.filter(x => ids.includes(x.client_id));
          if (filteredClients.length > 0) completeAgenda(filteredClients);

          return agenda;
        })
      );
    }
  }

  private async mergeWithSync(): Promise<void> {
    return this.syncService
      .getAllbyDay()
      .then((syncItems: any[]) => {
        if (syncItems?.length > 0 && this.clientRepository.clients.length === 0) {
          return this.clientService
            .getAll(false)
            .toPromise()
            .then(() => syncItems);
        } else return syncItems;
      })
      .then((syncItems: any[]) => {
        if (syncItems && syncItems.length > 0) {
          const newClientReqs = syncItems.filter(x => x.patternId === 3);

          for (const req of newClientReqs) {
            const { payload } = req;
            const { client_id } = payload;
            const client = this.clientRepository.clients.find(x => x.client_id === client_id);
            if (client) {
              const copy = new Agenda();
              copy.setClient(client);

              copy.feedback_id = payload.feedback_id;
              copy.feedback_presential = payload.feedback_presential;
              copy.observations = payload.observations;
              copy.rescheduled_date = payload.rescheduled_date;
              copy.visit_point = payload.visit_point;
              copy.divergent_info_desc = payload.divergent_info_desc;

              this.saveAgendaItem(copy);
            }
          }

          const otherReqs = syncItems.filter(x => x.patternId !== 3);

          for (const req of otherReqs) {
            const { payload } = req;
            switch (req.patternId) {
              case 1:
                let { client_id } = req.payload;
                let agendaToUpdate = this.agenda.find(x => x.client_id === client_id);
                if (agendaToUpdate) {
                  const agendaCopy = new Agenda(agendaToUpdate);
                  agendaCopy.feedback_id = payload.feedback_id;
                  agendaCopy.feedback_presential = payload.feedback_presential;
                  agendaCopy.observations = payload.observations;
                  agendaCopy.rescheduled_date = payload.rescheduled_date;
                  agendaCopy.visit_point = payload.visit_point;

                  this.saveAgendaItem(agendaCopy);
                }
                break;
              case 2:
                client_id = req.payload[0].client_id;
                agendaToUpdate = this.agenda.find(x => x.client_id === client_id);
                if (agendaToUpdate) {
                  const agendaClone = new Agenda(agendaToUpdate);
                  const params = payload[0];
                  agendaClone.freq = params.freq;
                  agendaClone.mon_pref = params.mon_pref;
                  agendaClone.tue_pref = params.tue_pref;
                  agendaClone.wed_pref = params.wed_pref;
                  agendaClone.thu_pref = params.thu_pref;
                  agendaClone.fri_pref = params.fri_pref;
                  agendaClone.sat_pref = params.sat_pref;
                  agendaClone.sun_pref = params.sun_pref;
                  agendaClone.non_schedulable = params.non_schedulable;

                  this.saveAgendaItem(agendaClone);
                }
                break;
            }
          }

          const trackData: any = {};
          if (newClientReqs.length > 0) trackData.new_clients = newClientReqs.map(x => x.payload.client_id);
          if (otherReqs.length > 0) {
            const clientsToUpdate = otherReqs.filter(x => x.payload.patternId === 1);
            const params = otherReqs.filter(x => x.payload.patternId === 2);

            if (clientsToUpdate.length > 0) trackData.clients_to_update = clientsToUpdate.map(x => x.payload.client_id);
            if (params.length > 0) trackData.client_params_to_update = params.map(x => x.payload[0].client_id);
          }

          this.trackingService.track(TrackingEvents.AGENDA_FILLED_BY_SYNC_DATA, trackData);
        }
      });
  }
}
