import "zone.js";

import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "app/app.module";
import { environment } from "environments/environment";
import { hmrBootstrap } from "hmr";
import * as Sentry from "@sentry/browser";
import { Offline as OfflineIntegration } from "@sentry/integrations";
import * as mixpanel from "mixpanel-browser";
import { Utils } from "app/main/shared/services/utils";

if (environment.production) {
  enableProdMode();
}

mixpanel.init("ff2662223e93dc790ff253b95902fb11");
Sentry.init({
  dsn: "https://c640d43b4a814233a665ff4370673ca6@sentry.io/1518157",
  release: environment.app_version,
  integrations: [
    new OfflineIntegration({
      // limit how many events will be localled saved. Defaults to 30.
      maxStoredEvents: 30
    })
  ]
});

Sentry.configureScope(scope => {
  scope.setExtras({
    appVersion: environment.app_version,
    appInstalled: Utils.isAppInstalled()
  });
});

const spinner = document.getElementsByClassName("init-spinner-container")[0];

let startPressTime: number, endPressTime: number;
spinner.addEventListener("touchstart", () => {
  console.log("touchstart");
  startPressTime = new Date().getTime();
});
spinner.addEventListener("touchend", () => {
  endPressTime = new Date().getTime();
  const diffTIme = endPressTime - startPressTime;

  if (diffTIme > 2500) {
    const remoteAccessButton = document.getElementsByClassName("remote-access-button")[0];
    remoteAccessButton["style"].display = "block";
    remoteAccessButton.addEventListener("click", () => {
      localStorage.setItem("remote_debug", "true");
      location.reload();
    });
  } else {
    startPressTime = endPressTime = 0;
  }
});

const enableRemoteDebug = () => {
  const s = document.createElement("script");
  s.src = "https://remotejs.com/agent/agent.js";
  s.setAttribute("data-consolejs-channel", environment.remoteDebugId);
  document.head.appendChild(s);
};

const remoteDebug = localStorage.getItem("remote_debug") === "true";
if (remoteDebug) enableRemoteDebug();

try {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then(resp => {
      spinner["style"].display = "none";
      if (environment.hmr) {
        if (module["hot"]) hmrBootstrap(module, resp);
        else {
          console.error("HMR is not enabled for webpack-dev-server!");
          console.log("Are you using the --hmr flag for ng serve?");
        }
      }
    })
    .catch(error => {
      spinner["style"].display = "none";
      alert("Erro crítico na aplicação!");
      // if (navigator.onLine) Sentry.captureException(error.originalError || error);
    });
} catch (error) {
  spinner["style"].display = "none";
  alert("Erro crítico na aplicação!");
  // if (navigator.onLine) Sentry.captureException(error.originalError || error);
}
