import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { DatabaseManager } from "./database-manager";
import { LocationDataPhoto } from "../models";

@Injectable()
export class StorageImageService {
  public itemsCollection: any;
  public items: any;

  constructor(public http: HttpClient, public dbManager: DatabaseManager) {}

  public getList(accountId: number, userId: number): Promise<any[]> {
    const range = {
      only: [accountId, userId]
    };

    return this.dbManager.getAll(DatabaseManager.PHOTO_STORE_NAME, range, "accountId_userId");
  }

  public async update(locationsDataPhoto: LocationDataPhoto[]): Promise<LocationDataPhoto[]> {
    for (const item of locationsDataPhoto) {
      await this.dbManager.updateItem(DatabaseManager.PHOTO_STORE_NAME, item);
    }
    return locationsDataPhoto;
  }

  public add(locationDataPhoto: LocationDataPhoto): Promise<LocationDataPhoto> {
    return this.dbManager.addItem(DatabaseManager.PHOTO_STORE_NAME, locationDataPhoto);
  }

  public remove(accountId: number, userId: number, clientId: string): Promise<LocationDataPhoto[]> {
    const range = {
      only: [accountId, userId, clientId]
    };

    const callback = (evt: Event) => {
      const cursor = (<IDBRequest>evt.target).result;
      if (cursor) {
        const { value } = cursor;
        cursor.delete();
        cursor.continue();
      }
    };

    return this.dbManager.openCursorByRange(DatabaseManager.PHOTO_STORE_NAME, callback, range, "accountId_userId_clientId");
  }
}
