import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { ToastrService } from "ngx-toastr";
import { Observable, throwError, TimeoutError } from "rxjs";
import { timeout, catchError } from "rxjs/operators";

@Injectable()
export class TimeoutInterceptor implements HttpInterceptor {
  constructor(private toastr: ToastrService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const timeoutValue = Number(req.headers.get("timeout-govendas")) || environment.requestTimeout;
    return next
      .handle(req)
      .pipe(
        timeout(timeoutValue),
        catchError(response => {
          if (response instanceof TimeoutError) {
            this.toastr.error("Houve problemas na conexão com a internet. Tente novamente mais tarde", null, {timeOut: 7000});
            return throwError(response);
          }

          return throwError(response);
        })
      );
  }
}
