export class WorkingTime {
  public days: string[];
  public period_time: WorkingTimePeriod[];

  constructor(source?: WorkingTime) {
    if (source) {
      if (source.days != null) this.days = [...source.days];
      if (source.period_time != null) this.period_time = source.period_time.map(x => new WorkingTimePeriod(x));
    }
  }
}

export class WorkingTimePeriod {
  public start = "";
  public end = "";

  constructor(source?: WorkingTimePeriod) {
    if (source) {
      this.start = source.start;
      this.end = source.end;
    }
  }
}
