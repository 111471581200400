import { Injectable } from "@angular/core";
import { NotificationEvent, PushNotificationData } from "../models";

@Injectable()
export class PushNotificationsRepository {
  private _history: NotificationEvent[] = [];
  private _notificationsData: NotificationEvent[] = [];

  get history(): NotificationEvent[] {
    return this._history;
  }

  get notificationsData(): NotificationEvent[] {
    return this._notificationsData;
  }

  public saveHistory(data: NotificationEvent[]): void {
    this._history = data;
  }

  public addNotificationData(data: NotificationEvent): void {
    this._notificationsData.push(data);
  }

  public updateOneData(data: NotificationEvent): void {
    this._notificationsData.forEach((item, i) => {
      if (item.id === data.id) {
        this._notificationsData[i] = data;
      }
    });
  }
}
