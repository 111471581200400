import { UserAccount } from "app/main/shared/models";
import { RuptureParams } from "./rupture-params";
import { MetaParameters } from "./meta-paramters";
import { WorkingTime } from "./working-time";

export class AccountParams {
  public inactive_days: number;
  public activeFreq: number;
  public inactiveFreq: number;
  public skipDefaulting: string;

  public rupture_params: RuptureParams;
  public working_time: WorkingTime[];

  constructor(user: UserAccount) {
    this.inactive_days = user.inactive_days;
    const metaParams = user.meta_parameters;
    if (metaParams) {
      this.activeFreq = metaParams.activeFreq;
      this.inactiveFreq = metaParams.inactiveFreq;
      this.rupture_params = metaParams.rupture_params;
      this.working_time = metaParams.working_time;
      switch (metaParams.skipDefaulting) {
        case false:
          this.skipDefaulting = "1";
          break;
        case true:
          this.skipDefaulting = "2";
          break;
        default:
          this.skipDefaulting = "3";
      }
    } else {
      this.skipDefaulting = "3";
    }
  }

  public toMetaParameters(): MetaParameters {
    const metaParams = new MetaParameters();
    metaParams.activeFreq = this.activeFreq;
    metaParams.inactiveFreq = this.inactiveFreq;
    metaParams.rupture_params = this.rupture_params;
    metaParams.working_time = this.working_time;
    switch (this.skipDefaulting) {
      case "1":
        metaParams.skipDefaulting = false;
        break;
      case "2":
        metaParams.skipDefaulting = true;
        break;
      case "3":
        metaParams.skipDefaulting = null;
        break;
    }

    return metaParams;
  }
}
