
export enum FeedbackStatus {
  Positivized = 101,
  PositivizedByPhone = 103,
  BadPrice = 202,
  Budget = 204,
  NoDemand = 207,
  WithoutProduct = 206,
  Others = 205,
  FullStore = 210,
  BlockedClient = 211,
  WithoutInstallment = 212,
  WithoutCredit = 213,
  WaitingForOrder = 214,
  UnsuccessfullyContact = 90,
  ClosedCompany = 91,
  PhoneOutOfDate = 92,
  NotAvailable = 99,
  WithoutFeedback = 7,
  Open = 0,
  UnsuccessfullyContact_Negotiation = 80,
  UnsuccessfullyContact_Offers = 81,
}

export const contactedFeedbacks =
  [FeedbackStatus.NotAvailable, FeedbackStatus.UnsuccessfullyContact_Negotiation, FeedbackStatus.UnsuccessfullyContact_Offers, FeedbackStatus.PhoneOutOfDate, FeedbackStatus.UnsuccessfullyContact, FeedbackStatus.ClosedCompany];
