export class FeedbackError {
  public feedback_id: string;
  public rescheduled_date: string;
  public observations: string;
  public feedback_presential: string;

  public isEmpty(): boolean {
    return !this.feedback_id && !this.rescheduled_date && !this.observations && !this.feedback_presential;
  }
}
