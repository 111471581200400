import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

// components

import { AuthGuardService } from "./main/shared/services/auth-guard.service";
import { LogoutComponent } from "./main/logout/logout.component";
import { AccessDeniedGuardService } from "./main/shared/services";

const routes: Routes = [
  {
    path: "auth",
    loadChildren: () => import("./main/pages/login/login.module").then(m => m.LoginModule)
  },
  {
    path: "acompanhamento",
    loadChildren: () => import("./main/acompanhamento/acompanhamento.module").then(m => m.AcompanhamentoModule),
    canActivate: [AuthGuardService]
  },
  {
    path: "gestao-ruptura",
    loadChildren: () => import("./main/rupture-rules-management/rupture-rules-managment.module").then(m => m.GestaoRupturaModule),
    canActivate: [AuthGuardService]
  },
  {
    path: "atendimento",
    loadChildren: () => import("./main/atendimento/atendimento.module").then(m => m.AtendimentoModule),
    canActivate: [AuthGuardService]
  },
  {
    path: "parametrosatendimento",
    loadChildren: () => import("./main/parametros-atendimento/parametros-atendimento.module").then(m => m.ParametrosAtendimentoModule),
    canActivate: [AuthGuardService]
  },
  {
    path: "usuarios",
    loadChildren: () => import("./main/account/account.module").then(m => m.AccountModule),
    canActivate: [AuthGuardService]
  },
  {
    path: "relatorios",
    loadChildren: () => import("./main/reports/reports.module").then(m => m.ReportsModule),
    canActivate: [AuthGuardService]
  },
  {
    path: "planejamento",
    loadChildren: () => import("./main/sales-planning/sales-planning.module").then(m => m.SalesPlanningModule),
    canActivate: [AuthGuardService]
  },
  {
    path: "notificacao",
    loadChildren: () => import("./main/notification/notification.module").then(m => m.NotificationModule),
    canActivate: [AuthGuardService]
  },
  {
    path: "logout",
    component: LogoutComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: "browser-not-supported",
    loadChildren: () => import("./main/pages/browser-not-supported/browser-not-supported.module").then(m => m.BrowserNotSupportedModule)
  },
  {
    path: "access-denied",
    loadChildren: () => import("./main/pages/access-denied/access-denied.module").then(m => m.AccessDeniedModule),
    canActivate: [AccessDeniedGuardService]
  },
  {
    path: "**",
    redirectTo: "acompanhamento"
  }
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })]
})
export class AppRoutingModule { }
