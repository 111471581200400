import { ICountClients } from "./countPhotos";

export class StatisticsCustomerWallet implements ICountClients {
  public amount = 0;
  public client_with_photo: string[] = [];
  public client_without_photo: string[] = [];
  public clients_non_routable: string[] = [];
  public clients_non_schedulable: string[] = [];
  public clients_excluded: string[] = [];
  public clients_blocked: string[] = [];
  public clients_non_schedulable_non_routable: string[] = [];

  constructor(source?: ICountClients) {
    this.fromCountClients(source);
  }

  get excludedClients(): Set<string> {
    return new Set([...this.clients_non_routable, ...this.clients_non_schedulable, ...this.clients_excluded]);
  }

  get clients_count(): number {
    return this.amount;
  }

  get clientsWithCollectCount(): number {
    const isExcluded = {};
    this.excludedClients.forEach(x => (isExcluded[x] = true));
    return this.client_with_photo.filter(x => !isExcluded[x]).length;
  }

  get clientsWithoutCollectCount(): number {
    const isExcluded = {};
    this.excludedClients.forEach(x => (isExcluded[x] = true));
    return this.client_without_photo.filter(x => !isExcluded[x]).length;
  }

  get quota_with_collect(): string {
    if (this.clientsNeedPhoto === 0) {
      return "0";
    }

    const percent = (this.client_with_photo.length / this.amount) * 100;
    return percent.toFixed(2);
  }

  get quota_without_collect(): string {
    if (this.amount === 0) {
      return "0";
    }

    const percent = (this.client_without_photo.length / this.amount) * 100;
    return percent.toFixed(1);
  }

  get clientsNeedPhoto(): number {
    return this.amount - this.clients_non_schedulable_non_routable.length - this.clients_blocked.length;
  }

  get clientsDonNeedPhoto(): number {
    return this.clients_non_schedulable_non_routable.length + this.clients_blocked.length;
  }

  public fromCountClients(source: ICountClients): void {
    if (source) {
      this.amount = source.amount;
      this.client_with_photo = source.client_with_photo;
      this.client_without_photo = source.client_without_photo;
      this.clients_non_routable = source.clients_non_routable;
      this.clients_non_schedulable = source.clients_non_schedulable;
      this.clients_excluded = source.clients_excluded;
      this.clients_blocked = source.clients_blocked;
      this.clients_non_schedulable_non_routable = source.clients_non_schedulable_non_routable;
    }
  }

  public addCountClients(source: ICountClients): void {
    if (source) {
      if (source.amount) this.amount += source.amount;
      if (source.client_with_photo) this.client_with_photo.push(...source.client_with_photo);
      if (source.client_without_photo) this.client_without_photo.push(...source.client_without_photo);
      if (source.clients_non_routable) this.clients_non_routable.push(...source.clients_non_routable);
      if (source.clients_non_schedulable) this.clients_non_schedulable.push(...source.clients_non_schedulable);
      if (source.clients_excluded) this.clients_excluded.push(...source.clients_excluded);
      if (source.clients_blocked) this.clients_blocked.push(...source.clients_blocked);
      if (source.clients_non_schedulable_non_routable) this.clients_non_schedulable_non_routable.push(...source.clients_non_schedulable_non_routable);
    }
  }
}
