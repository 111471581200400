import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";

import { AuthService } from "./auth.service";

import { WorkingTimeService } from "./working-time.service";

@Injectable()
export class AccessDeniedGuardService implements CanActivate {
  constructor(public auth: AuthService, public router: Router, private workingTimeService: WorkingTimeService) {}
  public canActivate(routeSnapshot: ActivatedRouteSnapshot, stateSnapshot: RouterStateSnapshot): boolean {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user && user.role) {
      const haveAccess = this.workingTimeService.checkAccess(user);
      if (haveAccess) {
        this.router.navigateByUrl("/");
        return false;
      } else return true;
    } else {
      this.router.navigateByUrl("/auth/login");
      return false;
    }
  }
}
