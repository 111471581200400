import { appVersion } from "./version";

export const environmentCommon = {
  app_version: appVersion,
  BASE_URL: "https://govendas.gogeo.io", // Stagging -> https://govendas-staging.gogeo.io
  ACADEMY_URL: "https://govendas.teachable.com/",
  timeIntervalLocationRequests: 2 * 1000, // quantidade de milisegundos entre cada request de gps
  timeIntervalServiceLocationTest: 5 * 1000, // quantidade de milisegundos entre cada request
  speedSlow: 2000, // velocidade em kbps para considerar a rede slow ou fast
  slaRTT: 1400,
  remoteDebugId: "1248cb5c-aa06-d9c2-3e71-78fbdd33c25e-govendas-remote-debug",
  bad_accuracy_threshold: 50,
  good_accuracy_threshold: 30,
  mediaQuery: {
    mobile: "(max-width: 950px)",
    desktop: "(min-width: 950px)",
    indicatorsOpen: "(min-width: 1000px)",
    xs: "screen and (max-width: 599px)",
    sm: "screen and (min-width: 600px) and (max-width: 959px)",
    md: "screen and (min-width: 960px) and (max-width: 1279px)",
    lg: "screen and (min-width: 1280px) and (max-width: 1919px)",
    xl: "screen and (min-width: 1920px) and (max-width: 5000px)"
  },
  requestTimeout: 30000,
  requestsToSync: [
    {
      id: 1,
      type: "PUT",
      pattern: "agenda\\/[0-9-]+\\/[\\d]+\\/status"
    },
    {
      id: 2,
      type: "PUT",
      pattern: "clients\\/params\\/[\\d]+",
      bodyId: "client_id"
    },
    {
      id: 3,
      type: "POST",
      pattern: "agenda\\/[0-9-]+\\/client",
      bodyId: "client_id"
    }
  ],
  requestsToCache: [
    {
      id: 1,
      type: "POST",
      pattern: "clients"
    }
  ],
  requestIdHeader: "x-request-id"
};
