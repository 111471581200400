export enum ErrorCodeDefinitionsGoVendas {
  /*
   * Generico
   */
  INTERNAL_SERVER_ERROR = 1,
  ACCOUNT_NOT_FOUND_ERROR = 2,
  INVALID_ACCOUNT_PARAMS_ERROR = 3,
  ACCESS_DENIED = 4,
  INTERNAL_ERROR_ACCOUNT = 5,
  USER_NOT_FOUND_ERROR = 6,

  /*
   * Parâmetros
   */
  GENERIC_INVALID_PARAMS = 30,
  GENERIC_INVALID_CONTENT_TYPE = 31,
  INVALID_DATE_FORMAT = 32,
  INVALID_CLIENT_ID = 33,

  /*
   * Cliente
   */
  CLIENT_NOT_EXISTS = 40,

  /*
   * Agenda
   */
  AGENDA_INTERNAL_ERROR = 50,
  AGENDA_FEEDBACK_EMPTY = 51,
  AGENDA_VISIT_DATE_BEFORE_NOW = 52,
  ERROR_TO_SAVE_AGENDA = 53,
  AGENDA_NOT_EXISTS = 54,
  CLIENT_AGENDA_ALREADY_EXISTS = 55,
  AGENDA_FEEDBACK_DATE_EMPTY = 56,
  AGENDA_INVALID_PARAMS_ERROR = 57,

  /*
   * Setup
   */
  INVALID_SETUP_PARAMS = 70,
  SETUP_ALREADY_EXISTS = 71,

  /*
   * Metrics
   */
  METRICS_ALREADY_SENT = 80
}
