import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

interface WarnMessageOperation {
  op: "add" | "update" | "delete";
  id: string;
  value?: string;
}

@Injectable({
  providedIn: "root"
})
export class WarnMessageService {
  private notificationMsgOptions: string[];
  private notificationIds: string[];
  private count: number;
  private broker = new Subject<WarnMessageOperation>();

  get message(): string {
    return this.notificationMsgOptions.length !== 0 ? this.notificationMsgOptions[this.count % this.notificationMsgOptions.length] : "";
  }

  constructor() {
    this.notificationMsgOptions = [];
    this.notificationIds = [];
    this.count = 0;

    this.broker.subscribe(operation => {
      this.changeMessages(operation);
    });

    setInterval(() => {
      this.count++;
    }, 5000);
  }

  private changeMessages(operation: WarnMessageOperation): void {
    switch (operation.op) {
      case "add":
        this.notificationIds.push(operation.id);
        this.notificationMsgOptions.push(operation.value);
        this.count = 0;
        break;

      case "update":
        const uIndex = this.notificationIds.indexOf(operation.id);
        if (uIndex !== -1) this.notificationMsgOptions[uIndex] = operation.value;
        break;

      case "delete":
        const dIndex = this.notificationIds.indexOf(operation.id);
        if (dIndex !== -1) {
          this.notificationIds.splice(dIndex, 1);
          this.notificationMsgOptions.splice(dIndex, 1);
          this.count = 0;
        }
        break;
      default:
        break;
    }
  }

  public add(id: string, value: string): void {
    this.broker.next({
      op: "add",
      id,
      value
    });
  }

  public update(id: string, value: string): void {
    this.broker.next({
      op: "update",
      id,
      value
    });
  }

  public delete(id: string): void {
    this.broker.next({
      op: "delete",
      id
    });
  }

  public haveId(id: string): boolean {
    return this.notificationIds.indexOf(id) !== -1;
  }
}
