import { NetworkInformation } from "./networkInformation";

export class CustomNetworkInformation {
    public speedDownload?: number;
    public downlink?: number;
    public effectiveType?: string;
    public onchange?: any;
    public rtt?: number;
    public saveData?: boolean;
    public customEffectiveTypeBySpeed?: string;

    constructor(data?: CustomNetworkInformation) {
        if (data) {
            this.speedDownload = data.speedDownload;
            this.downlink = data.downlink;
            this.effectiveType = data.effectiveType;
            this.onchange = data.onchange;
            this.rtt = data.rtt;
            this.saveData = data.saveData;
            this.customEffectiveTypeBySpeed = data.customEffectiveTypeBySpeed;
        } else {
            this.speedDownload = -1;
            this.downlink =  -1;
            this.effectiveType =  "undefined";
            this.onchange =  null;
            this.rtt =  -1;
            this.saveData = false;
            this.customEffectiveTypeBySpeed = "undefined";
        }
    }
    
}