import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  HostListener,
  ChangeDetectorRef,
  AfterViewChecked,
  AfterViewInit,
  ElementRef,
  OnDestroy,
  ViewChildren,
  QueryList
} from "@angular/core";
import { SentryErrorHandler } from "../../services";
import { Utils } from "../../services/utils";

@Component({
  selector: "camera",
  templateUrl: "./camera.component.html",
  styleUrls: ["./camera.component.scss"]
})
export class CameraComponent implements OnInit, AfterViewChecked, AfterViewInit, OnDestroy {
  private readonly storageKey = "deviceIdPreference";
  // toggle webcam on/off
  public showWebcam = true;
  public webcamAvailable: "init" | "success" | "error" = "init";
  public errors: any[] = [];

  public dataUrl = "";
  public availableVideoInputs: MediaDeviceInfo[] = [];
  private mediaStream: MediaStream = null;
  public videoInitialized = false;

  @Output()
  public triggerSnapshotEmitter = new EventEmitter<string>();

  public width: number;
  public height: number;
  public messageError: string;
  public hasCameraDevice: boolean;

  @ViewChildren("video")
  public video: QueryList<ElementRef<HTMLVideoElement>>;

  @ViewChildren("canvas")
  public canvas: QueryList<ElementRef<HTMLCanvasElement>>;

  public get videoWidth(): number {
    const videoRatio = this.getVideoAspectRatio();
    return Math.min(this.width, this.height * videoRatio);
  }

  public get videoHeight(): number {
    const videoRatio = this.getVideoAspectRatio();
    return Math.min(this.height, this.width / videoRatio);
  }

  constructor(private cdRef: ChangeDetectorRef, private sentryService: SentryErrorHandler) {}

  public ngOnInit(): void {}

  public ngAfterViewInit(): void {
    this.detectAvailableDevices()
      .then(devices => {
        if (devices && devices.length > 0) {
          this.initWebcam(devices[0].deviceId);
          this.webcamAvailable = "success";
        } else {
          this.webcamAvailable = "error";
          this.hasCameraDevice = false;
          this.messageError = "Não existe camera neste dispositivo ou sem autorização para acessar a camera";
        }
      })
      .catch((err: string) => {
        this.handleInitError({ message: err });
        this.messageError = "Erro ao capturar a lista de dispositivos";
        this.sentryService.handleError(err);
        // fallback: still try to load webcam, even if device enumeration failed
        this.switchToVideoInput(null);
      });
  }

  public ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
    this.onResize();
  }

  public ngOnDestroy(): void {
    this.stopMediaTracks();
  }

  @HostListener("window:resize", ["$event"])
  public onResize(event?: Event): void {
    const win = !!event ? (event.target as Window) : window;
    this.width = Math.round(win.innerWidth);
    this.height = Math.round(win.innerHeight);
  }

  private getNativeVideoElement(): Promise<ElementRef<HTMLVideoElement>> {
    return new Promise(resolve => {
      if (this.video.length !== 0) {
        resolve(this.video.first);
      } else {
        const subscription = this.video.changes.subscribe(() => {
          resolve(this.video.first);
          subscription.unsubscribe();
        });
      }
    });
  }

  private getNativeCanvasElement(): Promise<ElementRef<HTMLCanvasElement>> {
    return new Promise(resolve => {
      if (this.canvas.length !== 0) {
        resolve(this.canvas.first);
      } else {
        const subscription = this.canvas.changes.subscribe(() => {
          resolve(this.canvas.first);
          subscription.unsubscribe();
        });
      }
    });
  }

  public handleInitError(error: { message: string; mediaStreamError?: any }): void {
    this.webcamAvailable = "error";
    this.errors.push(error);

    if (error.mediaStreamError && error.mediaStreamError.name === "NotFoundError") {
      this.hasCameraDevice = false;
    }

    if (error.mediaStreamError) {
      switch (error.mediaStreamError.name) {
        case "NotAllowedError":
          this.messageError = "Sem autorização para acessar a câmera";
          break;
        case "NotFoundError":
          this.messageError = "Não existe câmera neste dispositivo";
          break;
        case "Error":
          this.messageError = "Erro desconhecido ao acessar a câmera";
          break;
        default:
          this.messageError = error.mediaStreamError.name;
          break;
      }
    }
  }

  public capture(): void {
    Promise.all([this.getNativeVideoElement(), this.getNativeCanvasElement()]).then(resp => {
      const [videoElement, canvasElement] = resp;
      const nativeVideoElement = videoElement.nativeElement;
      const nativeCanvasElement = canvasElement.nativeElement;
      const context = nativeCanvasElement.getContext("2d");
      context.drawImage(nativeVideoElement, 0, 0, this.videoWidth, this.videoHeight);
      this.dataUrl = nativeCanvasElement.toDataURL("image/jpeg", 1);
      this.showWebcam = false;
      this.triggerSnapshotEmitter.emit(this.dataUrl);
    });
  }

  public rotateVideoInput(): void {
    if (this.availableVideoInputs && this.availableVideoInputs.length > 1) {
      this.availableVideoInputs.push(this.availableVideoInputs.shift());
      this.switchToVideoInput(this.availableVideoInputs[0].deviceId);
    }
  }

  private detectAvailableDevices(): Promise<MediaDeviceInfo[]> {
    return new Promise((resolve, reject) => {
      Utils.getAvailableVideoInputs()
        .then((devices: MediaDeviceInfo[]) => {
          const deviceIdPreference = localStorage.getItem(this.storageKey);
          const deviceFound = devices.find(x => x.deviceId === deviceIdPreference);
          this.availableVideoInputs = deviceFound ? [deviceFound].concat(devices.filter(x => x.deviceId !== deviceIdPreference)) : devices;
          resolve(this.availableVideoInputs);
        })
        .catch(err => {
          this.availableVideoInputs = [];
          this.sentryService.handleError(err);
          reject(err);
        });
    });
  }

  public switchToVideoInput(deviceId: string): void {
    this.videoInitialized = false;
    this.stopMediaTracks();
    this.initWebcam(deviceId).then(() => {
      localStorage.setItem(this.storageKey, deviceId);
    });
  }

  private stopMediaTracks(): void {
    if (this.mediaStream && this.mediaStream.getTracks) {
      // getTracks() returns all media tracks (video+audio)
      this.mediaStream.getTracks().forEach((track: MediaStreamTrack) => track.stop());
    }
  }

  private initWebcam(deviceId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        const options = deviceId ? { video: { deviceId } } : { video: true };
        navigator.mediaDevices
          .getUserMedia(options)
          .then(stream => {
            this.mediaStream = stream;
            return this.getNativeVideoElement();
          })
          .then(videoElement => {
            const { nativeElement } = videoElement;
            nativeElement.srcObject = this.mediaStream;
            nativeElement.play();
            this.videoInitialized = true;
            resolve();
          })
          .catch(err => {
            this.videoInitialized = true;
            this.handleInitError({ message: err.message, mediaStreamError: err });
            this.sentryService.handleError(err);
            reject(err);
          });
      } else {
        const message = "Cannot read UserMedia from MediaDevices.";
        this.handleInitError({ message });
        const err = new Error(message);
        this.sentryService.handleError(err);
        reject(err);
      }
    });
  }

  private getVideoAspectRatio(): number {
    // calculate ratio from video element dimensions if present
    const videoElement = this.video.first;
    if (!videoElement) return this.width / this.height;
    const { nativeElement } = videoElement;
    if (nativeElement.videoWidth && nativeElement.videoWidth > 0 && nativeElement.videoHeight && nativeElement.videoHeight > 0) {
      return nativeElement.videoWidth / nativeElement.videoHeight;
    }

    // nothing present - calculate ratio based on width/height params
    return this.width / this.height;
  }
}
