import { Injectable } from '@angular/core';
import { Role } from '../models/role';

@Injectable()
export class RoleService {
  constructor() {
  }

  public getRoleList( ): Role[] {
    const roles = [];
    for (const n in Role) {
        if (typeof Role[n] === 'number') roles.push(n);
    }
    return roles;
  }

  public getValue(key): any {
    return Role[key];
  }

  public getRoleListLessThen(role): Role[] {
    const roles = [];
    const valueRole = this.getValue(role);
    // tslint:disable-next-line:forin
    for (const n in Role) {
        if (typeof Role[n] === 'number' && this.getValue(n) >= valueRole ) {
            roles.push(n);
        } 
    }
    return roles;
  }
}