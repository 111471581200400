import { IAgenda, ScheduledMetadata } from "./agenda";
import { ClientParams, IClient } from "./client";
import { GeoJsonGeometry } from "./geo-json-point";
import { Utils } from "../services/utils";

export interface IFeedback {
  feedback_id: number;
  rescheduled_date: Date | string;
  observations: string;
  visit_point: GeoJsonGeometry;
  client_point: GeoJsonGeometry;
  feedback_date: Date;
  feedback_presential: boolean;
  client_id: string;
  gps_available: boolean;
  accuracy: number;
  gps_retries: number;
}

export class Feedback implements IFeedback {
  public feedback_id: number;
  public rescheduled_date: Date | string;
  public observations: string;
  public client_point: GeoJsonGeometry;
  public visit_point: GeoJsonGeometry;
  public feedback_date: Date;
  public feedback_presential: boolean;
  public client_id: string;
  public gps_available: boolean;
  public accuracy: number;
  public gps_retries: number;

  public params = new ClientParams();
  public client = "";
  public fantasy_name = "";
  public visit_date: Date = null;
  public schedule_type: number;
  public scheduled_metadata: ScheduledMetadata;
  public neighborhood = "";
  public thoroughfare = "";
  public block = "";
  public city = "";
  public state = "";
  public phone = "";
  public house_number = "";
  public dataInLocalStorage: boolean = null;

  constructor(source?: IFeedback) {
    this.fromFeedback(source);
  }

  private fromFeedback(source?: IFeedback): void {
    if (source) {
      this.client_id = source.client_id;
      this.feedback_id = source.feedback_id;
      this.rescheduled_date = source.rescheduled_date;
      this.observations = source.observations;
      this.visit_point = Utils.isValidPoint(source.visit_point) ? source.visit_point : null;
      this.feedback_presential = source.feedback_presential;
      this.gps_available = source.gps_available;
      this.accuracy = source.accuracy;
      this.gps_retries = source.gps_retries;
      this.client_point = source.client_point;
    }
  }

  public fromAgenda(agenda: IAgenda): void {
    this.fromFeedback(agenda);
    this.params.fromAgenda(agenda);

    if (agenda.client != null) this.client = agenda.client;
    if (agenda.visit_date != null) this.visit_date = agenda.visit_date;
    if (agenda.schedule_type != null) this.schedule_type = agenda.schedule_type;
    if (agenda.scheduled_metadata != null) this.scheduled_metadata = agenda.scheduled_metadata;
    if (agenda.neighborhood != null) this.neighborhood = agenda.neighborhood;
    if (agenda.thoroughfare != null) this.thoroughfare = agenda.thoroughfare;
    if (agenda.block != null) this.block = agenda.block;
    if (agenda.city != null) this.city = agenda.city;
    if (agenda.state != null) this.state = agenda.state;
    if (agenda.phone != null) this.phone = agenda.phone;
    if (agenda.house_number != null) this.house_number = agenda.house_number;
    if (agenda.fantasy_name != null) this.fantasy_name = agenda.fantasy_name;
  }

  public fromClient(client: IClient): void {
    if (client.client_id != null) this.client_id = client.client_id;
    if (client.client != null) this.client = client.client;
    if (client.fantasy_name != null) this.fantasy_name = client.fantasy_name;
    if (client.neighborhood != null) this.neighborhood = client.neighborhood;
    if (client.thoroughfare != null) this.thoroughfare = client.thoroughfare;
    if (client.block != null) this.block = client.block;
    if (client.city != null) this.city = client.city;
    if (client.state != null) this.state = client.state;
    if (client.phone != null) this.phone = client.phone;
    if (client.house_number != null) this.house_number = client.house_number;
    if (client.dataInLocalStorage != null) this.dataInLocalStorage = client.dataInLocalStorage;
  }

  public toFeedback(): IFeedback {
    return {
      feedback_id: this.feedback_id,
      rescheduled_date: this.rescheduled_date,
      observations: this.observations,
      visit_point: this.visit_point,
      feedback_date: this.feedback_date,
      feedback_presential: this.feedback_presential,
      client_id: this.client_id,
      gps_available: this.gps_available,
      accuracy: this.accuracy,
      gps_retries: this.gps_retries,
      client_point: this.client_point
    };
  }
}

export interface FeedbackMapping {
  [key: string]: string;
}

export interface FeedbackMappingGroup {
  name: string;
  values: string[][];
}
