import { MetricsService } from "./../../shared/services/metrics.service";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import * as moment from "moment";

import { AgendaService, AuthService } from "app/main/shared/services";
import { Agenda, MetricsData } from "app/main/shared/models";
import { SellersService, FeedbackMappingService, ParameterService, ClientService, VisibilityService } from "../../shared/services";
import { OfflineInput } from "../models";

@Injectable({
  providedIn: "root"
})
export class OfflineDataService {
  constructor(
    private agendaService: AgendaService,
    private sellersService: SellersService,
    private feedbackMappingService: FeedbackMappingService,
    private paramsService: ParameterService,
    private clientService: ClientService,
    private metricsService: MetricsService,
    private auth: AuthService
  ) {}

  public load(input: OfflineInput): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      let startTime = moment(new Date());
      let metricData = new MetricsData();

      if (navigator.serviceWorker && navigator.serviceWorker.controller) {
        console.log("Service Worker Found!!!");

        navigator.serviceWorker.addEventListener("message", event => {
          if (event.data.msg === "precache_finished") {
            metricData.loadingName = "offlineAssets";
            metricData.processingTime = moment.duration(moment(new Date()).diff(startTime)).asMilliseconds();
            metricData.isSucess = true;
            this.metricsService.finishLoading(metricData);

            startTime = moment(new Date());
            metricData = new MetricsData();
            this.loadServicesData(input).subscribe(
              () => {
                metricData.isSucess = true;
                resolve();
              },
              err => {
                metricData.isSucess = false;
                reject(err);
              },
              () => {
                metricData.loadingName = "offlineData";
                metricData.totalTime = moment.duration(moment(new Date()).diff(startTime)).asMilliseconds();
                this.metricsService.finishLoading(metricData);
              }
            );
          }

          if (event.data.msg === "precache_error") {
            metricData.loadingName = "offlineAssets";
            metricData.sendTime = startTime.valueOf();
            metricData.processingTime = moment.duration(moment(new Date()).diff(startTime)).asMilliseconds();
            metricData.isSucess = false;
            this.metricsService.finishLoading(metricData);

            reject(event.data.data);
          }

          if (event.data.msg === "backend_data_removed") {
            navigator.serviceWorker.controller.postMessage({ msg: "precache" });
          }
        });

        navigator.serviceWorker.controller.postMessage({ msg: "remove_backend_data" });
      } else {
        console.log("Service Worker Not Found!!!");
        this.loadServicesData(input).subscribe(
          () => {
            resolve();
          },
          err => {
            reject(err);
          }
        );
      }
    });
  }

  public abortLoad(): Promise<any> {
    return new Promise<void>(resolve => {
      if (navigator.serviceWorker && navigator.serviceWorker.controller) {
        navigator.serviceWorker.addEventListener("message", event => {
          if (event.data.msg === "precache_abort_finished") {
            resolve();
          }
        });

        navigator.serviceWorker.controller.postMessage({ msg: "precache_abort" });
      } else {
        resolve();
      }
    });
  }

  private loadServicesData(input: OfflineInput): Observable<any> {
    return new Observable(subscriber => {
      this.agendaService.listar(input.date.toDate()).subscribe(
        response => {
          const agendaList: Agenda[] = response.data;
          const observables = [];
          const clientIdList = agendaList.map(x => x.client_id);
          const { id } = input.user;
          clientIdList.forEach(clientId => {
            observables.push(this.sellersService.getFollowUpAndSalesHistory(input.date.format("YYYY-MM-DD"), clientId));
          });

          observables.push(this.feedbackMappingService.getAll());

          observables.push(this.feedbackMappingService.getAll(false));

          observables.push(this.paramsService.getByID(id));

          observables.push(this.clientService.getAll(true));

          observables.push(this.auth.getUser(true));

          observables.push(this.auth.getAccount(true));

          Promise.all(observables.map(x => x.toPromise()))
            .then(() => {
              subscriber.next(true);
              subscriber.complete();
            })
            .catch(err => {
              subscriber.error(err);
              subscriber.complete();
            });
        },
        err => {
          subscriber.error(err);
          subscriber.complete();
        }
      );
    });
  }
}
