import { Injectable } from "@angular/core";
import { DatabaseManager } from "./database-manager";
import { Client } from "app/main/shared/models";
import { CacheData } from "../models";

@Injectable()
export class CacheService<T> {
  constructor(private dataManager: DatabaseManager) {}

  public add(url: string, response: T): Promise<CacheData<Client>> {
    const data = new CacheData(url, response);
    return this.dataManager.addItem(DatabaseManager.CACHE_STORE_NAME, data);
  }

  public save(url: string, response: T): Promise<CacheData<Client>> {
    const range = {
      only: url
    };

    return this.dataManager.getAll(DatabaseManager.CACHE_STORE_NAME, range, "url").then(items => {
      if (items && items.length > 0) {
        const item: CacheData<T> = items[0];
        item.response = response;
        return this.dataManager.updateItem(DatabaseManager.CACHE_STORE_NAME, item);
      } else {
        const data = new CacheData(url, response);
        return this.dataManager.addItem(DatabaseManager.CACHE_STORE_NAME, data);
      }
    });
  }

  public getByUrl(url: string): Promise<CacheData<Client[]>> {
    const range = {
      only: url
    };

    return this.dataManager.getAll(DatabaseManager.CACHE_STORE_NAME, range, "url").then((items: CacheData<Client[]>[]) => {
      if (items.length > 0) {
        return items[0];
      } else return null;
    });
  }

  public canCache(): boolean {
    return this.dataManager.indexedDBExists;
  }
}
