export enum GovendasErrorCode {
  Unknown = 1,
  ResourceUnavailable = 2
}

export class GovendasError extends Error {
  public code: GovendasErrorCode;

  constructor(code: GovendasErrorCode, message: string) {
    super(message);

    this.code = code;
  }
}

export enum HttpResponseStatusText {
  NetworkUnavailable = "Network Unavailable",
  SyncUnavailable = "Sync Unavailable"
}
