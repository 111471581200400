<div style="background: white; padding: 20px; text-align: center; height: 100vh; font-size: 12px" *ngIf="webcamAvailable === 'error'">
  <h4 style="background: orange; color: white; padding: 20px">{{ messageError }}</h4>

  <div>
    <h3>É preciso autorizar a camera para conseguirmos planejar a rota de visitas.</h3>

    <h4>Como ativar?</h4>
    <ul style="text-align: left; font-size: 12px">
      <li>No smartphone ou tablet, abra o app do Chrome Chrome.</li>
      <li>Acesse o goVendas https://app.govendas.com</li>
      <li>À direita da barra de endereço, toque em
        <span class="mdi mdi-dots-vertical" style="margin-left: 5px"> </span>
      </li>
      <li>Toque em Configurações do site.</li>
      <li>Para fazer uma alteração, em "Permissões", toque em uma configuração</li>
      <li>Autorize CAMERA e LOCAL</li>
    </ul>
  </div>
</div>

<div (window:resize)="onResize($event)" *ngIf="!dataUrl && webcamAvailable === 'success'">
  <button
    mat-stroked-button
    color="accent"
    class="mr-16 actionButtonCapturePhoto"
    (click)="capture()"
    *ngIf="showWebcam && webcamAvailable == 'success'"
  >
    <div style="margin: -10px">Tirar foto</div>
  </button>
  <div *ngIf="showWebcam" class="camera-container">
    <button mat-fab *ngIf="availableVideoInputs.length > 1 && videoInitialized" class="switch-camera" (click)="rotateVideoInput()">
      <mat-icon svgIcon="camera-flip" class="switch-camera-icon" [inline]="true"></mat-icon>
    </button>
    <video #video id="video" [width]="videoWidth" [height]="videoHeight" autoplay muted playsinline style="height: auto"></video>
    <canvas #canvas id="canvas" [width]="videoWidth" [height]="videoHeight" style="display: none"></canvas>
  </div>
</div>

<div *ngIf="dataUrl && webcamAvailable === 'success'">
  <img [src]="dataUrl" [width]="videoWidth" [height]="videoHeight" />
</div>
