import { FeedbackMappingService } from "app/main/shared/services";
import { OfflineDataService } from "./main/atendimento/services/offline-data.service";
import { OfflineInput } from "./main/atendimento/models/offline";
import { ErrorCodeDefinitionsGoVendas } from "./main/shared/models/service-error-codes";
import { HttpErrorResponse } from "@angular/common/http";
import {
  navigationSupervisor,
  navigationAdmin,
  navigationSupervisorMobile,
  navigationManagerMobile,
  navigationSellerMobile,
  navigationAdminMobile,
  navigationMobile
} from "./navigation/navigation";
import { Component, Inject, OnDestroy, OnInit, ChangeDetectorRef, ViewChild, AfterViewInit, HostListener, AfterViewChecked } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Platform } from "@angular/cdk/platform";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { FuseConfigService } from "@fuse/services/config.service";

import { navigation, navigationManager, navigationSeller } from "app/navigation/navigation";
import { AuthService } from "./main/shared/services/auth.service";
import { Router } from "@angular/router";
import { EventEmitterService } from "./main/shared/services/event.service";
import { access } from "app/navigation/navigation";
import { User, UserAccount, MetricsData } from "./main/shared/models";
import * as moment from "moment";
import { TrackingService } from "./main/shared/services/tracking.service";
import { SentryErrorHandler, OnlineService, StoragesManagementService, MetricsService } from "./main/shared/services";
import { MediaMatcher } from "@angular/cdk/layout";
import { environment } from "environments/environment";
import { ModalConfirmOfflineToogleComponent } from "./main/shared/components/modal-confirm-offline-toogle/modal-confirm-offline-toogle.component";
import { MatDialog } from "@angular/material/dialog";
import { MatSidenav } from "@angular/material/sidenav";
import { GlobalConfig, ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { UserAgenteService } from "./main/shared/services/user-agent.service";
import { WorkingTimeService } from "./main/shared/services/working-time.service";
import { WarnMessageService } from "./main/shared/services/warn-messages.service";
import { OneSignalService } from "./main/shared/services/onesignal.service";
import { UpdateService } from "./main/shared/services/update.service";

@Component({
  selector: "app",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
  public fuseConfig: any;
  public navigation: any;

  // Private
  private _unsubscribeAll: Subject<any>;
  public currentUser: User;
  public onSideNavChange: boolean;

  public mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  public online: boolean;
  private subscriptions: Subscription[];

  public logged = false;
  public showMenuLarge = true;
  @ViewChild("sidenav") public sidenav: MatSidenav;

  public app_version = environment.app_version;
  public availbleStorage: boolean;
  public percentUsed: number;
  public usage: string;
  public quota: string;
  public account: UserAccount;

  public duration: moment.Moment;
  public interval: NodeJS.Timeout;
  public loadingTimeout: boolean;

  public isCtrlPressed: boolean;

  public options: GlobalConfig;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private _fuseConfigService: FuseConfigService,
    private auth: AuthService,
    private _platform: Platform,
    private router: Router,
    private sentryService: SentryErrorHandler,
    private trackingService: TrackingService,
    public changeDetectorRef: ChangeDetectorRef,
    public media: MediaMatcher,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private onlineService: OnlineService,
    private storagesManagement: StoragesManagementService,
    private spinner: NgxSpinnerService,
    private cdRef: ChangeDetectorRef,
    private metricsService: MetricsService,
    private offlineDataService: OfflineDataService,
    private feedbackMappingService: FeedbackMappingService,
    private userAgenteService: UserAgenteService,
    private workingtimeService: WorkingTimeService,
    public warnMessagesService: WarnMessageService,
    private oneSignalService: OneSignalService,
    private updateService: UpdateService,
  ) {
    this.options = this.toastr.toastrConfig;

    this.currentUser = new User();
    this.mobileQuery = media.matchMedia(environment.mediaQuery.mobile);

    ///////////////////////////////

    this.updateService.checkForUpdates();

    ///////////////////////////////

    window.addEventListener("keydown", e => {
      if (e.key === "Control" && !this.isCtrlPressed) {
        this.isCtrlPressed = true;
      }
    });

    window.addEventListener("keyup", e => {
      if (e.key === "Control") {
        this.isCtrlPressed = false;
      }
    });

    this._mobileQueryListener = () => {
      changeDetectorRef.detectChanges();
      this.buildMenu();
    };

    EventEmitterService.get(EventEmitterService.LOGOUT_EVENT).subscribe(data => {
      if (!this.onlineService.onlineSnapshot) {
        this.toastr.info("Você está offline, não pode fazer logout.");
      } else {
        this.logged = false;
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        this.workingtimeService.removeWorkDays();
        this.router.navigateByUrl("/auth/login");
        this.oneSignalService.unsubscribe().then(() => {
          this.oneSignalService.removeExternalUserId();
          location.reload();
        });
      }
    });

    EventEmitterService.get(EventEmitterService.REFRESH_EVENT).subscribe(data => {
      if (!this.onlineService.onlineSnapshot) {
        this.toastr.info("Você está offline, não pode recarregar a aplicação.");
      } else {
        location.reload();
      }
    });

    EventEmitterService.get(EventEmitterService.LOGIN_EVENT).subscribe(data => {
      this.initApp(true);
    });

    EventEmitterService.get(EventEmitterService.ACADEMY_GOVENDAS).subscribe(data => {
      this.trackingService.track("academygoVendas");
      window.open(environment.ACADEMY_URL, "_blank");
    });

    EventEmitterService.get(EventEmitterService.OPEN_MODAL_OFFLINE_TOGGLE_EVENT).subscribe(data => {
      const dialogRef = this.dialog.open(ModalConfirmOfflineToogleComponent, {
        maxWidth: "100vw",
        // minHeight: "auto",
        // minWidth: "40%",
        // maxWidth: "100vw",
        // maxHeight: "100vh",
        data: data
      });

      dialogRef.afterClosed().subscribe((isOffline: any) => {
        if (isOffline != null) {
          if (isOffline) {
            const offlineData: OfflineInput = {
              date: moment(new Date()),
              user: this.currentUser
            };
            this.showLoading("loadingDependencies");
            this.offlineDataService
              .load(offlineData)
              .then(() => {
                this.hideLoading("loadingDependencies");
                location.reload();
              })
              .catch(error => {
                EventEmitterService.get(EventEmitterService.CHANGE_WORK_MODE_EVENT).emit(true);
                this.hideLoading("loadingDependencies");
                this.toastr.error("Erro ao carregar dados. Tente novamente mais tarde!");
                this.sentryService.handleError(error);
              });
          } else {
            location.reload();
          }
        }
      });
    });

    EventEmitterService.get(EventEmitterService.UPDATE_OFFLINE_ICON).emit(this.onlineService.wantWorkOnline);

    // Add is-mobile class to the body if the platform is mobile
    if (this._platform.ANDROID || this._platform.IOS) {
      this.document.body.classList.add("is-mobile");
    }

    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this.subscriptions = [];

    moment.locale("pt-br");

    this.storagesManagement.storageInfoWatch.subscribe(() => {
      this.storagesManagement.updateStorageInfo().then(() => {
        const { percentUsed } = this.storagesManagement;
        if (percentUsed >= 90) {
          const msg = `Você atingiu ${percentUsed}% do seu espaço de armazenamento`;
          if (!this.warnMessagesService.haveId("storage")) this.warnMessagesService.add("storage", msg);
        } else {
          if (this.warnMessagesService.haveId("storage")) this.warnMessagesService.delete("storage");
        }
      });
    });

    this.workingtimeService.finishTime.subscribe(() => {
      this.router.navigateByUrl("/access-denied");
    });

    if (this.onlineService.onlineSnapshot)
      this.sendLoggedUsersMetric();
  }

  @HostListener("window:resize", ["$event"])
  public onResize(event): void {
    this.setSidenav();
  }

  private setUserData(user: User): void {
    localStorage.setItem("user", JSON.stringify(user));
    this.currentUser = user;
  }

  public ngAfterViewInit(): void {
    if (this.auth.getToken()) {
      this.auth.getUser().subscribe(user => {
        this.logged = true;
        this.setSidenav();
      });
    }
  }

  private checkBrowserResources(): void {
    if (!navigator.serviceWorker) this.trackingService.track("service worker indisponível");
    if (!window.indexedDB) this.trackingService.track("banco de dados indisponível");
    if (!window.caches) this.trackingService.track("cache indisponível");
    if (!navigator.geolocation) this.trackingService.track("geolocalização indisponível");
    if (!navigator.storage) this.trackingService.track("armazenamento indisponível");

    if (this._platform.ANDROID || this._platform.IOS) {
      if (!navigator.mediaDevices) this.trackingService.track("câmera indisponível");
    }
  }

  private enableRemoteDebug(): void {
    const s = document.createElement("script");
    s.src = "https://remotejs.com/agent/agent.js";
    s.setAttribute("data-consolejs-channel", environment.remoteDebugId);
    document.head.appendChild(s);
  }

  private initApp(fromLogin: boolean = false): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.auth.getToken()) {
        Promise.all([this.auth.getAccount().toPromise(), this.auth.getUser().toPromise(), this.feedbackMappingService.init()])
          .then(resp => {
            const [account, user] = resp;
            this.setUserData(user);
            this.account = account;
            const { list_permission } = user;
            if (list_permission && list_permission.indexOf("remote_debug") !== -1) this.enableRemoteDebug();
            if (user.notificationsEnabled()) this.oneSignalService.initOneSignal();
            this.buildMenu();
            this.sentryService.setUserInfo(user, account);
            this.workingtimeService.mergeWorkingTime(account);
            if (fromLogin) {
              this.logged = true;
              this.setSidenav();
              this.redirectOnLogin();
            }

            this.trackingService.init(account, this.currentUser);
            resolve();
          })
          .catch(error => {
            toastr.error("Erro ao inicializar aplicação!");
            this.sentryService.handleError(error);
          });
      } else {
        reject();
      }
    });
  }

  public buildMenu(): void {
    const user = this.currentUser;

    if (user && user.role) {
      switch (user.role) {
        case "SUPERVISOR":
          this.navigation = this.userAgenteService.isMobile ? navigationSupervisorMobile : navigationSupervisor;
          break;
        case "MANAGER":
          // Get navigation form Manager
          this.navigation = this.userAgenteService.isMobile ? navigationManagerMobile : navigationManager;
          break;

        case "SALESMAN":
          // Get navigation for Seller
          this.navigation = this.userAgenteService.isMobile ? navigationSellerMobile : navigationSeller;
          break;

        case "ADMIN":
          // Get navigation for Admin
          this.navigation = this.userAgenteService.isMobile ? navigationAdminMobile : navigationAdmin;
          break;

        default:
          // Get default navigation
          this.navigation = this.userAgenteService.isMobile ? navigationMobile : navigation;
          break;
      }
    }

    this.navigation.map(menuGroup => {
      const that = user;
      if (that.role === "SALESMAN" && !that.allow_change_params) {
        menuGroup.children = menuGroup.children.filter(x => x.id !== "parametrosatendimento");
      }

      if (!that.can_update_rupture_rule) {
        menuGroup.children = menuGroup.children.filter(x => x.id !== "gestao-ruptura");
      }

      if (that.salesman_type === "INTERNAL") {
        menuGroup.children = menuGroup.children.filter(x => x.id !== "changeWorkMode");
      }

      if (that.role !== "SALESMAN") {
        menuGroup.children = menuGroup.children.filter(x => x.id !== "changeWorkMode");
      }
    });
  }

  private checkSidenav(): boolean {
    if (this.mobileQuery.matches && this.showMenuLarge && !this.sidenav.opened) return true;
    else if (!this.mobileQuery.matches && this.sidenav.opened) return true;
    else return false;
  }

  private setSidenav(): void {
    if (this.mobileQuery.matches) {
      if (this.sidenav) this.sidenav.close();
      this.showMenuLarge = true;
    }
    if (!this.mobileQuery.matches) {
      if (this.sidenav) this.sidenav.open();
      this.showMenuLarge = false;
    }
  }

  public redirectOnLogin(): void {
    switch (this.currentUser.role) {
      case "SUPERVISOR":
        this.router.navigateByUrl(access.SUPERVISOR[0]);
        break;
      case "MANAGER":
        // Get navigation form Manager
        this.router.navigateByUrl(access.MANAGER[0]);
        break;
      case "SALESMAN":
        // Get navigation for Seller
        this.router.navigateByUrl(access.SALESMAN[0]);
        break;
      case "ADMIN":
        // Get navigation for Admin
        this.router.navigateByUrl(access.ADMIN[0]);
        break;
      default:
        // Get default navigation
        break;
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  public ngOnInit(): void {
    this.getStorage();
    EventEmitterService.get(EventEmitterService.TOGGLE_MAIN_MENU_LISTENER_EVENT).subscribe(data => {
      if (!this.checkSidenav()) this.setSidenav();
      this.toggleSideNav();
    });

    const clearBackendCache = new Promise((resolve: any) => {
      if (navigator.serviceWorker && navigator.serviceWorker.controller) {
        const refreshDateKey = "refreshDate";
        const refreshDateStr = localStorage.getItem(refreshDateKey);
        const now = moment();
        localStorage.setItem(refreshDateKey, JSON.stringify(now));

        if (refreshDateStr && typeof refreshDateStr === "string") {
          const refreshDate = moment(refreshDateStr, "YYYY-MM-DD");
          if (now.isAfter(refreshDate, "day")) {
            navigator.serviceWorker.addEventListener("message", event => {
              if (event.data.msg === "backend_data_removed") {
                resolve();
              }
            });
            navigator.serviceWorker.controller.postMessage({ msg: "remove_backend_data" });
          } else resolve();
        } else resolve();
      } else resolve();
    });

    clearBackendCache
      .then(() => this.initApp())
      .then(() => this.checkBrowserResources())
      .then(() => !this.mobileQuery.matches && this.sidenav.open());

    // Subscribe to config changes
    this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.fuseConfig = config;

      // Boxed
      if (this.fuseConfig.layout.width === "boxed") {
        this.document.body.classList.add("boxed");
      } else {
        this.document.body.classList.remove("boxed");
      }

      // Color theme - Use normal for loop for IE11 compatibility
      for (let i = 0; i < this.document.body.classList.length; i++) {
        const className = this.document.body.classList[i];

        if (className.startsWith("theme-")) {
          this.document.body.classList.remove(className);
        }
      }

      this.document.body.classList.add(this.fuseConfig.colorTheme);
    });

    this.onlineService.getOnline().subscribe(online => {
      this.online = online;
    });
  }

  public toggleSideNav(): void {
    if (this.mobileQuery.matches) {
      this.sidenav.toggle();
    } else {
      this.showMenuLarge = !this.showMenuLarge;
    }
  }

  /**
   * On destroy
   */
  public ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', this._mobileQueryListener);

    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();

    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  public close(reason: string): void {
    this.sidenav.close();
  }

  public getStorage(): void {
    if (this.storagesManagement.storageExists) {
      this.storagesManagement
        .updateStorageInfo()
        .then(() => {
          this.availbleStorage = this.storagesManagement.availbleStorage;
          this.percentUsed = this.storagesManagement.percentUsed;
          this.usage = this.storagesManagement.getUsageFormated();
          this.quota = this.storagesManagement.getTotalSpaceFormated();
        })
        .catch(error => {
          this.toastr.error("Erro ao acessar armazenamento!");
          this.sentryService.handleError(error);
        });
    }
  }

  public clearStorage(): void {
    const previus = moment(new Date());
    this.spinner.show("loadingStorage");
    const data = new MetricsData();
    this.storagesManagement
      .clearStorage()
      .then(() => {
        data.isSucess = true;
        this.toastr.success("Armazenamento local limpo");
        this.spinner.hide("loadingStorage");
        location.reload();
      })
      .catch((error: Error) => {
        this.toastr.error(error.message);
        data.isSucess = false;
        this.spinner.hide("loadingStorage");
      })
      .finally(() => {
        data.loadingName = "loadingStorage";
        data.sendTime = previus.valueOf();
        data.totalTime = moment.duration(moment(new Date()).diff(previus)).asMilliseconds();
        this.metricsService.finishLoading(data);
      });
  }

  public ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  private sendLoggedUsersMetric(): void {
    const valueStr = localStorage.getItem("loggedUserMetricSent");
    const metricSent = valueStr ? new Date(valueStr) : new Date();
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (today.getTime() < metricSent.getTime()) {
      this.metricsService.sendLoggedUsers().subscribe(
        () => {
          localStorage.setItem("loggedUserMetricSent", `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}T00:00:00`);
        },
        error => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 400 && ErrorCodeDefinitionsGoVendas.METRICS_ALREADY_SENT.valueOf() === error.error.code) {
              localStorage.setItem("loggedUserMetricSent", `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}T00:00:00`);
              return;
            }
            return;
          }
        }
      );
    }
  }

  public startTimer(): void {
    this.duration = moment().set({ hour: 0, minute: 5, second: 0, millisecond: 0 });
    this.interval = setInterval(() => {
      this.duration.subtract(1, "second");
      const minutes = this.duration.minutes();
      const seconds = this.duration.seconds();
      if (minutes === 0 && seconds === 0) {
        this.loadingTimeout = true;
        clearInterval(this.interval);
      }
    }, 1000);
  }

  public pauseTimer(): void {
    clearInterval(this.interval);
  }

  private showLoading(name: string): void {
    this.startTimer();
    this.spinner.show(name);
  }

  private hideLoading(name: string): void {
    this.pauseTimer();
    this.spinner.hide(name);
  }

  public abortOfflineLoad(): void {
    EventEmitterService.get(EventEmitterService.CHANGE_WORK_MODE_EVENT).emit(true);
    this.offlineDataService
      .abortLoad()
      .then(() => {
        location.reload();
      })
      .catch((error: Error) => {
        this.toastr.error(error.message, "Erro no carregamento!");
        this.sentryService.handleError(error);
      });
  }

  public activate(itemMenu: any): void {
    this.trackingService.track(itemMenu.id);
    this.router.navigateByUrl(itemMenu.url);
  }

  public openLinkInAnotherTab(url: string): void {
    window.open(url, "_blank");
  }
}
