export interface RuleFieldProps {
  relationships: RuptureRule[];
}
export interface RuptureRule {
  id?: string;
  name?: string;
  producers: string[];
  consumers: string[];
  rules: RulesOptions;
}
export interface RulesOptions {
  sales_rupture_lower_bound?: number;
  sales_rupture_upper_bound?: number;
  visit_rupture_lower_bound?: number;
  visit_rupture_upper_bound?: number;
  generic_filter?: string;
}

export interface Rule {
  id?: number;
  account_id?: number;
  enabled?: boolean;
  rule_name: string;
  type: number;
  inserted_at?: string;
  updated_at?: string;
  ruptureRule: RuleFieldProps;
}

export class RuleForService {
  public account_id: number;
  public enabled: boolean;
  public rule_name: string;
  public default_rule: boolean;
  public type: number;
  public inserted_at?: string;
  public updated_at?: string;
  public rule: string;

  constructor(source: Rule) {
    this.enabled = source.enabled;
    this.type = source.type;
    this.rule_name = source.rule_name;
    this.default_rule = false;
    this.rule = JSON.stringify(source.ruptureRule);
  }
}

