import { User } from "app/main/shared/models";
import { RuptureParams } from "./rupture-params";
import { MetaParameters } from "./meta-paramters";
import { WorkingTime } from "./working-time";
import { v4 as uuid } from 'uuid';

export class ISellerInfo {
  public id?: string;
  public salesman_id: string;
  public name?: string;
  public email?: string;
  public phone?: string;
  public isSelected?: boolean;
  public active?: boolean;
  public invisible?: boolean;

  constructor(salesman_id: string) {
    this.id = uuid();
    this.salesman_id = salesman_id;
    this.name = "Não Cadastrado";
    this.email = "-----";
    this.phone = "-----"
    this.isSelected = true;
  }
}

export interface ISellerParams {
  salesman_type: "EXTERNAL" | "INTERNAL";
  skip_scheduling: boolean;
  ignore_blocked: boolean;
  inactive_days: number;
  max_visit: number;
  salesman_id: string;
  notification_enabled: boolean;
  lat_start: number;
  lon_start: number;
  lat_end: number;
  lon_end: number;
  access_all_clients: boolean;
  allow_change_params: boolean;
  allow_incremental_observations: boolean;
  activeFreq: number;
  inactiveFreq: number;
  skipDefaulting: string;
  rupture_params: RuptureParams;
  working_time: WorkingTime[];
}
export class SellerParams implements ISellerParams {
  public salesman_type: "EXTERNAL" | "INTERNAL" = null;
  public skip_scheduling: boolean = null;
  public ignore_blocked: boolean = null;
  public inactive_days: number = null;
  public max_visit = 0;
  public salesman_id = "";
  public notification_enabled: boolean = null;
  public lat_start: number = null;
  public lon_start: number = null;
  public lat_end: number = null;
  public lon_end: number = null;
  public access_all_clients: boolean = null;
  public allow_change_params: boolean = null;
  public allow_incremental_observations: boolean = null;
  public activeFreq: number = null;
  public inactiveFreq: number = null;
  public skipDefaulting = "";
  public rupture_params = new RuptureParams();
  public working_time: WorkingTime[] = [];

  constructor(user?: User) {
    if (user) {
      if (user.salesman_type != null) this.salesman_type = user.salesman_type;
      if (user.skip_scheduling != null) this.skip_scheduling = user.skip_scheduling;
      if (user.ignore_blocked != null) this.ignore_blocked = user.ignore_blocked;
      if (user.inactive_days != null) this.inactive_days = user.inactive_days;
      if (user.max_visit != null) this.max_visit = user.max_visit;
      if (user.salesman_id != null) this.salesman_id = user.salesman_id;
      if (user.notification_enabled != null) this.notification_enabled = user.notification_enabled;
      if (user.lat_start != null) this.lat_start = user.lat_start;
      if (user.lon_start != null) this.lon_start = user.lon_start;
      if (user.lat_end != null) this.lat_end = user.lat_end;
      if (user.lon_end != null) this.lon_end = user.lon_end;
      if (user.access_all_clients != null) this.access_all_clients = user.access_all_clients;
      if (user.allow_change_params != null) this.allow_change_params = user.allow_change_params;
      if (user.allow_incremental_observations != null) this.allow_incremental_observations = user.allow_incremental_observations;

      if (user.meta_parameters != null) {
        const metaParams = user.meta_parameters;
        if (metaParams.activeFreq != null) this.activeFreq = metaParams.activeFreq;
        if (metaParams.inactiveFreq != null) this.inactiveFreq = metaParams.inactiveFreq;
        if (metaParams.rupture_params != null) this.rupture_params = metaParams.rupture_params;
        if (metaParams.working_time != null) this.working_time = metaParams.working_time;

        switch (metaParams.skipDefaulting) {
          case true:
            this.skipDefaulting = "2";
            break;
          case false:
            this.skipDefaulting = "1";
            break;
          default:
            this.skipDefaulting = "3";
        }
      } else {
        this.skipDefaulting = "3";
      }
    }
  }

  public toMetaParameters(): MetaParameters {
    const metaParams = new MetaParameters();
    metaParams.activeFreq = this.activeFreq;
    metaParams.inactiveFreq = this.inactiveFreq;
    metaParams.rupture_params = this.rupture_params;
    metaParams.working_time = this.working_time;
    switch (this.skipDefaulting) {
      case "1":
        metaParams.skipDefaulting = false;
        break;
      case "2":
        metaParams.skipDefaulting = true;
        break;
      case "3":
        metaParams.skipDefaulting = null;
        break;
    }

    return metaParams;
  }

  public toUser(): User {
    const user = new User();
    user.salesman_type = this.salesman_type;
    user.skip_scheduling = this.skip_scheduling || false;
    user.ignore_blocked = this.ignore_blocked || false;
    user.inactive_days = this.inactive_days;
    user.max_visit = this.max_visit;
    user.salesman_id = this.salesman_id;
    user.notification_enabled = this.notification_enabled || false;
    user.lat_start = this.lat_start;
    user.lon_start = this.lon_start;
    user.lat_end = this.lat_end;
    user.lon_end = this.lon_end;
    user.access_all_clients = this.access_all_clients;
    user.allow_change_params = this.allow_change_params;
    user.allow_incremental_observations = this.allow_incremental_observations;

    user.meta_parameters = this.toMetaParameters();

    return user;
  }
}
