<modal-component [modalContentClass]="'confirm-offline-content'">
  <span title>Confirmação</span>
  <div close-button>
    <button mat-icon-button (click)="close()" aria-label="Fechar janela">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <!-- <div content-header>
    
  </div> -->
  <div content-body class="content-body">
    <span *ngIf="online" class="p-16" style="font-size: 16px;">
      <p>Você está alternando entre o modo ONLINE e o modo OFFLINE, a partir de agora os proximos atendimentos e as
        proximas fotos serão armazenados no seu dispositivo.</p>
    </span>

    <span *ngIf="!online" class="p-16" style="font-size: 16px;">
      <p>Você está alternando entre o modo OFFLINE e o modo ONLINE. A partir de agora os próximos atendimentos e as
        próximas fotos serão enviados para o goVendas automaticamente.</p>

      <span *ngIf="numberItensToSync && onlineService.onlineNetwork">
        <p>
          Existem dados a ser sincronizados.
        </p>
        <mat-slide-toggle [(ngModel)]="isSyncAutomatic">
          Deseja fazer o envio automaticamente?
        </mat-slide-toggle>
      </span>
    </span>
  </div>
  <div content-ok>
    <div fxLayout="row" fxLayoutAlign=" center" fxLayout.lt-sm="column">
      <button mat-stroked-button color="accent" class="m-8" (click)="close()">Não, desejo ficar
        {{online ? "ONLINE": "OFFLINE"}}</button>
      <button mat-flat-button color="accent" class="m-8" (click)="toggleOnlineOffline()">Sim, alterar para
        {{!online ? "ONLINE": "OFFLINE"}}</button>
    </div>
  </div>
</modal-component>
