<span
  style="
    text-align: center;
    background: #ff0909aa;
    width: 100%;
    text-align: center;
    font-size: 10px;
    color: #fff;
    font-weight: bold;
    height: 20px;
    position: absolute;
    padding-top: 4px;
    top: 0;
    left: 0;
    z-index: 9999;
  "
  *ngIf="!!warnMessagesService.message"
  >{{ warnMessagesService.message }}</span
>

<ngx-spinner name="loadingStorage" bdColor="rgba(51, 51, 51, 0.8)" size="small" color="#fff" type="ball-clip-rotate" size="default"> </ngx-spinner>

<ngx-spinner name="loadingDependencies" bdColor="rgba(51, 51, 51, 0.8)" size="small" color="#fff" type="ball-clip-rotate" size="default">
  <div *ngIf="!loadingTimeout" fxLayout="column">
    <span style="z-index: 1000; color: #fff">Carregando dados...</span>
    <span style="z-index: 1000; color: #fff">Tempo limite para o carregamento: {{ duration?.format("mm:ss") }}</span>
  </div>
  <div *ngIf="loadingTimeout" fxLayout="column">
    <span style="z-index: 1000; color: #fff">Tempo Limite esgotado.</span>
    <span style="z-index: 1000; color: #fff">Conecte-se em uma rede de maior velocidade e tente novamente</span>
    <button mat-raised-button color="accent" (click)="abortOfflineLoad()">Recarregar</button>
  </div>
</ngx-spinner>

<!-- layout original  
<ng-container *ngIf="fuseConfig.layout.style === 'vertical-layout-1'">
  <vertical-layout-1></vertical-layout-1>
</ng-container>
-->

<mat-sidenav-container class="main-container" autosize (backdropClick)="close('backdrop')">
  <mat-sidenav
    #sidenav
    (keydown.escape)="close('escape')"
    [mode]="mobileQuery.matches ? 'over' : 'side'"
    class="sidenav"
    [ngClass]="logged ? '' : 'hidden'"
    style="overflow-y: auto; overflow-x: hidden"
  >
    <div fxLayout="column" fxLayoutGap="10px" style="height: 100%; padding-bottom: 80px">
      <div class="logo_menu" fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div class="logo" fxLayout="row" fxLayoutAlign="center center">
            <img class="logo-icon" src="assets/images/logos/logo-h1w-icon.svg" />
            <div class="logo-text secondary-text" *ngIf="showMenuLarge">vendas</div>
          </div>
        </div>
        <div class="user" *ngIf="showMenuLarge">
          <div *ngIf="!!currentUser" class="username">{{ currentUser.name | ellipsis: 20 }}</div>
        </div>
      </div>
      <div style="overflow-y: auto; overflow-x: hidden">
        <mat-nav-list style="overflow-y: auto; overflow-x: hidden; margin-bottom: 80px">
          <span mat-list-item *ngFor="let groupItem of navigation">
            <div *ngIf="groupItem.children.length > 0">
              <div class="nav groupItemLarge" *ngIf="showMenuLarge">
                <div *ngFor="let itemMenu of groupItem.children">
                  <div
                    class="itemMenu"
                    [routerLinkActive]="['is-active']"
                    (click)="!isCtrlPressed ? activate(itemMenu) : undefined"
                    *ngIf="itemMenu.url"
                  >
                    <a class="link-wrapper" [routerLink]="[itemMenu.url]">
                      <mat-icon class="icon largeMenu">{{ itemMenu?.icon }} </mat-icon>
                      <span>{{ itemMenu.title }}</span>
                    </a>
                  </div>

                  <div class="itemMenu" *ngIf="!itemMenu.url" (click)="itemMenu.function(online)">
                    <mat-icon class="icon largeMenu">{{ itemMenu?.icon }} </mat-icon>
                    <span>{{ itemMenu.title }}</span>
                  </div>
                </div>
              </div>
              <div class="nav groupItemShort" *ngIf="!showMenuLarge">
                <div class="titleShort"></div>
                <div *ngFor="let itemMenu of groupItem.children">
                  <div
                    class="itemMenuShort"
                    *ngIf="itemMenu.url"
                    [routerLinkActive]="['is-active']"
                    matTooltip="{{ itemMenu.tooltip ? itemMenu.tooltip : itemMenu.title }}"
                  >
                    <a class="link-wrapper" [routerLink]="[itemMenu.url]">
                      <mat-icon class="icon shortMenu">
                        {{ itemMenu?.icon }}
                      </mat-icon>
                    </a>
                  </div>
                  <div
                    class="itemMenuShort"
                    *ngIf="!itemMenu.url"
                    (click)="itemMenu.function(online)"
                    [routerLinkActive]="['is-active']"
                    matTooltip="{{ itemMenu.tooltip ? itemMenu.tooltip : itemMenu.title }}"
                  >
                    <mat-icon class="icon shortMenu">
                      {{ itemMenu?.icon }}
                    </mat-icon>
                  </div>
                </div>
              </div>
            </div>
          </span>
        </mat-nav-list>
      </div>
      <span class="spacer"></span>
      <div class="quotaStorage" *ngIf="availbleStorage" fxLayout="column" style="text-align: center; font-size: 12px; color: #8bf890">
        <div *ngIf="showMenuLarge">
          <div style="margin-bottom: 10px">
            Utilizando {{ usage }} do <br />
            total {{ quota }} disponível.<br /><br />
            <span>({{ percentUsed }}%) utilizado</span>
          </div>
          <button mat-stroked-button class="clear-button" (click)="clearStorage()" *ngIf="showMenuLarge">Limpar armazenamento</button>
        </div>
        <span *ngIf="!showMenuLarge">({{ percentUsed }}%) <br />utilizado</span>
      </div>
    </div>
  </mat-sidenav>

  <mat-sidenav-content>
    <router-outlet *ngIf="true"></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
