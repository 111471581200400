export class RuptureParams {
  public freq = 0;
  public max_visit = 0;

  public mon_pref: number = null;
  public tue_pref: number = null;
  public wed_pref: number = null;
  public thu_pref: number = null;
  public fri_pref: number = null;
  public sat_pref: number = null;
  public sun_pref: number = null;

  constructor(source?: RuptureParams) {
    if (source) {
      if (source.freq != null) this.freq = source.freq;
      if (source.fri_pref != null) this.fri_pref = source.fri_pref;
      if (source.max_visit != null) this.max_visit = source.max_visit;
      if (source.mon_pref != null) this.mon_pref = source.mon_pref;
      if (source.sat_pref != null) this.sat_pref = source.sat_pref;
      if (source.sun_pref != null) this.sun_pref = source.sun_pref;
      if (source.thu_pref != null) this.thu_pref = source.thu_pref;
      if (source.tue_pref != null) this.tue_pref = source.tue_pref;
      if (source.wed_pref != null) this.wed_pref = source.wed_pref;
    }
  }
}
