export class GeoJsonGeometry {
  public type = "";
  public coordinates: number[] = [];

  constructor(source?: GeoJsonGeometry) {
    if (source) {
      if (source.type != null) this.type = source.type;
      if (source.coordinates != null) this.coordinates = source.coordinates;
    }
  }

  public static createPointFromCoords(coordinates: number[]): GeoJsonGeometry {
    if (!coordinates || coordinates.length !== 2)
      return null;

    return this.createPointFromLatLon(coordinates[1], coordinates[0]);
  }

  public static createPointFromLatLon(lat: number, lon: number): GeoJsonGeometry {
    return {
      type: "Point",
      coordinates: [ lon, lat ]
    };
  }
}

export class GeoJsonFeature<T = {}> {
  public type: string;
  public geometry: GeoJsonGeometry;
  public properties: T;
}
