import { Injectable, Injector } from "@angular/core";
import { Router } from "@angular/router";
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpHeaders, HttpResponse } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { v4 as uuidv4 } from "uuid";

import { environment } from "environments/environment";
import { AuthService } from "./auth.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private authService: AuthService;
  constructor(private injector: Injector) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.authService = this.injector.get(AuthService);
    const token: string = this.authService.getToken();
    const uuid = uuidv4();
    if (request.url.includes(environment.BASE_URL)) {
      const commonHeaders = {
        "X-Request-Id": uuid,
        token: `${token}`
      };
      if (request.params.has("multipart")) {
        request = request.clone({
          setHeaders: {
            ...commonHeaders
          }
        });
      } else {
        request = request.clone({
          setHeaders: {
            ...commonHeaders,
            "Content-Type": "application/json"
          }
        });
      }
    } else {
      const value = request.headers.get("Content-Type");
      if (!value) {
        request.headers.set("Content-Type", "application/json");
      }
    }

    return next.handle(request).pipe(
      map(response => {
        if (response instanceof HttpResponse) {
          const headers = {};
          response.headers.keys().forEach(key => (headers[key] = response.headers.get(key)));
          const httpHeaders = new HttpHeaders({
            ...headers,
            "X-Request-Id": uuid
          });
          const resp = new HttpResponse({
            ...response,
            headers: httpHeaders
          });

          return resp;
        }

        return response;
      }),
      catchError(response => {
        if (response instanceof HttpErrorResponse) {
          const headers = {};
          response.headers.keys().forEach(key => (headers[key] = response.headers.get(key)));
          const httpHeaders = new HttpHeaders({
            ...headers,
            "X-Request-Id": uuid
          });
          const resp = new HttpErrorResponse({
            ...response,
            headers: httpHeaders
          });

          return throwError(resp);
        }

        return throwError(response);
      })
    );
  }
}

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router, private toastr: ToastrService) {}
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((response: any) => {
        if (response instanceof HttpErrorResponse && response.status === 401) {
          localStorage.removeItem("token");
          this.router.navigateByUrl("/auth/login");
        }

        if (response instanceof HttpErrorResponse && response.status === 403) {
          this.toastr.warning("Usuario sem acesso");
          this.router.navigateByUrl("/");
        }

        return throwError(response);
      })
    );
  }
}
