import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { OneSginalData, OnseSignalUpdateUserResponse } from "../models";
import { AuthService } from "./auth.service";
import { SentryErrorHandler } from "./sentry-error-handler.service";

@Injectable({
  providedIn: "root"
})
export class OneSignalService {
  private urlOneSignal = "https://onesignal.com/api/v1";
  public player_id = " ";
  public userData: OneSginalData;

  constructor(public http: HttpClient, private auth: AuthService, private sentryService: SentryErrorHandler) {
    this.userData = new OneSginalData();
  }

  private async updateUser(player_id: String, userData?: Partial<OneSginalData>): Promise<void> {
    await this.auth.getUser().subscribe(async user => {
      const defaultData: OneSginalData = {
        app_id: environment.oneSignalAppId, //"6bfdba9b-5f45-4f89-b908-8022870544c5"
        tags: { account_id: user.account_id, user_id: user.id },
        language: "br",
        external_user_id: user.account_id + "-" + user.id
      };

      const { events } = user;
      if (events && events.length > 0) events.forEach(x => (defaultData.tags[x] = true));

      const data: OneSginalData = userData ? { ...defaultData, ...userData } : defaultData;

      const urlOneSignal = `${this.urlOneSignal}/players/${player_id}`;

      this.http.put<OnseSignalUpdateUserResponse>(urlOneSignal, data).subscribe(
        resp => {
          if (resp.success) this.userData = data;
        },
        error => {
          this.sentryService.handleError(error);
        }
      );
    });
  }

  public async updateOneSignalUser(userData: Partial<OneSginalData>): Promise<void> {
    return this.updateUser(this.player_id, userData);
  }

  public get oneSignal(): any {
    return window["OneSignal"] || [];
  }

  public initOneSignal(): void {
    this.oneSignal.push(() => {
      this.oneSignal.init({
        appId: environment.oneSignalAppId, //"6bfdba9b-5f45-4f89-b908-8022870544c5"
        notifyButton: {
          enable: true
        }
      });
    });

    this.oneSignal.push(() => {
      this.oneSignal.showSlidedownPrompt();
    });

    /** Verifica se o registro foi feito */
    // this.oneSignal.push(["getNotificationPermission", (permission) => {
    //     console.log("Site Notification Permission:", permission);
    // }]);

    this.oneSignal.push(() => {
      // Occurs when the user's subscription changes to a new value.
      // TODO: Veridicar se esse método é chamado se descastrar
      const setPlayerId = () => {
        this.oneSignal.getUserId(userId => {
          if (userId) {
            this.player_id = userId;

            this.updateUser(userId);
          }
        });
      };

      setPlayerId();

      this.oneSignal.on("subscriptionChange", isSubscribed => {
        setPlayerId();
      });

      // This event can be listened to via the `on()` or `once()` listener.
    });
  }

  public unsubscribe(): Promise<void> {
    const data: Partial<OneSginalData> = {
      external_user_id: "not_notify"
    };
    return this.updateUser(this.player_id, data).then(() => (this.player_id = null));
  }

  public removeExternalUserId(): void {
    this.oneSignal.push(() => {
      this.oneSignal.removeExternalUserId();
    });
  }

  public isInitialized(): boolean {
    return !!this.player_id;
  }
}
