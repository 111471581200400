import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { environment } from "environments/environment";
import { SentryErrorHandler } from "app/main/shared/services";
import { Route } from "../../shared/models";
import { Utils } from "./utils";

@Injectable()
export class RouteService {
  private BASE_URL = environment.BASE_URL;

  constructor(public http: HttpClient, public sentryService: SentryErrorHandler) {}

  public getRoute(salesmanId: number, date: Date): Observable<HttpResponse<Route>> {
    const url = `${this.BASE_URL}/route/${salesmanId}/${Utils.getDateToServer(date)}`;
    return this.http
      .get<Route>(url, { observe: "response" })
      .pipe(
        map(response => {
          const respClone = new HttpResponse({
            ...response.clone(),
            body: new Route(response.body)
          });
          return respClone;
        }),
        catchError(err => {
          if (err instanceof HttpErrorResponse) {
            const extra = {};
            extra[environment.requestIdHeader] = err.headers.get(environment.requestIdHeader);
            this.sentryService.handleError(err, extra);
          } else {
            this.sentryService.handleError(err);
          }

          return throwError(err);
        })
      );
  }
}
