import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { forkJoin, Observable } from "rxjs";
import { environment } from "environments/environment";
import { ReplaySubject } from "rxjs";
import { FeedbackMapping, FeedbackMappingGroup } from "../models/feedback";
import { SentryErrorHandler } from "./sentry-error-handler.service";

@Injectable()
export class FeedbackMappingService {
  private BASE_URL = environment.BASE_URL;

  private feedbackMappingAll: FeedbackMapping;
  private feedbackMapping: FeedbackMapping;

  public feedbackGroups: FeedbackMappingGroup[];
  public feedbackGroupMap: Map<number, number>;

  private feedbackMapping$: ReplaySubject<FeedbackMapping>;
  private feedbackMappingAll$: ReplaySubject<FeedbackMapping>;
  private isMappingLoading: boolean;
  private isMappingLoadingAll: boolean;

  constructor(public http: HttpClient, private sentryService: SentryErrorHandler) {
    this.feedbackMapping$ = new ReplaySubject<FeedbackMapping>();
    this.feedbackMappingAll$ = new ReplaySubject<FeedbackMapping>();
    this.isMappingLoading = false;

    this.feedbackGroupMap = new Map<number, number>();
  }

  public init(): Promise<FeedbackMapping> {
    return new Promise((resolve, reject) => {
      forkJoin([this.getAll(), this.getAll(false)]).subscribe(
        mapping => {
          this.feedbackGroups = [
            {
              name: "Em andamento",
              values: []
            },
            {
              name: "Não vendi",
              values: []
            },
            {
              name: "Vendi",
              values: []
            }
          ];
          for (const item of Object.entries(mapping[1])) {
            const feedbackId = parseInt(item[0], 10);
            let index;
            if (feedbackId < 100) {
              index = 0;
            } else if (feedbackId >= 100 && feedbackId < 200) {
              index = 2;
            } else if (feedbackId >= 200) {
              index = 1;
            }

            if (!(feedbackId === 7 || feedbackId === 9)) {
              this.feedbackGroups[index].values.push(item);
              this.feedbackGroupMap.set(feedbackId, index);
            }
          }

          resolve(mapping[1]);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  public getAll(includeDeprecated: boolean = true): Observable<FeedbackMapping> {
    if (this.feedbackMappingAll && includeDeprecated) return this.feedbackMapping$;
    if (this.feedbackMappingAll && includeDeprecated) return this.feedbackMappingAll$;

    const canLoad = includeDeprecated ? !this.isMappingLoadingAll : !this.isMappingLoading;

    if (canLoad) {
      if (includeDeprecated) this.isMappingLoadingAll = true;
      else this.isMappingLoading = true;
      this.http.get<FeedbackMapping>(`${this.BASE_URL}/feedback/mapping?deprecated=${includeDeprecated}`).subscribe(
        (result: FeedbackMapping) => {
          if (includeDeprecated) {
            this.feedbackMappingAll = result;
            this.feedbackMappingAll$.next(result);
            this.feedbackMappingAll$.complete();
            this.isMappingLoadingAll = false;
          } else {
            this.feedbackMapping = result;
            this.feedbackMapping$.next(result);
            this.feedbackMapping$.complete();
            this.isMappingLoading = false;
          }
        },
        error => {
          if (error instanceof HttpErrorResponse) {
            const extra = {};
            extra[environment.requestIdHeader] = error.headers.get(environment.requestIdHeader);
            this.sentryService.handleError(error, extra);
          } else {
            this.sentryService.handleError(error);
          }

          this.feedbackMapping$.error(error);
        }
      );
    }

    return includeDeprecated ? this.feedbackMappingAll$ : this.feedbackMapping$;
  }

  public getColorByFeedbackID(feedbackId: number): string {
    if (feedbackId < 100) {
      if (feedbackId === 0) {
        return "st-emaberto";
      } else return "st-naoatendido";
    } else if (feedbackId >= 100 && feedbackId < 200) {
      return "st-positivado";
    } else if (feedbackId >= 200) {
      return "st-naovenda";
    }
  }

  public getLabelByFeedbackID(feedbackId: number): string {
    if (feedbackId === 0) {
      return "Em aberto";
    } else {
      if (this.feedbackMappingAll[feedbackId]) {
        return this.feedbackMappingAll[feedbackId];
      }
    }
  }
}
