export class ClientStatusSumary {
    public active_in_agenda: number;
    public active_out_agenda: number;

    public inactive_in_agenda: number;
    public inactive_out_agenda: number;


    constructor() {
        this.active_in_agenda = 0;
        this.active_out_agenda = 0;
        this.inactive_in_agenda = 0;
        this.inactive_out_agenda = 0;
    }
}