import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CacheService } from "./cache.service";
import { Client } from "app/main/shared/models";
import { environment } from "environments/environment";
import { tap } from "rxjs/operators";

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  constructor(private cacheService: CacheService<Client[]>) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
    tap(evt => {
      if (evt instanceof HttpResponse) {
        for (const req of environment.requestsToCache) {
          if (request.method === req.type && new RegExp(req.pattern).test(request.url)) {
            if (this.cacheService.canCache()) this.cacheService.save(request.url, evt.body);
          }
        }
      }
    }));
  }
}
