export interface IProductFeedback {
  feedback_id: number;
  visit_date: string;
  feedback_date: Date;
  product_id: string;
  client_id: string;
  salesman_id: string;
  owner_id: number;
  list_type: number;
}

export class ProductFeedback implements IProductFeedback {
  public feedback_id: number;
  public visit_date: string;
  public feedback_date: Date;
  public product_id: string;
  public client_id: string;
  public salesman_id: string;
  public owner_id: number;
  public list_type: number;
  public isPositive: boolean;

  constructor(source?: IProductFeedback) {
    if (source) {
      if (source.feedback_id) this.feedback_id = source.feedback_id;
      if (source.visit_date) this.visit_date = source.visit_date;
      if (source.feedback_date) this.feedback_date = source.feedback_date;
      if (source.product_id) this.product_id = source.product_id;
      if (source.client_id) this.client_id = source.client_id;
      if (source.salesman_id) this.salesman_id = source.salesman_id;
      if (source.owner_id) this.owner_id = source.owner_id;
      if (source.list_type) this.list_type = source.list_type;
    }
  }
}

export interface IProductFeedbackMapping {
  createdAt: Date;
  createdBy: number;
  deprecated: boolean;
  feedback_id: number;
  label: string;
  owner_id: number;
  positive: boolean;
  updatedBy: number;
}

export interface IProductHistoryItem {
  client_id: string;
  feedback_date: Date;
  feedback_id: number;
  feedback_label: string;
  id: number;
  list_type: number;
  owner_id: number;
  owner_name: string;
  product_id: string;
  salesman: string;
  salesman_id: string;
  visit_date: Date;
}

export class ProductHistoryItem {
  public client_id: string;
  public feedback_date: Date;
  public feedback_id: number;
  public feedback_label: string;
  public id: number;
  public list_type: number;
  public owner_id: number;
  public owner_name: string;
  public product_id: string;
  public salesman: string;
  public salesman_id: string;
  public visit_date: Date;

  public positive: boolean;

  constructor(source?: IProductHistoryItem) {
    if (source) {
      if (source.client_id) this.client_id = source.client_id;
      if (source.feedback_date) this.feedback_date = source.feedback_date;
      if (source.feedback_id) this.feedback_id = source.feedback_id;
      if (source.feedback_label) this.feedback_label = source.feedback_label;
      if (source.id) this.id = source.id;
      if (source.list_type) this.list_type = source.list_type;
      if (source.owner_id) this.owner_id = source.owner_id;
      if (source.owner_name) this.owner_name = source.owner_name;
      if (source.product_id) this.product_id = source.product_id;
      if (source.salesman) this.salesman = source.salesman;
      if (source.salesman_id) this.salesman_id = source.salesman_id;
      if (source.visit_date) this.visit_date = source.visit_date;
    }
  }
}
