import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";

import { User } from "../../shared/models/user";
import { environment } from "environments/environment";
import { FollowupSeller, IFollowupSeller, IFollowupClients, IFollowupHistory, FollowupHistory } from "../models/followup";
import * as _ from "lodash";
import { map, catchError } from "rxjs/operators";
import { SentryErrorHandler } from "./sentry-error-handler.service";

@Injectable()
export class SellersService {
  private BASE_URL = environment.BASE_URL;

  constructor(private http: HttpClient, private sentryService: SentryErrorHandler) {}

  private sendError(err: any, requestId?: string): void {
    if (requestId) {
      const extra = {};
      extra[environment.requestIdHeader] = err.headers.get(environment.requestIdHeader);
      this.sentryService.handleError(err, extra);
    } else {
      this.sentryService.handleError(err);
    }
  }

  public getSellers(sellerID: string, dateIni: string, dateEnd: string, scheduleType: number): Observable<FollowupSeller> {
    let url = `${this.BASE_URL}/followup/${sellerID}/${dateIni}/${dateEnd}`;

    if (scheduleType) {
      url += `?scheduleType=${scheduleType}`;
    }

    return this.http.get<IFollowupSeller>(url).pipe(
      map(x => new FollowupSeller(x)),
      catchError(err => {
        const requestId = err instanceof HttpErrorResponse ? err.headers.get(environment.requestIdHeader) : null;
        this.sendError(err, requestId);
        return throwError(err);
      })
    );
  }

  public getSellersReport(user: User, startDate: string, endDate: string, timezoneoffset: number, scheduleType: number): Promise<string> {
    const data = {
      ids: user.role === "SALESMAN" ? [user.salesman_id] : _.uniq(this._getSalesmanList(user)),
      start_date: startDate,
      end_date: endDate,
      timezone_offset: timezoneoffset
    };

    if (scheduleType) {
      data["Schedule_type"] = scheduleType;
    }

    const url = `${this.BASE_URL}/followup/report`;

    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders().set("Content-Type", "text/plain; charset=utf-8");

      this.http
        .post<any>(url, data, { headers, responseType: "blob" as "json" })
        .toPromise()
        .then(resp => {
          const blob = new Blob([resp], { type: "application/vnd.ms-excel" });
          const url2 = window.URL.createObjectURL(blob);
          let supervisorName = "";
          if (user.name && user.name.length > 0) {
            if (user.name.indexOf(" ") > 0) {
              supervisorName = user.name.slice(0, user.name.indexOf(" "));
            } else {
              supervisorName = user.name;
            }
          }
          const filename = "relatorio_de_acompanhamento_" + supervisorName.toLowerCase() + ".xlsx";
          const anchor = document.createElement("a");
          anchor.download = filename;
          anchor.href = url2;
          anchor.click();
          resolve(filename);
        })
        .catch(err => {
          const requestId = err instanceof HttpErrorResponse ? err.headers.get(environment.requestIdHeader) : null;
          this.sendError(err, requestId);
          reject(err);
        });
    });
  }

  public getSellersByClients(sellerID: number, date: string, scheduleType: number): Observable<IFollowupClients> {
    let url = `${this.BASE_URL}/followup/by-clients/${sellerID}/${date}`;

    if (scheduleType) url += `?scheduleType=${scheduleType}`;

    return this.http.get<IFollowupClients>(url).pipe(
      catchError(err => {
        const requestId = err instanceof HttpErrorResponse ? err.headers.get(environment.requestIdHeader) : null;
        this.sendError(err, requestId);
        return throwError(err);
      })
    );
  }

  public getFollowUpAndSalesHistory(selectedDate: string, clientID: string): Observable<FollowupHistory> {
    const url = `${this.BASE_URL}/followup/by-clients/${clientID}?visitDate=${selectedDate}&historyLimit=40`;

    return this.http.get<IFollowupHistory>(url).pipe(
      map(response => new FollowupHistory(response)),
      catchError(err => {
        const requestId = err instanceof HttpErrorResponse ? err.headers.get(environment.requestIdHeader) : null;
        this.sendError(err, requestId);
        return throwError(err);
      })
    );
  }

  private _getSalesmanList(user: User): string[] {
    const salesmanList = [];
    if (user) {
      if (user.salesman_id !== undefined && user.role === "SALESMAN") {
        salesmanList.push(user.salesman_id);
      }
      if (user.users) {
        user.users.forEach(userSelected => {
          const list = this._getSalesmanList(userSelected);
          if (list) {
            list.forEach(salesmanId => {
              salesmanList.push(salesmanId);
            });
          }
        });
      }
    }
    return salesmanList;
  }
}
