import { Injectable } from '@angular/core';
import { Feedback, FeedbackStatus, User } from '../models';
import { FeedbackError } from '../../atendimento/models/feedback-error';

@Injectable()
export class FeedbackService {
  public validateFeedbackParams(feedback: Feedback, currentUser: User, observations: string): FeedbackError {
    const errors: FeedbackError = new FeedbackError();

    if (!feedback.feedback_id) {
      errors.feedback_id = 'Opção de feedback inválida';
      return errors;
    }

    // if (feedback.feedback_id < 100 || feedback.feedback_id >= 200) {
    //   if (!observations) {
    //     errors.observations = `Para esse feedback o campo observações é obrigatório`;
    //   }
    // }

    if (currentUser && currentUser.salesman_type === "EXTERNAL" && (feedback.feedback_presential === null || feedback.feedback_presential === undefined))
      errors.feedback_presential = "É necessário informar se o feedback foi presencial";

    return errors;
  }
}
