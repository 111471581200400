import { Injectable, isDevMode } from '@angular/core';
import { CustomNetworkInformation } from '../models/customNetworkInformation';
import { HttpClient } from '@angular/common/http';
import * as moment from "moment";
import { environment } from 'environments/environment';
import { Observable, of, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NetworkspeedService {

  public netWorkStatus: Subject<CustomNetworkInformation>;

  constructor(public http: HttpClient) {
    this.netWorkStatus = new Subject();

    this.netWorkStatus.next({
      speedDownload: -1,
      downlink: -1,
      effectiveType: "undefined",
      onchange: null,
      rtt: -1,
      saveData: false,
      customEffectiveTypeBySpeed: "undefined",
    })
  }

  /* Esta função é executada quando o navegador não oferece suporte á networkAPI, 
  //https://wicg.github.io/netinfo/ (em junho/2019, o racunho da API é de 20 February 2019)
    // ECT	   Minimum RTT (ms)	Maximum downlink (Kbps)	Explanation
    // slow-2g	2000              	50	The network is suited for small transfers only such as text-only pages.
    // 2g	      1400	              70	The network is suited for transfers of small images.
    // 3g	       270	              700	The network is suited for transfers of large assets such as high resolution images, audio, and SD video.
    // 4g	       0                	∞	The network is suited for HD video, real-time video, etc.
    */

  private getEffectiveTypeBySpeed(rtt: number): string {
    let result = "undefined";
    if (rtt > 2000) {
      result = "slow-2g"
    } else {
      if (rtt > 1400) {
        result = "2g"
      } else {
        if (rtt > 270) {
          result = "3g"
        } else {
          result = "4g"
        }
      }
    }

    return result
  }


  private getCustomEffectiveTypeBySpeed(rtt: number): string {
    let result = "undefined";
    if (rtt < environment.slaRTT) {
      result = "slow"
    } else {
      result = "fast"
    }

    return result
  }

  public updateNetworkstatus(): void {
    const method = (isDevMode) ? "GET" : "HEAD";
    const dataInit = moment(new Date());
    const tcpWindow = 65536 * 8;

    this.http.request(method, "assets/images/misc/net-work-test.jpg", { responseType: 'blob' }).subscribe((res) => {
      const dataFinish = moment(new Date());
      const time = dataFinish.diff(dataInit);
      const rtt = time / 2;
      const timeSec = time / 1000;
      const velocity = (tcpWindow / timeSec) / 1000;
      const connection = new CustomNetworkInformation({
        speedDownload: parseInt((velocity).toFixed(2), 10),
        effectiveType: this.getEffectiveTypeBySpeed(rtt),
        customEffectiveTypeBySpeed: this.getCustomEffectiveTypeBySpeed(rtt)
      });
      this.netWorkStatus.next(connection)
    })

  }

}
